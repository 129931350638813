var flightCompose = require("flight/lib/compose");
var baseStSub = require("src/desktop/modules/flight-search-form/lib/mixins/with-base-st-sub");

var mixin = function () {
    flightCompose.mixin(this, [baseStSub]);

    /**
     * This function adds a fixed set of ST subscriptions to existing object / array of requestedSubscriptions
     * @param {Object[]} requestedSubscriptions -- update requestedSubscriptions & add other ST related subs
     */
    this.appendStSubDataForModal = function (requestedSubscriptions) {
        requestedSubscriptions.push({subscriptionType: this.attr.subTypeStTripReminder});
        requestedSubscriptions.push({subscriptionType: this.attr.subTypeStSpotlight});
        requestedSubscriptions.push({subscriptionType: this.attr.subTypeStUpdates});
        requestedSubscriptions.push({subscriptionType: this.attr.subTypeStDealAlert});
    };

    /**
     *
     * @param {Object} subData
     */
    this.applyOtherSubsForStUserModal = function (subData) {
        subData.siteOverride = this.attr.stSite;
        this.appendStSubDataForModal(subData.requestedSubscriptions);
        this.subMultiple(subData);
    };

    /**
     * The user-modal-st-sub-form expects a generically named func like this to do the subscription for either
     * JS or ST based on which mixin it pulled in (as of AWD-3152)
     */
    this.applyOtherSubsForExternalSiteUserModal = function (subData) {
        this.applyOtherSubsForStUserModal(subData);
    };
};

module.exports = mixin;