/**
 * This is the data component that makes server calls to make subscriptions.
 *
 * LISTENS TO
 * ==========
 *
 * In all of these events they take an email param. This is optional as long as the awd-user cookie is present. It cannot
 * be assumed that the email will be sent along with the request.
 *
 * @event "data:subs:bulk:request" this event requests a one or more fare subscriptions
 *  @param {Object} data
 *      @param {String} email
 *      @param {String} firstName
 *      @param {Object[]} requestedSubscriptions
 *          @param {String} subscriptionType
 *          @param {String} originCode
 *          @param {String} destinationCode
 *          @param {String} frequency
 *
 * TRIGGERS
 * ========
 *
 * @event "data:subs:bulk:response" this event sends a bulk subscription response
 *  @param {Object} data
 *      @param {String} id
 *      @param {Boolean} success
 *      @param {Number} code error code
 *      @param {String} msg
 *
 */
var withEndpointParser = require("flight-common-mixins/lib/with-endpoint-parser");
var withDataCollection = require("src/desktop/modules/data-services/mixins/with-data-collection");
var defineComponent = require("flight/lib/component");
var $ = require("jquery");
var _ = require("underscore");
var subTypes = require("src/desktop/modules/flight-subs/lib/data/subTypeConstants");
var MD5 = require("crypto-js/md5");
var stm = require("stm");

var component = defineComponent(sub).mixin(withEndpointParser, withDataCollection);

function sub() {
    this.attributes({

        /**
         * @event "data:subs:bulk:request" this event requests a one or more fare subscriptions with the resulting new subs returned in the requested content type (json/html)
         *  @param {Object} data
         *      @param {String} contentType
         *      @param {String} email
         *      @param {String} firstName
         *      @param {Object} requestedSubscriptions
         *          @param {String} subscriptionType
         *          @param {String} originCode
         *          @param {String} destinationCode
         *          @param {String} frequency
         */
        EV_BULK_SUB_REQUEST: "data:subs:bulk:request",

        /**
         * @event "data:subs:update:request" perform update on __all__ subs assigned to a user
         *  @param {Object} data
         *      @param {String} frequency
         *      @param {String} hold-until data (YYYY-mm-dd)
         */
        EV_UPDATE_SUBS_REQUEST: "data:subs:update:request",

        /**
         * @event "data:sub:delete" perform delete action on the sub id
         * @param {String} subscriptionId
         */
        EV_DELETE_SUB_REQUEST: "data:sub:delete:request",

        /**
         * @event "data:subs:delete" perform bulk unsub action
         *  @param {Object}
         *      @param {String} userTrackingCode
         *      @param {String} unsubReason
         *      @param {String} unsubFreetext
         */
        EV_DELETE_SUBS_REQUEST: "data:subs:delete:request",

        /**
         * @event "data:user:update:request" perform user update
         *  @param {Object}
         *      @param {String} email
         *      @param {String} firstName
         *      @param {String} airportCode
         */
        EV_UPDATE_USER_REQUEST: "data:user:update:request",

        /**
         * @event "data:subs:bulk:response:json" this event sends a bulk subscription json response
         *  @param {Object} data
         *      @param {String} id
         *      @param {Boolean} success
         *      @param {Number} code error code
         *      @param {String} msg
         */
        EV_BULK_SUB_JSON_RESPONSE: "data:subs:bulk:response:json",

        /**
         * @event "data:subs:react:subscriber:response" this event is fired by the React/Redux part of the codebase in response to a successful subscriber request
         *  @param {Object} event jQuery event
         *      @param {Object} event.originalEvent.detail.subscriber See https://stash.st.cognius.net/projects/AWD/repos/awdjava/browse/web/src/main/reactapp/src/models/Subscriptions/Subscriber.ts
         *      @param {Object[]} event.originalEvent.detail.newSubscriptions See https://stash.st.cognius.net/projects/AWD/repos/awdjava/browse/web/src/main/reactapp/src/models/Subscriptions/Subscription.ts
         *      @param {boolean} event.originalEvent.detail.newSubscriber
         */
        EV_DATA_SUBS_REACT_SUBSCRIBER_RESPONSE: "data:subs:react:subscriber:response",

        /**
         * @event "data:subs:bulk:response" this event sends a bulk subscription html response
         *  @param {String} data sub html content
         */
        EV_BULK_SUB_HTML_RESPONSE: "data:subs:bulk:response:html",

        /**
         * @event "data:subs:update:response" send global subs update json response
         */
        EV_UPDATE_SUBS_RESPONSE: "data:subs:update:response",

        /**
         * @event "data:sub:delete:response" sends sub deletion json response
         */
        EV_DELETE_SUB_RESPONSE: "data:sub:delete:response",

        /**
         * @event "data:subs:delete:response" sends bulk unsub json response
         */
        EV_DELETE_SUBS_RESPONSE: "data:subs:delete:response",

        /**
         * @event "data:user:update:response" sends user update json response
         */
        EV_UPDATE_USER_RESPONSE: "data:user:update:response",

        baseUrl: null,

        site: null,

        toEndpoint: "users/subscriptions/fare-alerts/to/{toCode}",

        fromEndpoint: "users/subscriptions/fare-alerts/from/{fromCode}",

        routeEndpoint: "users/subscriptions/fare-alerts/from/{fromCode}/to/{toCode}",

        bulkEndpoint: "users/subscriptions",

        updatesEndpoint: "user/subscriptions/update",

        deleteEndpoint: "user/subscription",

        deleteEndpointPost: "user/subscription/delete",

        unsubEndpoint: "fare-alerts/unsubscribe",

        userUpdateEndpoint: "user/update",

        preferencesEndpoint: "user/preferences",

        subTypeRoute: subTypes.subTypeRoute,

        subTypeDeparture: subTypes.subTypeDeparture,

        subTypeArrival: subTypes.subTypeArrival,

        subTypeHwdUpdates: subTypes.subTypeHwdUpdates,

        subTypeAwdUpdates: subTypes.subTypeAwdUpdates,

        subTypeAirfareInsider: subTypes.subTypeAirfareInsider,

        subTypeTripWatch: subTypes.subTypeTripWatch,

        subTypeWeekender: subTypes.subTypeWeekender,

        subTypeSpecialOffer: subTypes.subTypeSpecialOffer,

        subTypeHwdCity: subTypes.subTypeHwdCity,

        subTypeJsGeneral: subTypes.subTypeJsGeneral,

        subTypeJsInspiration: subTypes.subTypeJsInspiration,

        subTypeJsDeals: subTypes.subTypeJsDeals,

        subTypeStUpdates: subTypes.subTypeStUpdates,

        subTypeStTripReminder: subTypes.subTypeStTripReminder,

        subTypeStDealAlert: subTypes.subTypeStDealAlert,

        subTypeStSpotlight: subTypes.subTypeStSpotlight,

        subTypeFvcInsider: subTypes.subTypeFvcInsider,

        subTypeOysterGeneral: subTypes.subTypeOysterGeneral,

        subStatusNewAwdUser: "new_user",

        awdSite: "awd",

        gtmAwdSubEvent: "awdSubscriptionEvent",
        gtmAwdNewSubEvent: "awdSubscriptionNewSubEvent",
        gtmAwdNewUserEvent: "awdSubscriptionNewUserEvent",

    });

    this.sendPbrTracking = function(data) {
        try {
            this.trackPageActivity(data);
        } catch (e) {
            // so this never breaks the page
        }
    };

    this.createExtraQueryParams = function() {
        var pageStateIdQuery = "";

        if (stm.collectionSvcTrackingData.pageStateId) {
            pageStateIdQuery = "?pageStateId=" + stm.collectionSvcTrackingData.pageStateId;
        }

        return pageStateIdQuery;
    };

    this.onBulkSubRequest = function(ev, data) {
        var site = (data.siteOverride) ? data.siteOverride : this.attr.site;
        var requestedSubscriptions = data.requestedSubscriptions;
        $.each(requestedSubscriptions, function (index, sub) {
            if (sub.destinationCode && sub.destinationCode.code) {
                sub.destinationCode = sub.destinationCode.code;
            }
            if (sub.originCode && sub.originCode.code) {
                sub.originCode = sub.originCode.code;
            }
        });

        var params = {
            site: site,
            email: data.email,
            firstName: data.firstName,
            requestedSubscriptions: requestedSubscriptions,
            withDefaults: (data.withDefaults !== undefined) ? data.withDefaults : true
        };

        var self = this;
        $.each(requestedSubscriptions, function (index, sub) {

            // Assume air subscription
            var data = {
                product_type: "air",
                type: "email_subscription",
                origin: sub.originCode,
                destination: sub.destinationCode,
                name: sub.subscriptionType
            };

            // Override product type for HWD subscription
            if (sub.subscriptionType === "hwd_city") {
                data.product_type = "hotel";
            }

            self.sendPbrTracking(data);
        });

        var url = this.attr.baseUrl + this.parseEndpoint(this.attr.bulkEndpoint, data) + this.createExtraQueryParams();

        var headers = {};
        var event = this.attr.EV_BULK_SUB_JSON_RESPONSE;
        var callback = this.extractUserContent;
        if (data.contentType === "html") {
            headers.Accept = "text/html";
            callback = this.extractHtmlContent;
            event = this.attr.EV_BULK_SUB_HTML_RESPONSE;
        }
        this.makeRequest(url, "bulk", params, data.id, event, "post", headers, callback);
    };

    /**
     * Handles tracking for subscriptions originating from React widgets. All of this should eventually go away
     * and move into React/Redux
     * @param {Object} event jQuery event
     *      @param {Object} event.originalEvent.detail.subscriber See https://stash.st.cognius.net/projects/AWD/repos/awdjava/browse/web/src/main/reactapp/src/models/Subscriptions/Subscriber.ts
     *      @param {Object[]} event.originalEvent.detail.newSubscriptions See https://stash.st.cognius.net/projects/AWD/repos/awdjava/browse/web/src/main/reactapp/src/models/Subscriptions/Subscription.ts
     *      @param {boolean} event.originalEvent.detail.newSubscriber
     */
    this.onReactSubscriberResponse = function(event) {
        var self = this;
        var eventData = event && event.originalEvent && event.originalEvent.detail;
        var newSubscriptions = (eventData && eventData.newSubscriptions) || [];

        // Logic copied from onBulkSubRequest
        $.each(newSubscriptions || [], function(index, sub) {

            // Build Collection Service tracking; assume air subscription
            var data = {
                product_type: "air",
                type: "email_subscription",
                origin: sub.originCode,
                destination: sub.destinationCode,
                name: sub.subscriptionType
            };

            // Override product type for HWD subscription
            if (sub.subscriptionType === "hwd_city") {
                data.product_type = "hotel";
            }

            self.sendPbrTracking(data);
        });

        this.trackReactSubscriptionsToGtm(eventData);
    };

    /**
     * Tracks React subscription event data to GTM. See with-subs mixin for tracking FlightJS components.
     * @param {Object} data
     *      @param {Object} data.subscriber See https://stash.st.cognius.net/projects/AWD/repos/awdjava/browse/web/src/main/reactapp/src/models/Subscriptions/Subscriber.ts
     *      @param {Object[]} data.newSubscriptions See https://stash.st.cognius.net/projects/AWD/repos/awdjava/browse/web/src/main/reactapp/src/models/Subscriptions/Subscription.ts
     *      @param {boolean} data.newSubscriber
     */
    this.trackReactSubscriptionsToGtm = function(data) {
        if (!data) {
            return;
        }

        if (data.subscriber) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": this.attr.gtmAwdSubEvent
            });

            if (data.newSubscriptions && data.newSubscriptions.length) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    "event": this.attr.gtmAwdNewSubEvent,
                    "newSubCount": data.newSubscriptions.length
                });

                if (data.newSubscriber) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        "event": this.attr.gtmAwdNewUserEvent,
                        "userTrackingCode": data.subscriber.trackingCode,
                        "emailHash": MD5(data.subscriber.emailAddress).toString()
                    });
                }
            }
        }
    };

    this.onUpdateSubsRequest = function(event, data) {
        var url = this.attr.baseUrl + this.parseEndpoint(this.attr.updatesEndpoint, data);

        var updateType = data.action || "update";
        var params = {
            userTrackingCode: data.userTrackingCode,
            holdUntilDate: data.pauseDate,
            frequency: data.frequency,
            frequencyText: data.frequencyText,
            updateType : updateType
        };
        this.makeRequest(url, updateType, params, [], data.id, this.attr.EV_UPDATE_SUBS_RESPONSE, "post", {Accept: "application/json"}, this.extractAlertsContent);
    };

    this.onDeleteRequest = function(ev, data) {
        var site = (data.siteOverride) ? data.siteOverride : this.attr.site;
        var url = this.attr.baseUrl + this.parseEndpoint(this.attr.deleteEndpointPost, data)
            + "?subscriptionId=" + data.subId
            + "&userTrackingCode=" + data.userTrackingCode
            + "&site=" + site;
        this.makeRequest(url, "delete", {}, [], data.id, this.attr.EV_DELETE_SUB_RESPONSE, "post", {}, this.extractUserContent);
    };

    this.onDeleteAllRequest = function(event, data) {
        var url = this.attr.baseUrl + this.parseEndpoint(this.attr.unsubEndpoint, data);
        var params = {
            unsubReason: data.unsubReason,
            otherDesc: data.otherDesc
        };

        this.makeRequest(url, "unsub", params, [], data.id, this.attr.EV_UPDATE_SUBS_RESPONSE, "post", {}, this.extractAlertsContent);
    };

    this.onUpdateUserRequest = function(event, data) {
        var url = this.attr.baseUrl + this.parseEndpoint(this.attr.userUpdateEndpoint, data) + this.createExtraQueryParams();
        var params = {
            email: data.email,
            userTrackingCode: data.userTrackingCode,
            firstName: data.firstName,
            airportCode: data.airportCode
        };

        this.makeRequest(url, "updateUser", params, [], data.id, this.attr.EV_UPDATE_USER_RESPONSE, "post", {}, this.extractUserContent);
    };

    this.extractAlertsContent = function(data) {
        var responseData = {
            id: this.id,
            action: this.action
        };
        responseData.success = data.success;
        responseData.params = this.params;

        this.trigger(this.event, responseData);
    };

    this.extractHtmlContent = function(data, textStatus) {
        var responseData = {
            id: this.id
        };
        if (textStatus === "success") {
            responseData.success = true;
            responseData.html = data;
        } else {
            responseData.success = false;
        }

        this.trigger(this.event, responseData);
    };

    this.extractUserContent = function(data) {
        var responseData = {
            id: this.id,
            siteForSubRequest: this.params.site || this.attr.site
        };

        if (data.success &&
            (this.action === "delete" ||
            (data.success.trackingCode || (data.success.userInfo && data.success.userInfo.trackingCode)))) {
            responseData.success = true;
            responseData.msg = data.message;
            responseData.user = data.success;
        } else if (data.errors && data.errors.length) {
            responseData.success = false;
            responseData.code = data.errors[0].code;
            responseData.msg = data.errors[0].message;
        } else {
            responseData.success = false;
            var response = JSON.parse(data.responseText);
            if (response && response.errors.length) {
                responseData.code = response.errors[0].code;
                responseData.msg = response.errors[0].message;
            }
        }

        this.trigger(this.event, responseData);
    };

    this.makeRequest = function (url, action, params, id, event, protocol, additionalHeaders, callback) {
        var self = this;
        self.id = id;
        self.event = event;
        self.action = action;
        self.protocol = protocol;
        self.params = params;

        var headers = {
            "Content-Type": "application/json"
        };
        $.extend(headers, additionalHeaders);

        $.ajax({
            url: url,
            type: protocol,
            data: JSON.stringify(params),
            headers: headers,
            processData: false
        }).always(_.bind(callback, self));
    };

    this.after("initialize", function() {
        this.on(this.attr.EV_BULK_SUB_REQUEST, this.onBulkSubRequest);
        this.on(this.attr.EV_DELETE_SUB_REQUEST, this.onDeleteRequest);
        this.on(this.attr.EV_DELETE_SUBS_REQUEST, this.onDeleteAllRequest);
        this.on(this.attr.EV_UPDATE_SUBS_REQUEST, this.onUpdateSubsRequest);
        this.on(this.attr.EV_UPDATE_USER_REQUEST, this.onUpdateUserRequest);

        // Until we port this over to React/Redux-land (AWD-3671), we'll fire an event and handle the tracking here
        this.on(document, this.attr.EV_DATA_SUBS_REACT_SUBSCRIBER_RESPONSE, this.onReactSubscriberResponse);
    });
}

module.exports = component;
