var $ = require("jquery");

var mixin = function () {
    this.attributes({
        baseUrl: "/",
        tabbedBrowsingUrl: "search/air/",
        tabbedBrowsingHotelUrl: "search/hotel/"
    });

    /**
     * This function builds a functional search URL that will redirect the user to tabbed-browsing
     * @param {Object} data
     *  @param {String} mode the search mode ex: air
     *  @param {String} flightType oneway or roundtrip
     *  @param {Number} originId ta id of the origin airport
     *  @param {Number} destinationId ta id of the destination airport
     *  @param {String} departureDate YYYY-MM-DD the departure date
     *  @param {String} returnDate YYYY-MM-DD the return date if roundtrip
     *  @param {Number} numTravelers the number of travelers
     *
     *  @return {String}
     */

    this.buildTabbedBrowsingSearchUrl = function (data) {
        return this.attr.baseUrl + this.attr.tabbedBrowsingUrl + "?" + $.param(data);
    };

    this.buildTabbedBrowsingHotelSearchUrl = function (data) {
        return this.attr.baseUrl + this.attr.tabbedBrowsingHotelUrl + "?" + $.param(data);
    };
};

module.exports = mixin;
