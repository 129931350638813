
var mixin = function () {
    this.attributes({
        emailCheckbox: ".sub-widget-email-checkbox #emailCheckbox"
    });

    this.updateCheckbox = function () {
        var checkbox = this.select("emailCheckbox");
        checkbox.attr("checked", !checkbox.attr("checked"));
    };

    this.after("initialize", function () {
        this.on(this.select("emailCheckbox"), "change", this.updateCheckbox);
    });
};

module.exports = mixin;