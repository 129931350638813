var baseModal = require("src/desktop/modules/user-subscription-modal/lib/base/user-subscription-modal");
var UserSubscriptionForm = require("src/desktop/modules/flight-search-form/lib/ui/user-modal-subscription-form");
var ExtUserSubscriptionForm = require("src/desktop/modules/flight-search-form/lib/ui/user-modal-ext-sub-form");
var FacebookLogin = require("src/desktop/modules/facebook-login/lib/ui/facebook-login-button-email-fill");

var component = baseModal.mixin(function () {

    this.attributes({
        _subscriptionFormSelector: ".subscription_modal",
        _stSubFormSelector: ".subscription_modal_ext[data-sub-site=\"st\"]",
        _jsSubFormSelector: ".subscription_modal_ext[data-sub-site=\"js\"]",
        _facebookLoginSelector: ".facebook-login",
    });

    this.after("initialize", function() {
        UserSubscriptionForm.attachTo(this.select("_subscriptionFormSelector"), {_id : "user_subscription_modal"});
        ExtUserSubscriptionForm.stSubForm.attachTo(this.select("_stSubFormSelector"), {_id: "user_subscription_modal_st"});
        ExtUserSubscriptionForm.jsSubForm.attachTo(this.select("_jsSubFormSelector"), {_id: "user_subscription_modal_js"});
        FacebookLogin.attachTo(this.select("_facebookLoginSelector"));
    });

});

module.exports = component;