var baseAirSearchForm = require("src/desktop/modules/flight-search-form/lib/base/air-basic-search-form");
var withStSubs = require("src/desktop/modules/flight-search-form/lib/mixins/with-st-sub-modal");
var withJsSubs = require("src/desktop/modules/flight-search-form/lib/mixins/with-js-sub-modal");
var withSubs = require("src/desktop/modules/flight-subs/lib/mixins/with-subs");

var modalSubMixin = function () {
    this.attributes({
        _id: null,
        submitSelector: ".do_submit",
        formSelector: ".subscription-modal-ext-form",
        emailContainerSelector: ".st_sub_email_field",
        emailInput: "input[type='email']",

        errorClass: "error",

        /**
         * @event ui:user:subscribed:success => event to show success message
         */
        EV_USER_SUBSCRIBED_SUCCESS: "ui:user:subscribed:success",
    });

    /**
     * We only can do something if the sub fails with a email related error. So if the error code does not represent a
     * bad email we will fail silently.
     *
     * @override with-subs
     * @param {Object} data
     *  @param {Boolean} success
     *  @param {String} msg
     *  @param {Number} code
     */
    this.handleBulkSubJsonResponse = function (data) {
        if (data && data.code === this.attr.INVALID_EMAIL_CODE) {
            this.select("emailContainerSelector").addClass(this.attr.errorClass);
        } else {
            // trigger event to notify interested components
            var eventData = {
                nextView: "success",
                showExternalSiteSuccessMessage: true,
                userEmail: "",
                siteForSubRequest: data.siteForSubRequest
            };
            this.trigger(this.attr.EV_USER_SUBSCRIBED_SUCCESS, eventData);
        }
    };

    /**
     * Subscribes the user to ST-specific alerts
     * If the user is already subbed this will fail silently
     *
     * In user_modal_st_subscription, we are making subscriptions for SmarterTravel.com subscriptions
     * Note:- These are ST-related subs, we will override the site for this request
     *
     * @param {Object} formData see getFormData return
     */
    this.doSub = function (formData) {
        this.applyOtherSubsForExternalSiteUserModal({
            id: this.attr._id,
            email: formData.email,
            requestedSubscriptions: []
        });
    };

    this.clearErrors = function () {
        this.select("emailContainerSelector").removeClass(this.attr.errorClass);
    };

    /**
     * Show error for form fields
     * @param element - jQuery Object
     */
    this.showError = function (element) {
        element.addClass(this.attr.errorClass);
    };

    /**
     * Validate before submitting data
     * @param formData
     * @return {Boolean}
     */
    this.isValidData = function (formData) {
        if(!formData.email.trim()) {
            this.showError(this.select("emailContainerSelector"));
            return false;
        }

        return true;
    };

    this.getFormData = function() {
        return {email: this.select("emailInput").val()};
    };

    this.submit = function (event) {
        event.preventDefault();
        this.clearErrors();
        var formData = this.getFormData();

        if (!this.isValidData(formData)) {
            return;
        }

        this.doSub(formData);
    };



    this.after("initialize", function () {
        this.on(this.select("formSelector"), "submit", this.submit);
    });

};

module.exports = {
    stSubForm: baseAirSearchForm.mixin(withSubs, withStSubs, modalSubMixin),
    jsSubForm: baseAirSearchForm.mixin(withSubs, withJsSubs, modalSubMixin)
};
