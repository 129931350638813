/**
 * This mixin adds an unique id to a component.
 * Should be used if you're firing events and need to pass an id to make sure the response ends up in the correct spot. e.g:
 *
 * Will create `this.attr._id` containing a unique id
 * And will ONLY attach the id to the component.$node if the component is not attached to `body` or `document`
 *
 * - There are 2 location suggest components on the page
 *   1. user types in an input and the component fires a "Ui:suggest:request"
 *   2. After the data has been retrieved from the server, the correct location suggest input should be updated
 *   3. This is achieved by data component sending the id of the component that fired the request
 */
define(function (require){
   "use strict";

   return WithUniqueId;

   function WithUniqueId() {

     /**
      * Creates and returns a "unique" id
      * NOTE: _id will also be attached to the $node data
      */
     this._uniqueid = function () {
       // always start with a letter (for DOM friendlyness)
       var idstr = '';
       do {
         // between numbers and characters (48 is 0 and 90 is Z (42-48 = 90)
         var ascicode = Math.floor((Math.random()*42)+48);
         // exclude all chars between : (58) and @ (64)
         if (ascicode < 58 || ascicode > 64){
           idstr+=String.fromCharCode(ascicode);
         }
       } while (idstr.length < 8);


       // don't attacht the id to the $node if it can be overwritten
       if (!this.$node.is('body') || !this.$node.is(document)) {
         this.$node.data('_id', idstr);
       }

       return idstr;
     };
   }
});
