var $j = require("jquery");
var stm = require("stm");

// NOTE: the following lines should be uncommented to enable debugging in flight.js
// In addition, you'll have to comment out some of the flight source code for stuff flight throws exceptions for in
// debug mode only (which means we never caught them while developing in regular production mode):
// - js/vendor/flight/lib/base.js lines 38-40 (starting with `throw new Error`)
// - js/vendor/flight/lib/utils.js lines 18-23, and 25-28 (so the func `canWriteProtect` always returns false)
// var flightDebug = require("flight/lib/debug");
// flightDebug.enable(true);
// window.DEBUG.events.logAll(); // jshint ignore:line

var SearchDataCookieComponent = require("src/desktop/modules/flight-cs-cookie/lib/data/cs-cookie");
var SubDataComponent = require("src/desktop/modules/flight-subs/lib/data/sub");
var StPopunder = require("src/desktop/modules/st-popunder/lib/ui/st-blender-popunder");

var NavUi = require("src/desktop/modules/flight-nav-bar/ui/flight-nav-bar");


var NavAirSearchForm = require("src/desktop/modules/flight-search-form/lib/ui/air-details-sub-search-form");
var SiteIdUtils = require("src/desktop/utils/siteId");

var DartAdContainer = require("src/desktop/modules/flight-dart/lib/ui/flight-dart-mobile-adhesion-container");

var MetricsDataComponent = require("src/desktop/modules/flight-metrics/data/metrics");
var TrackMetricsPageLoad = require("src/desktop/modules/flight-metrics/ui/track_metrics_page_load");
var PushcrewTracking = require("src/desktop/modules/flight-awd-tracking/lib/data/pushcrewTracking");
var AddThisPoller = require("src/desktop/modules/poller/add-this-poller");

var ReactSearchEventHandlers = require("src/desktop/modules/react-app-interactions/search-event-handlers");

var PageData = stm.pageData.hwd;


/**
 * ===========================================================================
 */

$j(function (){
    var site = SiteIdUtils.getSite(PageData.siteId);

    MetricsDataComponent.attachTo("body");
    TrackMetricsPageLoad.attachTo(".do_track_metrics_page_load");

    StPopunder.attachTo("body", { site: site });

    NavUi.attachTo(".responsive_header");

    SubDataComponent.attachTo("body", {
        baseUrl: PageData.endPointContext,
        site: site
    });

    SearchDataCookieComponent.attachTo("body");

    PushcrewTracking.attachTo("body");

    NavAirSearchForm.attachTo(".do_nav_air_search", {_id: "nav_air_search_form", deviceType: PageData.device.deviceType });

    var isMobile = stm.pageData.hwd.device.mobile;
    if (isMobile) {
        DartAdContainer.attachTo("body", {});
    }

    AddThisPoller.attachTo("body");

    ReactSearchEventHandlers.attachTo("body");
});
