var _ = require("underscore");
var $j = require("jquery");
var stm = require("stm");

var define = stm.define;
var Shared = stm.shared;
var rootContext = stm.pageData.hwd.endPointContext;
var DartAdModel = require("src/desktop/modules/dart/models/dart");
var DartAdView = require("src/desktop/modules/dart/views/dartAd");
var ViewPortModel = require("src/desktop/modules/visibility/model/viewPort");

// ---------------------------------------------------------------------------------------------- //

var DartAdController = define(null, Shared.getBackboneEvents(), {
    /**
     * @param {Jquery Element} $el
     * @param {String} pageIdentifier the identifier to be used when building dart ad urls
     * @param {ViewPortModel} viewPortModel a model that triggers if the view port has changed
     * @param {object} options
     * @constructor
     */
    initialize: function($el, pageIdentifier, viewPortModel, options) {
        this.$el = $el;
        this.pageIdentifier = pageIdentifier;
        this.viewPortModel = viewPortModel;
        this.onViewPortChange = _.throttle(this.onViewPortChange, 200);
        this.initializeDartAds = _.once(this.initializeDartAds);

        this.options = _.extend({}, this.options(), options);
    }

    /* ----------
     Properties
     ---------- */

    /**
     * @type {Jquery}
     */
    , $el: null

    /**
     * @type {String}
     */
    , pageIdentifier: null

    /**
     * @type {ViewPortModel}
     */
    , viewPortModel: null

    /**
     * The index of the next placed ad.
     */
    , index: 1

    /**
     * The position identifier of the ad. Defaults to "a".
     */
    , position: "a"

    /**
     * The event to trigger on window, if any element is waiting to perform some task after Ad shown on the page
     */
    , EV_VALID_AD_LOADED: "ad:loaded:successfully"

    /**
     * @event data:tds:request - the event to request data from the traveler data service
     */
    , EV_TRAVELER_DATA_REQUEST: "data:tds:request"

    /**
     * @event data:tds:response - the event containing the most recent user search data from the traveler data service
     * @see traveler-data-service module for documentation
     */
    , EV_TRAVELER_DATA_RESPONSE: "data:tds:response"

    /**
     * @event data:tls:request - the event to request data from the traveler label service
     */
    , EV_TRAVELER_LABEL_REQUEST: "data:tls:request"

    /**
     * @event data:tls:response - the event containing label ids from the traveler data service
     * @see traveler-label-service module for documentation
     */
    , EV_TRAVELER_LABEL_RESPONSE: "data:tls:response"

    /**
     * @property {Object} travelerData - the most recent user search from the traveler data service
     * @see https://stash.st.cognius.net/projects/PBR/repos/traveler-data-service/browse/docs/Api.md#userSearch
     * @see https://stash.st.cognius.net/projects/PBR/repos/pbr-core/browse/src/main/java/com/smartertravel/pbr/core/entity/search/UserSearch.java
     */
    , travelerData: null

    /**
     * @property {number[]} travelerLabels - a list of traveler label ids from the traveler label service
     */
    , travelerLabels: null

    /**
     * An array of the jquery elements that have not yet been seen by the user.
     * @type {[jQuery]}
     */
    , unseenDartAds: []

    /**
     * @type {Object}
     */
    , options: function () {
        return {
            viewSelector: ".do_dart_ad",
            pageIdentifier: null,
            ord: new Date().getTime()
        };
    }

    /* --------------
     Public Methods
     -------------- */

    /**
     * Start executing. Kicks off requests to the traveler data and traveler label services, and initializes the
     * ads after responses are received.
     */
    , start: function() {
        var self = this;

        $j(window).on(this.EV_TRAVELER_DATA_RESPONSE, function(e, data) {
            self.travelerData = data && data.userSearch;
            if (self.travelerData && self.travelerLabels) {
                self.initializeDartAds();
            }
        });
        $j(window).on(this.EV_TRAVELER_LABEL_RESPONSE, function(e, data) {
            self.travelerLabels = data && data.labelIds;
            if (self.travelerData && self.travelerLabels) {
                self.initializeDartAds();
            }
        });

        $j(window).trigger(this.EV_TRAVELER_DATA_REQUEST);
        $j(window).trigger(this.EV_TRAVELER_LABEL_REQUEST);
    }

    , initializeDartAds: function() {
        var elements = this.$el.find(this.options.viewSelector);
        var self = this;

        _.each(elements, function (element) {
            element = $j(element);
            if (self.viewPortModel && !self.viewPortModel.isElementInViewport(element)) {
                if (self.isForcedShowDartAd(element)) {
                    self.createDartAdView(element);
                    return;
                } else {
                    self.unseenDartAds.push(element);
                    return;
                }
            }

            self.createDartAdView(element);
        });

        self.listenTo(self.viewPortModel, ViewPortModel.VIEWPORT_CHANGED, self.onViewPortChange);
    }

    /**
     * Stop executing.
     *
     * @return {this}
     */
    , stop: function() {

    }

    /**
     * Creates the dart ad view for the provided element
     * @param {jQuery} element
     */
    , createDartAdView: function (element) {
        var model = new DartAdModel(
            _.extend({}, this.options, {rootContext: rootContext})
        );

        var position = element.attr("data-ad-position");

        var view = new DartAdView({
            el: element,
            model: model,
            order: this.index++,
            position: position ? position : this.position,
            ord: this.options.ord,
            pageIdentifier: this.pageIdentifier,
            contextRoot: rootContext,
            dartAdZone: this.options.dartAdZone,
            searchData: this.options.searchData,
            travelerData: this.travelerData,
            travelerLabels: this.travelerLabels
        });

        this.listenTo(view, DartAdView.VALID_AD, this.onValidAd);
        this.listenTo(view, DartAdView.INVALID_AD, this.onNoAd);
        view.render();
    }

    /**
     * Checks to see if any of the previously unseen dart ads are now visible. If they are remove them from the array and
     * create a dart ad view.
     */
    , onViewPortChange: function () {
        var self = this;
        var elements = this.unseenDartAds.slice(0);
        var newUnseen = [];

        _.each(elements, function (element) {
            if (self.viewPortModel && !self.viewPortModel.isElementInViewport(element)) {
                if (self.isForcedShowDartAd(element)) {
                    self.createDartAdView(element);
                    return;
                } else {
                    newUnseen.push(element);
                    return;
                }
            }
            self.createDartAdView(element);
        });

        this.unseenDartAds = newUnseen;
    }

    /**
     * Handles the case where an ad has been loaded in the view.
     * @param {DartAdView} view
     */
    , onValidAd: function(view) {
        view.$el.height("100%");
        view.$el.find(">div").css("display","block") ;

        $j(window).trigger(this.EV_VALID_AD_LOADED);
    }

    /**
     * Handles the case where there is no ad in the view. If the view is the rising star add it will close the whole
     * top row.
     * TODO: move this logic to a more page specific location
     * @param {DartAdView} view
     */
    , onNoAd: function (view) {
        if (view.$el.hasClass("rising_star")) {
            view.$el.parents(".top_row").removeClass("visible-md visible-lg");
            view.$el.parents(".top_row").hide();
        } else {
            view.$el.hide();
        }
    }

    /**
     * Checks to see if the element is a dart ad that we're going to force-show
     * @param {jQuery} element
     */
    , isForcedShowDartAd: function (element) {
        element = element[0];
        var forceShow = element.getAttribute("data-ad-force-show");

        return (forceShow === "true");
    }

    /* --------------
     Event Handlers
     -------------- */

    , STATIC: {
        /* ------------------
         Static - Constants
         ------------------ */

        /* ---------------
         Static - Events
         --------------- */

        /* ----------------
         Static - Methods
         ---------------- */

    }
});

// ---------------------------------------------------------------------------------------------- //

module.exports = DartAdController;