var defineComponent = require("flight/lib/component");
var withDataCollection = require("src/desktop/modules/data-services/mixins/with-data-collection");

var component = defineComponent(reactSearchEventHandlers).mixin(withDataCollection);

function reactSearchEventHandlers() {
    this.attributes({
        /**
         * @event "data:react:exact:search:submitted" this event is fired by the React/Redux part of the codebase in response to an exact search form submission
         *  @param {Object} event jQuery event
         *      @param {Object} event.originalEvent.detail.searchData See https://stash.st.cognius.net/projects/AWD/repos/awdjava/browse/web/src/main/reactapp/src/models/SearchData/index.ts
         */
        EV_DATA_REACT_EXACT_SEARCH_SUBMITTED: "data:react:exact:search:submitted"
    });

    this.onReactExactSearchSubmitted = function(event) {
        var eventDetail = event && event.originalEvent && event.originalEvent.detail;
        var searchData = eventDetail.searchData;
        var date1Str = searchData.date1 && searchData.date1.format("YYYY-MM-DD");
        var date2Str = searchData.date2 && searchData.date2.format("YYYY-MM-DD");

        // Track to collection service
        try {
            this.trackPageActivity({
                type: "click",
                product_type: "air",
                origin: searchData.location1.suggestion.airportCode,
                destination: searchData.location2.suggestion.airportCode,
                date_1: date1Str,
                date_2: date2Str,
                num_adults: searchData.travelers,
                name: "find_fares"
            });
        } catch (e) {
            // so this never breaks the page
        }

        // Track to GTM
        window.dataLayer.push({
            "event": "tabbedBrowsingSearch",
            "tabbedBrowsingSearchData": {
                mode: "air",
                flightType: searchData.flightSearchType,
                originId: searchData.location1.suggestion.id,
                destinationId: searchData.location2.suggestion.id,
                departureDate: date1Str,
                returnDate: date2Str,
                numTravelers: searchData.travelers
            }
        });
    };

    this.after("initialize", function () {
        // Until we port this over to React/Redux-land (AWD-3671), we'll fire an event and handle the tracking here
        this.on(document, this.attr.EV_DATA_REACT_EXACT_SEARCH_SUBMITTED, this.onReactExactSearchSubmitted);
    });
}

module.exports = component;
