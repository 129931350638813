/**
 * This is the base component for search data. It should only contain fields that will be used by all search verticals.
 * If vertical search data needs to be tracked please create a new component that uses this component as a base and
 * mixes in all of the the functionality.
 *
 *
 * DOM Element Requirements:
 * =========================
 *  This element should be extremely high up the DOM tree as to catch any and all search data changes triggered by ui interaction.
 *  The Element it is placed on can have the following data attributes:
 *
 *  1) data-search-data: This should be a json representation of the awd java world search data model.
 *
 *  ex)
 *   <div class="do_search_data" data-search-data="{"searchType":"hotel","location1CityId":null,"location1AirportTaId":null,"location1AirportCode":null,"location1Name":null,"location1CityDisplayName":null,"location2CityId":60745,"location2AirportTaId":null,"location2AirportCode":null,"location2Name":"Boston","location2CityDisplayName":"Boston, Massachusetts, United States","provider":null,"searchDate1":{"date":"2015-06-16","time":"anytime"},"searchDate2":{"date":"2015-06-17","time":"anytime"},"flexDays":null,"travelers":2,"rooms":"2","nonStop":null,"serviceClass":null,"flightSearchType":null}"></div>
 *
 *
 * Listens To:
 * ===========
 * @event ui:searchData:changed => search data has been changed by a ui component
 *  @param {Object} searchData - the search data that has changed
 *
 * @event data:persistentSearchData:provided => search data has been provided from some persistent store
 *  @param {Object} searchData - the search data that was stored in some persistent store.
 *
 *
 * Triggers:
 * =========
 * @event data:searchData:changed => the search data in this component has been updated
 *  @param {Object} searchData - the full data set currently contained in the component
 *
 * @event data:persistentSearchData:request => requests search data from a persistent store
 *
 *
 * On initialize if search data cannot be found by checking the dom element for data attributes or the attribute contains
 * a null object the component will trigger the 'data:persistentSearchData:request' in hopes that there is another component
 * out there that can provide it some search data to transmit to any listening components.
 *
 */
define(function (require) {

    'use strict';

    /**
     * Module dependencies
     */

    var defineComponent = require('flight/lib/component');
    var $ = require('jquery');

    /**
     * Module exports
     */

    return defineComponent(searchData);

    /**
     * Module function
     */

    function searchData() {
        this.defaultAttrs({

            /**
             * Events
             */

            /**
             * @event ui:searchData:changed => search data has been changed by a ui component
             *  @param {Object} searchData - the search data that has changed
             */
            EV_UI_SEARCH_DATA_CHANGED: 'ui:searchData:changed',

            /**
             * @event data:searchData:changed => the search data in this component has been updated
             *  @param {Object} searchData - the full data set currently contained in the component
             */
            EV_DATA_SEARCH_DATA_CHANGED: 'data:searchData:changed',

            /**
             * @event data:persistentSearchData:request => requests search data from a persistent store
             */
            EV_DATA_PERSISTENT_SEARCH_DATA_REQUESTED: 'data:persistentSearchData:request',

            /**
             * @event data:persistentSearchData:provided => search data has been provided from somewhere
             *  @param {Object} searchData - the search data that was stored in some persistent store.
             */
            EV_DATA_PERSISTENT_SEARCH_DATA_PROVIDED: 'data:persistentSearchData:provided',


            /**
             * Search data attrs
             */
            searchType: '',


            /**
             * data selector
             */
            _searchDataSelector: 'search-data'

        });

        /**
         * Returns an array of base search data keys.
         * @return {Array} an array of strings that contains the keys to pull search data out of this components attrs
         */
        this.getBaseSearchDataKeys = function () {
            return [
                'searchType',
                'id'
            ];
        };

        /**
         * This function exists so that mixins to this base component can easily specify other search data keys w/o having
         * to define exiting search data keys.
         * @returns {Array} an array of strings that are keys for search data values
         */
        this.getSearchDataKeys = function () {
            return this.getBaseSearchDataKeys();
        };

        /**
         * Returns any search data that the model supports it will only return search data fields that have been explicitly
         * defined.
         * @returns {Object} the search data the component supports
         */
        this.getSearchData = function () {
            var searchDataKeys = this.getSearchDataKeys();

            var searchData = {};

            for (var key in searchDataKeys) {
                searchData[searchDataKeys[key]] = this.attr[searchDataKeys[key]];
            }

            return searchData;
        };

        /**
         * This function sets the internal search data and triggers an event to any listeners
         * with the new data.
         */
        this.updateInternalModel = function (event, searchData) {
            $.extend(this.attr, searchData);
            if (searchData.id === undefined) {
                this.attr.id = undefined;
            }
            this.trigger(this.attr.EV_DATA_SEARCH_DATA_CHANGED, this.getSearchData());
        };

        /**
         * Attempts to load search data from the dom otherwise it will trigger a request
         * for data from a persistent store.
         */
        this.setup = function () {
            var searchData = this.$node.data(this.attr._searchDataSelector);
            if (searchData) {
                this.updateInternalModel(null, searchData);
            } else {
                this.trigger(this.attr.EV_DATA_PERSISTENT_SEARCH_DATA_REQUESTED);
            }
        };

        this.after('initialize', function () {
            this.on(this.attr.EV_UI_SEARCH_DATA_CHANGED, this.updateInternalModel);
            this.on(this.attr.EV_DATA_PERSISTENT_SEARCH_DATA_PROVIDED, this.updateInternalModel);
            this.setup();
        });


    }


});
