/**
 * This component is meant to be a replacement for the popunder component.
 *
 * It requires a url attribute, which determines the url for the popunder to open.
 *
 * The cookieName attribute specifies which cookie to set and check in order to determine whether or not the
 * popunder should be opened.
 */

var WindowOpenerBase = require("@smarter/window-opener");
var defineComponent = require("flight/lib/component");
var CookieUtils = require("src/desktop/utils/cookies");
var stm = require("stm");
var $j = require("jquery");


var component = defineComponent(function () {
    this.attributes({
        /**
         * The URL to be opened in the popunder.
         * @type {String}
         */
        url: "/Best-Airfares-and-Travel-Advice/",

        /**
         * Classname for elements that should not trigger a popunder upon click
         */
        noPopunderClass: "do_no_popunder",

        /**
         * The cookie to be checked to see if the popunder should open.
         * @type {String}
         */
        cookieName: "blender_popunder",

        /**
         * The width of the popunder in pixels.
         * @type {number}
         */
        width: 1000,


        /**
         * The height of the popunder in pixels.
         * @type {number}
         */
        height: 672,

        /**
         * The amount of time before the cookie that determines whether or not the pop under will appear
         * will expire.
         */
        cookieExpiration: 1
    });

    /**
     * Determines whether or not the popunder should be opened.
     *
     * @param event the clickevent
     * @returns {boolean} if the popunder should be opened
     */
    this.shouldOpen = function (event) {
        var cookie = CookieUtils.getCookie(this.attr.cookieName);

        var userAgentNormalized = navigator.userAgent.toLowerCase();
        var isNotDesktop = stm.browser.Platform.ios ||
            (stm.browser.Platform.android && (userAgentNormalized.indexOf("mobile") !== -1)) ||
            (userAgentNormalized.indexOf("tablet") !== -1);
        var isFirefox = stm.browser.firefox;

        var $target = $j(event.target);
        var restrictedElements = "input, a[target='_blank'], button, ." + this.attr.noPopunderClass;
        var isNoPopunder = $target.is(restrictedElements) || $target.parents(restrictedElements).length;

        return !cookie && !isNotDesktop && !isNoPopunder && !isFirefox;
    };

    /**
     * Sets the cookie specifying that the popunder has been opened, and makes the cookie expire in a day.
     */
    this.setCookie = function () {
        CookieUtils.setCookie(this.attr.cookieName, "true", this.attr.cookieExpiration);
    };

    /**
     * Opens the popunder and sets the cookie that the popunder has been opened.
     * Does not check first if popunder should be opened.
     *
     * @param windowOpener A windowOpener object created by WindowOpenerBase.create
     */
    this.open = function (windowOpener) {
        var scheme = windowOpener.scheme.custom({
            customScheme: { width: this.attr.width, height: this.attr.height }
        });

        windowOpener.openPopunder({
            url: this.attr.url,
            scheme: scheme
        });
        this.setCookie();
    };

    this.onNodeClick = function (windowOpener) {
        return function (event) {
            if (this.shouldOpen(event)) {
                this.open(windowOpener);
            }
        };
    };

    this.setup = function () {
        this.on("click", this.onNodeClick(WindowOpenerBase.create({})).bind(this));
    };

    this.after("initialize", this.setup);
});

module.exports = component;