/**
 * @override of base/autosuggest to work with desktop browsers.
 *
 * Triggers:
 * =========
 *  `ui:autoSuggest:switch`  =>  after auto suggest has been hidden with tab or enter (switch to other autosuggest)
 */
define(['jquery'], function ($) {

    return function () {

        this.attributes({

            EV_AUTOSUGGEST_SWITCH: "ui:autoSuggest:switch",

            EV_SEARCH_DATA_AFTER_UPDATE: "ui:searchData:afterUpdate",

            /**
             * The css selector for a selected suggestion
             */
            selected: ".selected",

            tabKeyCode: 9,

            enterKeyCode: 13,

            upArrowKeyCode: 38,

            downArrowKeyCode: 40,

            zIndexProperty: "z-index",

            _iconMsgSelector: "i, span.msg",

            _inputSelector: "input",

            _labelSelector: "label",

            _fixedLayerSelector: ".fixed-layer",

            copyPlaceholder: false
        });

        this.resize = function () {
            this.setWidthAndPosition(this.attr.$autoSuggest);
        };

        /**
         * Creates a new Location-Suggest element to be used with this instance
         */
        this.createLocationSuggest = function () {

            this.attr.$autoSuggest = $(this.toHtml());

            $('body').append(this.attr.$autoSuggest);

            var self = this;

            //Need to focus inputField in modal to get virtual-keyboard visible on mobile devices
            //Set time out is a really bad IE fix http://www.mkyong.com/javascript/focus-is-not-working-in-ie-solution/
            setTimeout(function () {
                var autoSuggest = self.attr.$autoSuggest;

                if (!autoSuggest) {
                    return;
                }

                self.setWidthAndPosition(autoSuggest);
                self.on(autoSuggest.find('input'), 'blur', self.shouldCloseAutoSuggest);
                autoSuggest.find('input').focus();
            }, 10);

            // Binding of event is done after components are created,
            // Since components did not exist in prior.
            this.on(self.attr.$autoSuggest.find('input'), 'keydown', this._onKeyDown);
            this.on(self.attr.$autoSuggest.find('input'), 'keyup', this._onKeyUp);
            $(window).on('scroll', this.resize.bind(this));
            $(window).on('resize', this.resize.bind(this));
        };


        /**
         * @param {Array<Object>} items
         */
        this.updateList = function (items) {
            var $suggestionResults = $(this.attr.suggestionResultsSelectorWithId);
            var $suggestionResultsList = $suggestionResults.find('ul');

            if (!items[0]) {
                $suggestionResultsList.empty();
                return;
            }

            $suggestionResultsList.html(this.buildItemListHtml(items));

            // Highlight first option when results are returned
            this.selectDown();

            // Attaching Event only after item(s) is/are available on the page
            var $suggestionResultsList = $suggestionResults.find(this.attr.listSelector);
            this.on($suggestionResultsList, 'mouseenter', this.onItemHover);
        };


        /**
         * This detects if a click is inside of the autosuggest container and will close the location suggest box if it is outside of the box
         *
         * If there is a selected option we will update with the option.
         *
         * @param {Event} event the click event
         * @returns {boolean} if the click is in or outside of the autosuggest container.
         */
        this.shouldCloseAutoSuggest = function (event) {
            if (!event) {
                return false;
            }

            var $target = $(event.target);
            if (!this.attr.$autoSuggest || (document.activeElement !== this.attr.$autoSuggest.find("input")[0])) {
                this.closeAutoSuggest();

                return true;
            }

            return false;
        };

        /**
         * Closes the autosuggest widget and updates the input to the currently selected option if it exists
         */
        this.closeAutoSuggest = function () {
            var $suggestionResults = $(this.attr.suggestionResultsSelectorWithId);
            var selected = $suggestionResults.find(this.attr.selected);
            if (selected.length) {
                var dataValue = selected.data('value'),
                    displayName = selected.data('value').displayName;

                this.updateInput(dataValue, displayName);

                this.trigger(this.attr.EV_AUTOSUGGEST_SELECTED, {
                    dataValue: dataValue,
                    displayName: displayName,
                    id: this.attr._id
                });
            }

            this.hideLocationSuggest();
        };

        this.afterUpdate = function(ev, data) {
            if (data.id !== this.attr._id) return;

            this.trigger(this.attr.EV_AUTOSUGGEST_SWITCH, {
                tabIndex: parseInt(this.select("_inputSelector").data("tab-index")),
                reverse: false
            });
        };

        this.arrowKeyPressed = function (ev) {
            if (ev.keyCode == this.attr.upArrowKeyCode) {
                this.selectUp();
            } else if (ev.keyCode == this.attr.downArrowKeyCode) {
                this.selectDown();
            }
        };

        this.selectUp = function () {
            var $suggestionResults = $(this.attr.suggestionResultsSelectorWithId);
            var selected = $suggestionResults.find(this.attr.selected);
            if (selected.length && selected.index() > 0) {
                var index = selected.index();
                var newIndex = index - 1;
                this.selectIndex(newIndex);
            }
        };

        this.selectDown = function () {
            var $suggestionResults = $(this.attr.suggestionResultsSelectorWithId);
            var selected = $suggestionResults.find(this.attr.selected);
            var maxIndex = $suggestionResults.find("li").size() - 1;
            if (!selected.length && maxIndex >= 0) {
                this.selectIndex(0);
            } else if (selected.length && selected.index() < maxIndex) {
                var index = selected.index();
                var newIndex = index + 1;
                this.selectIndex(newIndex);
            }
        };

        this.selectIndex = function (index) {
            var $suggestionResults = $(this.attr.suggestionResultsSelectorWithId);
            var selected = $suggestionResults.find(this.attr.selected);
            selected.removeClass(this.attr.selected.substring(1));
            $suggestionResults.find("li:eq(" + index + ")").addClass(this.attr.selected.substring(1));
        };

        this.onItemHover = function (event) {
            var $target = $(event.target);

            var $suggestionResults = $(this.attr.suggestionResultsSelectorWithId);
            var selected = $suggestionResults.find(this.attr.selected);
            selected.removeClass(this.attr.selected.substring(1));
            $target.addClass(this.attr.selected.substring(1));
        };


        /**
         * @param {Event} ev
         *                  - {HtmlElement} el
         */
        this._onKeyDown = function (ev) {

            if ((ev.keyCode == this.attr.enterKeyCode) || (ev.keyCode == this.attr.tabKeyCode)) {
                ev.preventDefault();
                this.closeAutoSuggest();

                setTimeout(function () {
                    this.trigger(this.attr.EV_AUTOSUGGEST_SWITCH, {
                        tabIndex: parseInt(this.select("_inputSelector").data("tab-index")),

                        reverse: ev.shiftKey && ev.keyCode === this.attr.tabKeyCode
                    });
                }.bind(this), 0);


                return;
            }
        };

        /**
         * @param {Event} ev
         *                  - {HtmlElement} el
         */
        this._onKeyUp = function (ev) {
            if (ev.keyCode == this.attr.downArrowKeyCode || ev.keyCode == this.attr.upArrowKeyCode) {
                this.arrowKeyPressed(ev);
                return;
            }

            var $suggestionResults = $(this.attr.suggestionResultsSelectorWithId);
            var val = $(ev.currentTarget).val();
            if (val.length < this.attr.minLetters) {
                // Hide only list container
                $suggestionResults.hide();
                return;
            }

            var request = {
                params: {
                    query: val
                },
                searchTypePath: this.attr.searchTypePath,
                autoSuggestId: this.attr._id
            };

            // Currently only used on air searches
            if (this.attr.searchType) {
                request.params.search_type = this.attr.searchType;
            }

            if (this.attr.includeMetroAirports) {
                request.params.include_metro_airports = this.attr.includeMetroAirports;
            }


            this.trigger(this.attr.EV_AUTOSUGGEST_REQUEST, request);
        };


        /**
         * Hides the Location Suggest
         * @triggers EV_AUTOSUGGEST_HIDE
         * @param {Event|Null} ev - mouse click event if the user got here by clicking the close button
         */
        this.hideLocationSuggest = function (ev) {
            this.hideModal();
            $(window).off('scroll', this.resize);
            $(window).off('resize', this.resize);

            if (this.attr.$autoSuggest) {
                this.off(this.attr.$autoSuggest.find('input'), 'blur');
                delete this.attr.$autoSuggest;
            }

            this.resetIcons();
            this.trigger(this.attr.EV_AUTOSUGGEST_HIDE, {
                $node: this.$node
            });
        };


        /**
         * Overlays the icon and msg to be above the autosuggest box
         * @param $autosuggest the autosuggest modal
         */
        this.overlayIcons = function ($autosuggest) {
            this.select("_iconMsgSelector").css(this.attr.zIndexProperty,
                parseInt($autosuggest.css(this.attr.zIndexProperty)) + 1);
        };

        /**
         * Resets the z-indexes of the icon and msg
         */
        this.resetIcons = function () {
            this.select("_iconMsgSelector").css(this.attr.zIndexProperty, "");
        };

        /**
         * Moves the autosuggest over the input.
         * @param $autosuggest the autosuggest modal
         */
        this.setWidthAndPosition = function ($autosuggest) {
            if (!$autosuggest) {
               return;
            }

            if (!this.$node.is(":visible")) {
                this.closeAutoSuggest();
                return;
            }

            var pos = this.$node.offset();
            var width = this.select("_labelSelector").width();
            var height = this.select("_labelSelector").height();
            var paddingLeft = this.$node.css("padding-left");

            pos.left += parseInt(paddingLeft);

            $autosuggest.css("position", "absolute");
            $autosuggest.offset(pos);
            $autosuggest.width(width);
            $autosuggest.height(height);

            // Remove top padding because otherwise the autosuggest will be slightly vertically misaligned
            $autosuggest.css("padding-top", 0);

            $autosuggest.find(this.attr._inputSelector).css("font", this.select("_inputSelector").css("font"));
            if (this.attr.copyPlaceholder) {
                $autosuggest.find(this.attr._inputSelector).attr("placeholder", this.select("_inputSelector").attr("placeholder"));
            }

            // Sets the padding of the autosuggest modal's input to be the same
            // as the padding-left of the input it covers up. For example, on the home page, it says
            // "Departure City" and this makes it such that when you start typing, the cursor starts out
            // at where the beginning of the "Departure City" text starts.
            $autosuggest.find(this.attr._inputSelector).css("padding-top", this.select("_inputSelector").css("padding-top"));
            $autosuggest.find(this.attr._inputSelector).css("padding-left", this.select("_inputSelector").css("padding-left"));
            $autosuggest.find(this.attr._inputSelector).css("padding-right", this.select("_inputSelector").css("padding-right"));
            $autosuggest.find(this.attr._inputSelector).css("padding-bottom", this.select("_inputSelector").css("padding-bottom"));

            $autosuggest.find(this.attr._inputSelector).css("font-size", this.select("_inputSelector").css("font-size"));
            $autosuggest.find(this.attr._inputSelector).css("font-family", this.select("_inputSelector").css("font-family"));
            $autosuggest.find(this.attr._inputSelector).css("font-weight", this.select("_inputSelector").css("font-weight"));

            // Account for border padding to vertically center our text
            $autosuggest.find(this.attr._inputSelector).css("padding-top", parseInt(this.select("_inputSelector").css("padding-top")) -  parseInt($autosuggest.find(this.attr._inputSelector).css("border-top")) + "px");

            // Override padding-left for autosuggest inputs within a fixed-position container as the underlying
            //  input text/icon would be hidden (z-index does not have any affect)
            if (this.$node.parents(this.attr._fixedLayerSelector).length != 0) {
                $autosuggest.find(this.attr._inputSelector).css("padding-left", "5px");
            }

            this.overlayIcons($autosuggest);
        };

        this.after("initialize", function () {
            this.on("body", this.attr.EV_SEARCH_DATA_AFTER_UPDATE, this.afterUpdate);
        });
    }
});
