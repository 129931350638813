var mixin = function () {
    this.attributes({
        /**
         * @event ui:autoSuggest:switch
         *  @param {number} tabIndex - the number of the autosuggest the event was triggered on
         *  @param {boolean} reverse - whether or not the shift key was being held down while the key was pressed
         */
        EV_AUTOSUGGEST_SWITCH: "ui:autoSuggest:switch",

        lastFieldNumber: 2,

        tabKeyCode: 9,

        enterKeyCode: 13
    });

    this.onAutosuggestSwitch = function (ev, evData) {
        var tabTo;
        if (evData.reverse) {
            tabTo = evData.tabIndex - 1;
        } else {
            tabTo = evData.tabIndex + 1;
        }
        tabTo = this.$node.find("[data-tab-index="+(tabTo)+"]");
        if (tabTo.length) {
            // Opened https://jira.smartertravelmedia.com/browse/AWD-2712 to fix this eventually
            window.setTimeout(function() {
                tabTo.first().focus();
            }, 10);
        }
    };

    this.after("initialize", function () {
        this.on(this.attr.EV_AUTOSUGGEST_SWITCH, this.onAutosuggestSwitch);
    });
};

module.exports = mixin;