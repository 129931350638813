/**
 * Base component to predict user-movements in browser
 * Main focus of this component is to trigger an event if user tries to close the browser / tab
 */
var $j = require("jquery");
var utils = require("flight/lib/utils");

var PredictUserExit = function () {
    var mY = $j(window).height()/2;

    this.attributes({
        userMovementDirection: "",

        scrollThrottleMs: 100,
        /**
         * @event ui:start:user:tracking => event to start tracking user movement
         */
        EV_START_USER_TRACKING: "ui:start:user:tracking",

        /**
         * @event ui:stop:user:tracking => event to stop tracking user movement
         */
        EV_STOP_USER_TRACKING: "ui:stop:user:tracking",

        /**
         * @event ui:subscription:modal:exit:offer => event to show subscription modal as an exit-offer when user tries to leave
         */
        EV_SHOW_EXIT_OFFER_SUB_MODAL: "ui:subscription:modal:exit:offer"
    });

    this.trackUserMovement = function (e) {
        if (e.pageY < mY) {
            mY = e.pageY;
            this.attr.userMovementDirection = "UP";
            //console.log(this.attr.userMovementDirection + "  ::  " + mY);

        } else {
            mY = e.pageY;
            this.attr.userMovementDirection = "DOWN";
            //console.log(this.attr.userMovementDirection + "  ::  " + mY);
        }
    };
    
    this.trackUserFocus = function (e) {

        if(this.attr.userMovementDirection === "UP") {
            this.trigger(this.attr.EV_SHOW_EXIT_OFFER_SUB_MODAL);
        }

    };

    this.startUserMovementTracking = function () {
        this.on($j(window), "mousemove", utils.throttle(this.trackUserMovement, this.attr.scrollThrottleMs));
        this.on($j("body"), "mouseleave", this.trackUserFocus);
    };

    this.stopUserMovementTracking = function () {
        this.off($j(window), "mousemove");
        this.off($j("body"), "mouseleave");
    };

    this.after("initialize", function () {
        this.on(this.attr.EV_START_USER_TRACKING, this.startUserMovementTracking);
        this.on(this.attr.EV_STOP_USER_TRACKING, this.stopUserMovementTracking);
    });
};

module.exports = PredictUserExit;