/**
 * The search form ui component acts as an adapter between an air search form and the "flight-search-data". It will
 * listen for incoming search data changes, convert them, and propagate them to underlying search form components.
 *
 *
 *
 * This component provides default selectors for the underlying fields and components. This is required so that events can
 * be propagated down. They can all be overridden at construction time.
 *
 * This component expects the date imputs to have a data attribute date with the format of "YYYY-MM-DD" that is kept up
 * to date.
 *
 *
 * Listens To:
 * =========
 *
 * @event data:searchData:changed => the search data in this component has been updated
 *  @param {Object} searchData - the full data set currently contained in the component
 *      @param {String} searchType 'hotel' or 'air'
 *      @param {Number} location1CityId the taid of the city
 *      @param {Number} location1AirportId the taid of the airport
 *      @param {String} location1Name the name of the airport to be displayed in the autosuggest
 *      @param {String} location1CityDisplayName the city full display name
 *      @param {Object} location1AirportCode the airport code
 *          @param {String} code 3 letter IOTA code
 *      @param {Number} location2CityId the taid of the city
 *      @param {Number} location2AirportId the taid of the airport
 *      @param {String} location2Name the name of the airport to be displayed in the autosuggest
 *      @param {String} location2CityDisplayName the city full display name
 *      @param {Object} location2AirportCode the airport code
 *          @param {String} code 3 letter IOTA code
 *      @param {Object} searchDate1 the first search date
 *          @param {String} date YYYY-MM-DD
 *          @param {String} time
 *      @param {Object} searchDate2 the second search date
 *          @param {String} date YYYY-MM-DD
 *          @param {String}
 *      @param {Number} travelers
 *      @param {Boolean} oneWay
 * This event is received when there is an external change to the search data. If the received search data
 * is not a search type of 'air' this search form will only update the dates as it will not have enough information
 * properly update the autosuggest.
 *
 * @event ui:calendar:dates:changed => The departure/checkin date has changed.
 *  @param {Object} data - the new dates for the calendar to display format is YYYY-MM-DD.
 *      @param {String} date1
 *      @param {String} date2
 *
 * @event ui:autoSuggest:selected
 *  @param {Object} params - query params sent for the request
 *  @param {Object} dataValue - data-value parameters
 *      @param {String} dataValue.id
 *      @param {NULL | Number} dataValue.parentId
 *  @param {String} displayName - display name for the list item
 * Note that while we receive all the selected information from the autosuggest because it cannot be determined which
 * autosuggest triggered this event this event is only used to watch for changes that are then pulled out of the form.
 *
 * @event ui:radiobutton:changed  a radio button on the form changed.
 *
 * @event ui:selectbox:changed a select box on the form changed.
 *
 * @event ui:autoSuggest:switch
 *  @param {number} keyCode - the keycode of the key pressed to switch autosuggests
 *  @param {number} fieldNumber - 1 or 2, the number of the autosuggest the event was triggered on
 *  @param {boolean} shiftKey - whether or not the shift key was being held down while the key was pressed
 *
 *
 * TRIGGERS
 * ==========
 * @event ui:searchData:changed => the search data in this component has been changed
 *  @param {Object} searchData - the full data set currently contained in the component
 *      @param {String} searchType 'hotel' or 'air'
 *      @param {Number} location1CityId the taid of the city
 *      @param {Number} location1AirportId the taid of the airport
 *      @param {String} location1Name the name of the airport to be displayed in the autosuggest
 *      @param {String} location1CityDisplayName the city full display name
 *      @param {Object} location1AirportCode the airport code
 *          @param {String} code 3 letter IOTA code
 *      @param {Number} location2CityId the taid of the city
 *      @param {Number} location2AirportId the taid of the airport
 *      @param {String} location2Name the name of the airport to be displayed in the autosuggest
 *      @param {String} location2CityDisplayName the city full display name
 *      @param {Object} location2AirportCode the airport code
 *          @param {String} code 3 letter IOTA code
 *      @param {Object} searchDate1 the first search date
 *          @param {String} date YYYY-MM-DD
 *          @param {String} time
 *      @param {Object} searchDate2 the second search date
 *          @param {String} date YYYY-MM-DD
 *          @param {String}
 *      @param {Number} travelers
 *      @param {Boolean} oneWay
 * This event is triggered when there is a change detected on this components node. This is not garenteed to send all of
 * listed params with the event. It will send the data is currently has available.
 *
 * @event data:searchForm:calendar:date1:changed => The departure/checkin date has changed.
 *  @param {String} date - the new date for the calendar to display format is YYYY-MM-DD.
 *
 *
 * @event data:searchForm:calendar:date2:changed => The return/checkout date has changed.
 *  @param {String} date - the new date for the calendar to display format is YYYY-MM-DD.
 *
 * @event ui:searchForm:radio:changed => The radio button has been changed externally.
 *  @param {Object} data
 *      @param {String} name name of radio group
 *      @param {String} value value to select
 *
 * @event ui:searchForm:select:changed => The select box has been changed externally.
 *  @param {Object} data
 *      @param {String} name name of the select box
 *      @param {String} value value to select
 *
 */
var defineComponent = require("flight/lib/component");
var withTabHandler = require("src/desktop/modules/flight-search-form/lib/mixins/with-tab-handler.js");


var component = defineComponent(airSearchForm).mixin(withTabHandler);

function airSearchForm () {
    this.attributes({

        /**
         * @event ui:select:changed a select box on the form changed.
         */
        EV_SELECTBOX_CHANGED: "ui:select:changed",

        /**
         * @event ui:searchForm:select:changed => The select box has been changed externally.
         *  @param {Object} data
         *      @param {String} name name of the select box
         *      @param {String} value value to select
         */
        EV_EXTERNAL_SELECTBOX_CHANGED: "ui:searchForm:select:changed",

        /**
         * @event ui:searchForm:radio:changed => The radio button has been changed externally.
         *  @param {Object} data
         *      @param {String} name name of radio group
         *      @param {String} value value to select
         */
        EV_EXTERNAL_RADIO_CHANGED: "ui:searchForm:radio:changed",

        /**
         * @event ui:radio:changed  a radio button on the form changed.
         */
        EV_RADIOBUTTON_CHANGED: "ui:radio:changed",

        /**
         * @event ui:calendar:dates:changed => The departure/checkin date has changed.
         *  @param {Object} data - the new dates for the calendar to display format is YYYY-MM-DD.
         *      @param {String} date1
         *      @param {String} date2
         */
        EV_DATES_CHANGED: "ui:calendar:dates:changed",

        /**
         *  @event ui:autoSuggest:selected
         *  @param {Object} params - query params sent for the request
         *  @param {Object} dataValue - data-value parameters
         *      @param {String} dataValue.id
         *      @param {NULL | Number} dataValue.parentId
         *  @param {String} displayName - display name for the list item
         * Note that while we receive all the selected information from the autosuggest because it cannot be determined which
         * autosuggest triggered this event this event is only used to watch for changes that are then pulled out of the form.
         */
        EV_AUTOSUGGEST_SELECTED: "ui:autoSuggest:selected",

        /**
         * * @event data:searchData:changed => the search data in this component has been updated
         *  @param {Object} searchData - the full data set currently contained in the component
         *      @param {String} searchType 'hotel' or 'air'
         *      @param {Number} location1TaId the taid of the airport
         *      @param {String} location1CityName the name of the city to be displayed in the autosuggest
         *      @param {Number} location1CityId the city id
         *      @param {String} location1AirportCode the airport code
         *      @param {Number} location2TaId the taid of the airport
         *      @param {String} location2CityName the name of the city to be displayed in the autosuggest
         *      @param {Number} location2CityId the city id
         *      @param {String} location2AirportCode the airport code
         *      @param {Object} searchDate1 the first search date
         *          @param {String} date YYYY-MM-DD
         *          @param {String} time
         *      @param {Object} searchDate2 the second search date
         *          @param {String} date YYYY-MM-DD
         *          @param {String}
         *      @param {Number} travelers
         *      @param {Boolean} oneWay
         * This event is received when there is an external change to the search data. If the received search data
         * is not a search type of 'air' this search form will only update the dates as it will not have enough information
         * properly update the autosuggest.
         */
        EV_EXTERNAL_SEARCH_DATA_CHANGED: "data:searchData:changed",

        /**
         * @event ui:searchData:changed => the search data in this component has been changed
         *  @param {Object} searchData - the full data set currently contained in the component
         *      @param {String} searchType 'hotel' or 'air'
         *      @param {Number} location1TaId the taid of the airport
         *      @param {String} location1CityName the name of the city to be displayed in the autosuggest
         *      @param {Number} location1CityId the city id
         *      @param {String} location1AirportCode the airport code
         *      @param {Number} location2TaId the taid of the airport
         *      @param {String} location2CityName the name of the city to be displayed in the autosuggest
         *      @param {Number} location2CityId the city id
         *      @param {String} location2AirportCode the airport code
         *      @param {Object} searchDate1 the first search date
         *          @param {String} date YYYY-MM-DD
         *          @param {String} time
         *      @param {Object} searchDate2 the second search date
         *          @param {String} date YYYY-MM-DD
         *          @param {String}
         *      @param {Number} travelers
         *      @param {string} flightType
         * This event is triggered when there is a change detected on this components node. This is not garenteed to send all of
         * listed params with the event. It will send the data is currently has available.
         */
        EV_SEARCH_DATA_CHANGED: "ui:searchData:changed",

        /**
         * @event ui:searchData:afterUpdate => The form has finished updating
         *   @param {Number} id - the id of the component that triggered the update. Can be undefined
         */
        EV_SEARCH_DATA_AFTER_UPDATE: "ui:searchData:afterUpdate",

        /**
         *
         * SELECTORS
         *
         */

        /**
         * Selector for locating location 1's auto suggest
         */
        _location1Selector: "input[name='originId']",

        /**
         * Selector for locating location 2's auto suggest
         */
        _location2Selector: "input[name='destinationId']"
    });

    /**
     * Loads the search data currently imputed in the form
     * @return {Object}
     *  @return {Object} location1
     *      @return {String} name
     *      @return {Number} id
     *      @return {Object} airportCode
     *          @return {String} code 3 letter airport code
     *  @return {Object} location2
     *      @return {String} name
     *      @return {Number} id
     *      @return {Object} airportCode
     *          @return {String} code 3 letter airport code
     *  @return {String} date1 YYYY-MM-DD
     *  @return {String} date2 YYYY-MM-DD
     *  @return {String} travelers 1,2,3+
     *  @return {String} flightType oneway, roundtrip
     *
     */
    this.getFormData = function () {
        var formData = {},
            location1 = {},
            location2 = {};
        //Locations
        var location1Input = this.select("_location1Selector");

        if (location1Input.length) {
            location1 = location1Input.data("value") || {};
            location1.name = location1Input.val();
        }


        var location2Input = this.select("_location2Selector");

        if (location1Input.length) {
            location2 = location2Input.data("value") || {};
            location2.name = location2Input.val();
        }

        formData.location1 = location1;
        formData.location2 = location2;

        return formData;
    };

    /**
     * Handles search data being transmitted when a search box changes
     */
    this.onAutosuggestChange = function (ev, data) {
        var input1 = this.select("_location1Selector"),
            input2 = this.select("_location2Selector"),
            location1Data = input1.length ? input1.data("value") : null,
            location2Data = input2.length ? input2.data("value") : null,
            searchData = {
                searchType: "air",
                id: data.id
            };

        if (location1Data) {
            searchData.location1Name = input1.val();
            searchData.location1CityId = location1Data.parentId;
            searchData.location1AirportCode = location1Data.airportCode.hasOwnProperty("code") ? location1Data.airportCode.code : location1Data.airportCode;
            searchData.location1AirportId = location1Data.id;
            searchData.location1CityDisplayName = location1Data.parentDisplayName;
        }
        if (location2Data) {
            searchData.location2Name = input2.val();
            searchData.location2CityId = location2Data.parentId;
            searchData.location2AirportCode = location2Data.airportCode.hasOwnProperty("code") ? location2Data.airportCode.code : location2Data.airportCode;
            searchData.location2AirportId = location2Data.id;
            searchData.location2CityDisplayName = location2Data.parentDisplayName;
        }

        this.triggerSearchDataChange(searchData);
    };

    this.triggerSearchDataChange = function (searchData) {
        var self = this;
        setTimeout(function (){
            searchData.searchType = "air";
            self.trigger(self.attr.EV_SEARCH_DATA_CHANGED, searchData);
        }, 0);

    };


    /**
     * The event handler when external search data is detected by the search forms. causes the form to silently update
     * all of its fields.
     */
    this.externalSearchDataChanged = function (event, searchData) {
        var newValue;
        if (!searchData) {
            return;
        }

        var location1Input = this.select("_location1Selector");
        var location2Input = this.select("_location2Selector");

        //Bail out if search data isn't an air search
        if (searchData.searchType !== "air") {
            return;
        }

        if (searchData.location1AirportId && location1Input.length) {
            newValue = {
                id: searchData.location1AirportId,
                airportCode: searchData.location1AirportCode.hasOwnProperty("code") ? searchData.location1AirportCode.code : searchData.location1AirportCode,
                parentId: searchData.location1CityId,
                parentDisplayName: searchData.location1CityDisplayName
            };

            location1Input.data("value", newValue);
            location1Input.val(searchData.location1Name);
        }

        if (searchData.location2AirportId && location2Input.length) {
            newValue = {
                id: searchData.location2AirportId,
                parentId: searchData.location2CityId,
                airportCode: searchData.location2AirportCode.hasOwnProperty("code") ? searchData.location2AirportCode.code : searchData.location2AirportCode,
                parentDisplayName: searchData.location2CityDisplayName
            };

            location2Input.data("value", newValue);
            location2Input.val(searchData.location2Name);
        }

        this.trigger(this.attr.EV_SEARCH_DATA_AFTER_UPDATE, { id: searchData.id });
    };


    this.after("initialize", function () {
        this.on(this.attr.EV_AUTOSUGGEST_SELECTED, this.onAutosuggestChange);
        this.on("body", this.attr.EV_EXTERNAL_SEARCH_DATA_CHANGED, this.externalSearchDataChanged);
    });
}

module.exports = component;