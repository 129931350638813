/**
 * Methods for reading and setting a cookies
 */
define(function (require){
    "use strict";

    return WithCookieSupport;

    function WithCookieSupport() {

    /**
     * This method sets a cookie with the specified name and value. This finds the
     * host by looking at the window.location.hostname. The path will always be '/'
     * @param {String} cookieName the name of the cookie
     * @param {String} cookieValue the value of the cookie
     * @param {Number|null} expireInDays the number of days to expire or null for session
     */
    this.setCookie = function (cookieName, cookieValue, expireInDays) {
        var expireDate = new Date(),
            reg = new RegExp(/(\w*\.co(m|\.uk))/),
            host = window.location.hostname,
            matches = reg.exec(host),
            defaultDomain = matches ? matches[0] : host,
            result = cookieName + "=" + cookieValue + ";path=/";

        expireDate.setTime(expireDate.getTime()+(expireInDays*24*60*60*1000));

        if (expireInDays) {
            result += ";expires=" + expireDate.toGMTString();
        }

        if (defaultDomain != "localhost") {
            result += ";domain=" + defaultDomain + ";secure=true";
        }

        document.cookie = result;
    };

    /**
     * Loads a cookie by name.
     * @param cookieName the name of the cookie to find.
     * @return {Object|null} the cookie if it can be found, null otherwise.
     */
    this.getCookie = function(cookieName) {
        var name = cookieName + "=",
            cookieItems = document.cookie.split(';'),
            currentCookie = null;

        for(var i = 0; i < cookieItems.length; i++) {
            currentCookie = cookieItems[i].trim();
            if (currentCookie.indexOf(name) === 0) {
                return currentCookie.substring(name.length,currentCookie.length);
            }
        }

        return null;
    };
  }
} );
