/**
 * A mixin for toggling a modal in a UI component.
 *
 * Following attributes need to be set on any component loading this mixin
 *    modalSelector:         '.modal',
 *    modalHeaderSelector:   '.modal-header',
 *    modalVisibleSelector:  '.modal-visible',
 *    modalHiddenSelector:   '.modal-hidden'
 */
define(function (require){
  "use strict";

  return WithModal;

  function WithModal() {

    this.defaultAttrs({
      //Used to disable the functionality for that moves the window
      // back to its original position
      disablePrevScroll: false,

      // used to keep track of the users scroll position so that
      // we can return the user to that position after
      // they're done with the modal
      prevScrollPosition: 0,

      // amount of time to wait before returning the user to their previous scroll position
      // NOTE: this value should be coordinated with css animation transtion values
      // (return to scroll position *after* the modal has moved out of view)
      returnToPrevScrollPosTimeout: 500,

      // amount of time to wait before removing the modal
      // NOTE: this value should be coordinated with css animation transtion values
      // (modal should be removed *after* being moved out of view)
      modalRemovalTimeout: 1000,
      modalHeaderFadeSpeed: 300
    });

    this.showModal = function () {
      var $modal       = $(this.attr.modalSelector),
        $modalHeader = $(this.attr.modalHeaderSelector);

      this.attr.prevScrollPosition = $('body').scrollTop();

      $modal
        .show()
        .removeClass(this.attr.modalHiddenSelector.substr(1))
        .addClass(this.attr.modalVisibleSelector.substr(1));

      $modalHeader.fadeIn();
    };

    this.hideModal = function () {
      var self         = this,
        $modal       = $(this.attr.modalSelector),
        $modalHeader = $(this.attr.modalHeaderSelector);

      $modal.removeClass(this.attr.modalVisibleSelector.substr(1))
        .addClass(this.attr.modalHiddenSelector.substr(1));

      if (!this.attr.disablePrevScroll) {
        // return the user the previous scrolling point
        setTimeout(function() {

          $('html,body').animate({
            scrollTop: self.attr.prevScrollPosition
          }, 0);

        }, this.attr.returnToPrevScrollPosTimeout);

      }

      $modalHeader.fadeOut(this.attr.modalHeaderFadeSpeed, function () {
        $modalHeader.remove();
      });

      // remove the component after a delay to give enough time for the css transition
      setTimeout(function() {
        $modal.remove();
      }, this.attr.modalRemovalTimeout);
    };
  }
});
