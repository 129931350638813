/**
 * Base class for closeable components
 */
var $j = require("jquery");

var Closeable = function () {
    this.attributes({
        _closeActionSelector: ".do_close",
        _closeContainerSelector: ".closeable",
        /**
         * @event ui:event:element:closed => event to notify closing/hidding element
         */
        EV_ELEMENT_CLOSED: "ui:event:element:closed"

    });

    this.doClose = function(event) {
        event.stopPropagation();
        $j(event.target).parents(this.attr._closeContainerSelector).addClass("hidden");
        // Trigger an event for interested candidates!!
        this.trigger(this.attr.EV_ELEMENT_CLOSED);
    };

    this.after("initialize", function () {
        this.on(this.select("_closeActionSelector"), "click", this.doClose);
    });
};

module.exports = Closeable;