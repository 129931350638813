var defineComponent = require("flight/lib/component");
var withDataCollection = require("src/desktop/modules/data-services/mixins/with-data-collection");

var component = defineComponent(poller).mixin(withDataCollection);

function poller() {
    this.attributes({
        waitIntervalMs: 500,
        retryLimit: 10
    });

    /**
     * Attempt to attach a listener for social media shares up to {@count} times
     *
     * @param {Number} retries
     * @private
     */
    this.pollAddThis = function (retries) {
        if (window.addthis) {
            var self = this;
            window.addthis.addEventListener("addthis.menu.share", function (evt) {
                try {
                    self.trackPageActivity({
                        type: "social_share",
                        value: evt.data ? evt.data.service : "unknown"
                    }); 
                } catch (e) {
                    // so this never breaks the page
                }
            });
        } else {
            if (retries > 0) {
                var self2 = this;
                window.setTimeout(function () {
                    self2.pollAddThis(retries - 1);
                }, this.attr.waitIntervalMs);
            }
        }
    };

    this.after("initialize", function () {
        this.pollAddThis(this.attr.retryLimit);
    });
}

module.exports = component;