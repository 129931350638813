module.exports = {
    subTypeRoute: "city_to_city",

    subTypeDeparture: "departure",

    subTypeArrival: "arrival",

    subTypeHwdUpdates: "hotelwatchdog",

    subTypeAwdUpdates: "airfarewatchdog",

    subTypeAirfareInsider: "airfare_insider",

    subTypeTripWatch: "tripwatch",

    subTypeWeekender: "weekender",

    subTypeSpecialOffer: "awd_special_offer",

    subTypeHwdCity: "hwd_city",

    subTypeJsGeneral: "js_general",

    subTypeJsInspiration: "js_inspiration",

    subTypeJsDeals: "js_deals",

    subTypeStUpdates: "st_updates",

    subTypeStTripReminder: "st_trip_reminder",

    subTypeStDealAlert: "st_deal_alert",

    subTypeStSpotlight: "st_spotlight",

    subTypeFvcInsider: "fvc_insider",

    subTypeOysterGeneral: "oys_general"
};