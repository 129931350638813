var _ = require("underscore");
var stm = require("stm");

var define = stm.define;
var Shared = stm.shared;

var ViewPortModel = require("src/desktop/modules/visibility/model/viewPort");

// ---------------------------------------------------------------------------------------------- //
/**
 * This controller listens for changes in the window view port and triggers and event in the model.
 */
var WindowChangeController = define(null, Shared.getBackboneEvents(), {
    /**
     * @param {jQuery}
     * @param {ViewPortModel} model
     * @constructor
     */
    initialize: function($el, model, options) {
        this.$el = $el;
        this.model = model;
        this.options = _.extend({}, this.options(), options);
    }

    /* ----------
     Properties
     ---------- */

    /**
     * @type {Jquery}
     */
    , $el: null

    /**
     * @type {ChangeEventBus}
     */
    , model: null

    /**
     * @type {Object}
     */
    , options: function () {
        return {};
    }

    /* --------------
     Public Methods
     -------------- */

    /**
     * Start executing.
     *
     * @return {this}
     */
    , start: function() {
        this.$el.on("DOMContentLoaded load resize scroll", _.bind(this.onViewPortChange, this));
    }

    /**
     * Stop executing.
     *
     * @return {this}
     */
    , stop: function() {
        this.$el.off("DOMContentLoaded load resize scroll", _.bind(this.onViewPortChange, this));
    }

    , onViewPortChange: function () {
        this.model.trigger(ViewPortModel.VIEWPORT_CHANGED);
    }

    /* --------------
     Event Handlers
     -------------- */

    , STATIC: {
        /* ------------------
         Static - Constants
         ------------------ */

        /* ---------------
         Static - Events
         --------------- */

        /* ----------------
         Static - Methods
         ---------------- */

    }
});

// ---------------------------------------------------------------------------------------------- //

module.exports = WindowChangeController;