/**
* This data component that represents an awd search data model. It contains overrides
* to 'lib/base/search-data.js' to support the awd specific search data fileds.
*/
define(function (require) {

 'use strict';

 /**
  * Module dependencies
  */

 var baseSearchData = require('../base/search-data');
 var withDates = require('../mixin/with-dates');
 var withLocations = require('../mixin/with-locations');

 /**
  * Module exports
  */

 return baseSearchData.mixin(awdSearchData, withDates, withLocations);

 /**
  * Module function
  */

 function awdSearchData() {
   this.defaultAttrs({

     /**
      * The provider...
      * @type {String}
      */
     provider: '',

     /**
      * The number of flex days for a flex search
      * @type {Number} 3|30
      */
     flexDays: 3,

     /**
      * The number of travelers
      * @type {Number}
      */
     travelers: 2,

     /**
      * The number of rooms
      * @type {String} 1,2,3+
      */
     rooms: '1',

     /**
      * Is this a non stop search
      * @type {Boolean}
      */
     nonStop: false,

     /**
      * The class of the flight
      * @type {String} one of economy_coach, business, first_class
      */
     serviceClass: 'economy_coach',

     /**
      * The Search type for the flight
      * @type {String}
      */
     flightSearchType: 'roundtrip'

   });

   /**
    * Returns the array of the all of the search keys that the awd specific search model
    * uses and should transmit.
    * @return {Array}
    */
   this.getAwdSpecificSearchKeys = function() {
     return [
       'serviceClass',
       'nonStop',
       'travelers',
       'flexDays',
       'provider',
       'flightSearchType'
     ];
   };


   /**
    * Merges base params with awd specific params
    * @override
    */
   this.getSearchDataKeys = function () {
     return this.getBaseSearchDataKeys()
     .concat(this.getAwdSpecificSearchKeys())
     .concat(this.getSearchDateKeys())
     .concat(this.getLocationKeys());
   };
 }
});
