module.exports = function () {
    this.attributes({
        _errorSelectors: {
            login: "div.error.error-login",

            permission: "div.error.error-permission"
        },

        _loginSelector: "div.facebook-login",

        errorClass: "error"
    });

    this.onError = function (data) {
        if (!data.type) {
            return;
        }
        for (var key in this.attr._errorSelectors) {
            this.$node.find(this.attr._errorSelectors[key]).hide();
        }
        if (this.attr._errorSelectors[data.type]) {
            this.$node.find(this.attr._errorSelectors[data.type]).show();
        }
    };
};