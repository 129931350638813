/**
 * Ui component to track page load times either specific marks or through page reloads.
 *
 * Triggers:
 * =========
 * data:metrics:track:performance
 *  - {String} pageName: what page are we tracking on
 *
 * data:metrics:set:mark
 *  - {String} pageName: what page are we tracking on
 *  - {String} timingKey: what attribute of the site are we logging
 *
 * data:metrics:track:mark
 *  - {String} pageName: what page are we tracking on
 *  - {String} timingKey: what attribute of the site are we logging
 *
 */
var defineComponent = require("flight/lib/component");
var component = defineComponent(TrackMetricsPageLoad);

function TrackMetricsPageLoad() {

    this.attributes({
        /**
         * What is the current Page we are tracking on.  This will be loaded from a data attribute on the
         * attached node
         *
         * @var {String}
         */
        pageName: "",

        /**
         * @event data:metrics:track:performance
         *  @param pageName {String} what page is the tracking for
         */
        EV_METRICS_TRACK_PERFORMANCE: "data:metrics:track:performance",

        /**
         * @event data:metrics:set:mark
         *  @param pageName {String} what page is the tracking for
         *  @param timingKey {String} what attribute of the site are we logging
         */
        EV_METRICS_SET_MARK: "data:metrics:set:mark",

        /**
         * @event data:metrics:track:mark
         *  @param pageName {String} what page is the tracking for
         *  @param timingKey {String} what attribute of the site are we logging
         */
        EV_METRICS_TRACK_MARK: "data:metrics:track:mark"
    });

    /**
     * Track the standard performance data to the metrics service.  The metricsApi limits the number of times
     * this can be called to once per page load.  Thus multiple calls will do nothing.
     */
    this.trackPerformance = function () {
        var data = {
            pageName: this.attr.pageName
        };
        this.trigger(this.attr.EV_METRICS_TRACK_PERFORMANCE, data);
    };

    /**
     * Tell the Metrics service to set a start time for a particular key.
     *
     * @param key {string} timing key we want to track
     */
    this.setMark = function (key) {
        var data = {
            pageName: this.attr.pageName,
            timingKey: key
        };
        this.trigger(this.attr.EV_METRICS_SET_MARK, data);
    };

    /**
     * Track a mark to the metrics service.  If mark doesn't currently exist in the marks collection then the
     * request will be ignored.  Typically you would set a mark, to start the timer, then track the mark
     * to record the elapsed time to the metrics service.
     *
     * @param key {string} timing key we want to track
     */
    this.trackMark = function (key) {
        var data = {
            pageName: this.attr.pageName,
            timingKey: key
        };
        this.trigger(this.attr.EV_METRICS_TRACK_MARK, data);
    };

    /**
     * Load all required data from the node elements data attributes
     */
    this.loadData = function () {
        // load the page name from the dom
        var data = this.$node.data();
        if (data.metricsPageName) {
            this.attr.pageName = data.metricsPageName;
        } else {
            throw "metrics-page-name attribute is required, did you forgot to set it?";
        }
    };

    this.after("initialize", function () {
        this.loadData();

        // setup the performance tracking to trigger on page load so we'll have the most complete
        // timing information.
        this.on(window, "load", this.trackPerformance);
    });
}

module.exports = component;