
var mixin = function () {
    this.attributes({
        _subWidgetSelector: ".do_sub_widget",
        _subExistingRoutes: "routes",
        _subExistingDepartures: "departures",
        _subExistingHotels: "hotels",
    });

    /**
     * Populating maps of destinations and routes that the user is already subscribed for.
     */
    this.setup = function () {
        var widget = this.select("_subWidgetSelector");
        var routes = widget.data(this.attr._subExistingRoutes) || [];
        var departures = widget.data(this.attr._subExistingDepartures) || [];
        var hotels = widget.data(this.attr._subExistingHotels) || [];
        this.attr.existingRoutes = {};
        for (var j in routes) {
            var route = routes[j];
            this.attr.existingRoutes[route] = true;
        }

        this.attr.existingDepartures = {};
        for (var i in departures) {
            var departure = departures[i];
            this.attr.existingDepartures[departure.code] = true;
        }

        this.attr.existingHotels = {};
        for (var index in hotels) {
            this.attr.existingHotels[hotels[index]] = true;
        }
    };

    this.after("initialize", function () {
    });
};

module.exports = mixin;