var stm = require("stm");
var $j = require("jquery");
var define = stm.define;

/**
 * Simple cookie get and set functionality. At some point this will need to be extended further.
 */
var CookieUtils = define({
    STATIC: {
        /* ----------------
         Static - Methods
         ---------------- */

        /**
         * Set the value of a cookie.
         * @param cookieName
         * @param cookieValue
         * @param expireInDays
         */
        setCookie: function (cookieName, cookieValue, expireInDays) {
            var expireDate = new Date(),
                reg = new RegExp(/(\w*\.co(m|\.uk))/),
                host = window.location.hostname,
                matches = reg.exec(host),
                defaultDomain = matches ? matches[0] : host,
                result = cookieName + "=" + cookieValue + ";path=/";

            expireDate.setTime(expireDate.getTime()+(expireInDays*24*60*60*1000));

            if (expireInDays) {
                result += ";expires=" + expireDate.toGMTString();
            }

            if (defaultDomain != "localhost") {
                result += ";domain=" + defaultDomain;
            }

            document.cookie = result;
        }

        /**
         * Returns the value of the cookie or null
         * @param cookieName name of the cookie to lookup
         * @returns {*}
         */
        , getCookie: function(cookieName) {
            var name = cookieName + "=",
                cookieItems = document.cookie.split(";"),
                currentCookie = null;

            for(var i = 0; i < cookieItems.length; i++) {
                currentCookie = $j.trim(cookieItems[i]);
                if (currentCookie.indexOf(name) === 0) {
                    return currentCookie.substring(name.length,currentCookie.length);
                }
            }
            return null;
        }
    }
});

module.exports = CookieUtils;