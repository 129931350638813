var Cookies = require("src/desktop/utils/cookies");

var mixin = function () {
    this.attributes({
        stSite: "st",
        stUserSubscribedCookie: "stUserSubscribed",
        EV_USER_SUBSCRIBED_SUCCESS: "ui:user:subscribed:success",
        ST_USER_SUBSCRIBED_COOKIE_TTL_DAYS: 365
    });

    this.dropStUserSubscribedCookie = function() {
        Cookies.setCookie(this.attr.stUserSubscribedCookie, "true", this.attr.ST_USER_SUBSCRIBED_COOKIE_TTL_DAYS);
    };

    this.after("initialize", function () {
        var self = this;
        this.on(this.attr.EV_USER_SUBSCRIBED_SUCCESS, function(ev, data) {
            if (data.siteForSubRequest === this.attr.stSite) {
                self.dropStUserSubscribedCookie();
            }
        });
    });
};

module.exports = mixin;