/**
 * Data component that on request connects to the backend to get a suggestion based on
 * the params given
 *
 * Triggers:
 * =========
 *  `data:autoSuggest:response`  => when results are returned
 *     - {String} autoSuggestId: component id
 *     - {Object} params: request params used to get these items
 *     - {Array<Objects>} items:
 *     - {Number} item.id: location id to be used on `data-id`
 *     - {String} item.displayName: display name for the list item
 *
 * Listens To:
 * ===========
 *  `data:autoSuggest:request`  => suggest request
 *     - {Object} params: request params used to get suggested items
 *     - {String} searchTypePath: the aditional path information required for a specific search type call.
 *     - {String} autoSuggestId: component id
 *
 */
define([
    'flight/lib/component',
    'flight-common-mixins/lib/with-selector-utils',
], function(defineComponent, withSelectorUtils) {
    'use strict';

    /**
     * Module exports
     */
    return defineComponent(AutoSuggest)
        .mixin(withSelectorUtils);

    function AutoSuggest() {
        this.defaultAttrs({

            /**
             * @event data:autoSuggest:request
             *  @param {Object} params - request params used to get suggested items
             *  @param {String} autoSuggestId  - this component's id
             */
            EV_AUTOSUGGEST_REQUEST : 'data:autoSuggest:request',

            /**
             * @event data:autoSuggest:response
             * @param {String} autoSuggestId  - this component's id
             * @param {Object} params - query params sent for the request
             * @param {Array<Objects>} item
             *  @param {Number} item.id - location id to be used on `data-id`
             *  @param {String} item.displayName - display name for the list item
             *  @param {Number} item.parentId - Id for particular City
             *  @param {String} item.airportCode - Airport code for particular airport
             */
            EV_AUTOSUGGEST_RESPONSE : 'data:autoSuggest:response',

            _xhr: false,

            endPoint: null,

            // Holds a map of urls that are in transit
            isInTransit: {},

            cache: {}

        });

        /**
         * @param {Event} ev
         * @param {Object} request
         *      @param {String} request.autoSuggestId
         *      @param  {Object} request.params
         */
        this.onSuggestionRequest = function (ev, request) {
            var self          = this,
                url           = this.attr.endPoint + request.searchTypePath + '/?' + $.param(request.params),
                event         = ev;

            // return cached response
            if (this.attr.cache[url]) {

                this.triggerResponse({
                    autoSuggestId:  request.autoSuggestId,
                    params:         request.params,
                    items:          this.attr.cache[url].items
                });

                return;
            }

            if (!this.attr.isInTransit[url]) {
                this.attr.isInTransit[url] = true;

                $.get(url, function (items, textStatus, jqXhr) {
                    self.attr.isInTransit[url] = false;

                    if (items[0]) {
                        self.attr.cache[url] = {
                            items: items
                        };
                    }

                    self.triggerResponse({
                        autoSuggestId:  request.autoSuggestId,
                        params:         request.params,
                        items:          items
                    });
                });
            }

        };

        this.triggerResponse = function (response) {
            this.trigger(this.attr.EV_AUTOSUGGEST_RESPONSE, response);
        };

        this.after('initialize', function() {
            this.on(this.attr.EV_AUTOSUGGEST_REQUEST, this.onSuggestionRequest);
        });
    }

});
