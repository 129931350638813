/**
* After the user has entered `minLetters` in the contained input field
* Using `autoSuggestSelector` as the parent class builds an item list like so:
*
* wiil look for  `data-search-type="airports_international"`  in this.$node to set
* `this.attr.params.type`
*
* ```
* <div class="suggest"  data-search-type="airports"  data-search-type-path="airport"
*      >
*    <ul>
*        <li data-id="1">Boston, MA (BOS)</li>
*        <li data-id="2">Bost, Afghanistan (BST)</li>
*        <li data-id="3">Bossaso, Somailia (BSA)</li>
*        <li data-id="4">Sarajevo, Bosnia (SJJ)</li>
*    </ul>
* </div>
* ```
*
* Triggers:
* =========
*  `ui:autoSuggest:show`  =>  after auto suggest has been shown
*  `ui:autoSuggest:hide`  =>  after auto suggest has been hidden
*  `ui:autoSuggest:selected`  =>  when an item is selected. Send:
*     - {Object} params - request params used to get these items
*     - {Number} id - location id to be used on `data-id`
*     - {String} displayName - display name for the list item
*
*  `data:autoSuggest:request`  => Request event to pull in suggestions from a `data` component. Sends:
*     - {Object} params - request params used to get suggested items
*     - {String} searchTypePath - the aditional path information required for making the request @see this.attr._searchTypePathAttr
*     - {String} autoSuggestId  - component id
*
*
* Listens To:
* ===========
*  `data:autoSuggest:response` => should contain:
*     - {String} autoSuggestId  - id for this component
*     - {Object} params - query params sent for the request
*     - {Array<Objects>} items - should contain:
*     - {Number} id - location id to be used on `data-id`
*     - {String} displayName - display name for the list item*
*
*/
define([
    'require',
    'flight-common-mixins/lib/with-unique-id',
    'flight-common-mixins/lib/with-selector-utils',
    'flight-common-mixins/lib/with-modal'
  ], function(require, withUniqueId, withSelectorUtils, withModal) {
    'use strict';

    /**
    * Module dependencies
    */
    var defineComponent = require('flight/lib/component');

    /**
    * Module exports
    */
    return defineComponent(BaseAutoSuggest)
                .mixin(withUniqueId)
                .mixin(withSelectorUtils)
                .mixin(withModal);

    function BaseAutoSuggest() {

        this.attributes({

            /**
            * @event ui:autoSuggest:show
            * @param {jQuery} $node
            */
            EV_AUTOSUGGEST_SHOW : 'ui:autoSuggest:show',

            /**
            * @event ui:autoSuggest:hide
            * @param {jQuery} $node
            */
            EV_AUTOSUGGEST_HIDE : 'ui:autoSuggest:hide',

            /**
            * @event ui:autoSuggest:selected
            * @param {Object} params - query params sent for the request
            * @param {Object} dataValue - data-value parameters
            *      @param {String} dataValue.id
            *      @param {NULL | Number} dataValue.parentId
            * @param {String} displayName - display name for the list item
            */
            EV_AUTOSUGGEST_SELECTED : 'ui:autoSuggest:selected',

            /**
            * @event data:autoSuggest:request
            * @param {Object} params - query params sent for the request
            * @param {String} autoSuggestId  - this component's id
            */
            EV_AUTOSUGGEST_REQUEST : 'data:autoSuggest:request',

            /**
            * @event data:autoSuggest:response
            * @param {String} autoSuggestId  - this component's id
            * @param {Object} params - query params sent for the request
            * @param {Array<Objects>} item
            *  @param {Number} item.id - location id to be used on `data-id`
            *  @param {String} item.displayName - display name for the list item
            */
            EV_AUTOSUGGEST_RESPONSE : 'data:autoSuggest:response',

            // used to identify which component emmitted an event
            // this attribute should be set by a mixin or passed in
            _id: 0,

            _isOpen: false,

            minLetters: 3,

            // type to query from `airports_domestic|airports_international|airports_all`
            searchType: false,

            // endpoint to be attached to final location suggest query e.g:
            // data.autoSuggest has an enpoint of '/location' and `endpoint` == 'city'
            // final ajax request will be '/location/city?....'
            searchTypePath: '',

            // Delay time needed between focus on textField and showLocationSuggest-Modal animation
            focusDelayTime: 1100,

            modalSelector:         '.modal__location-suggest',
            modalHeaderSelector:   '.modal__header',
            modalVisibleSelector:  '.modal__visible',
            modalHiddenSelector:   '.modal__hidden',
            modalCloseSelector:    '.modal__close',

            inputContainerSelector:  '.modal__location-suggest-input-container',
            suggestionResultsSelector:  '.modal__location-suggest-results',

            inputSelector:        'input',
            listSelector:         'li',

            // Type of search
            // "airports_domestic|airports_international|airports_all"
            // Only required if this is an airport location suggest
            _searchTypeAttr: 'search-type',

            // which endpoint to add to the location suggest request e.g:
            // data.autoSuggest has an enpoint of '/location' and this.$node.data('endpoint') == 'city'
            // final ajax request will be '/location/city?....'
            _searchTypePathAttr: 'search-type-path',

            // Whether metro airports should be included in autosuggest results
            _includeMetroAirportsAttr: 'include-metro-airports',

            // Mutually exclusive fields (Eg: Same source (JFK) and destination (JFK) should not be allowed to enter)
            // Common Selector : (eg: airfare-locations / fare_search_locations)
            //    Used to fetch all mutually exclusive fields, common selector to identify the group
            //    Which is passed dynamically and required attribute
            pairedFieldsSelector:    '',

             // location1 and location2 are classes provided to identify mutually exclusive fields uniquely
             // Currently logic for mutually exclusion is limited to two different fields only.
             // It can be scalable to N-number of mutually exclusive fields
             //
             // 1) Field-1-selector -- location1
             // 2) Field-2-selector -- location2
            field1Selector: '.location1',
            field2Selector: '.location2',

            errorClass: 'error',

            duplicateErrorClass: 'error_duplicate'

        });




        /**
        * @param {Object} dataValue
        *      @param {Number} dataValue.id
        *      @param {NULL | Number} dataValue.parentId
        * @param {String} displayName
        */
        this.updateInput = function (dataValue, displayName) {
            this.select('inputSelector')
                .data('value', dataValue)
                .val(displayName)
                .trigger('change');
        };

        /**
        * @param {Event} ev
        */
        this.onItemSelect = function (ev) {
            var $target = $(ev.target);
            var dataValue = $target.data('value'),
                displayName = $target.data('value').displayName;

            this.updateInput(dataValue, displayName);

            this.trigger(this.attr.EV_AUTOSUGGEST_SELECTED, {
                dataValue:    dataValue,
                displayName:  displayName,
                id: this.attr._id
            });

            this.hideLocationSuggest();
        };

        /**
        * @param {Array<Object>} items
        *      @param {Number} item.id
        *      @param {String} item.displayName
        */
        this.buildItemListHtml = function (items) {
            var html = '';
            items.forEach(function(item) {
                html += '<li data-value="' + item.id + '" >' + item.displayName + '</li>';
            });

            return html;
        };


        /**
        * @param {Array<Object>} items
        */
        this.updateList = function (items) {
            if (!items[0]) return;

            var $suggestionResults = $(this.attr.suggestionResultsSelectorWithId);
            $suggestionResults.find('ul').html(this.buildItemListHtml(items));

            // Attaching Event only after item(s) is/are available on the page
            this.on($suggestionResults.find(this.attr.listSelector), 'click', this.onItemSelect);

        };

        /**
        * @param {Event} ev
        * @param {Object} resp
        *      @param {String} resp.autoSuggestId
        *      @param {Array<Object>} resp.items
        *      @param  {Object} resp.params
        */
        this.onSuggestionResponse = function (ev, resp) {
            if (resp.autoSuggestId != this.attr._id) return;
            $(this.attr.suggestionResultsSelectorWithId).show();

            var items = resp.items;
            this.updateList(items);
        };

        /**
        * @param {Event} ev
        *                  - {HtmlElement} el
        */
        this._onKeyUp = function (ev) {

            var $suggestionResults = $(this.attr.suggestionResultsSelectorWithId);
            var val = $(ev.currentTarget).val();
            if (val.length < this.attr.minLetters) {
                // Hide only list container
                $suggestionResults.hide();
                return;
            }

            var request = {
                params:{
                    query: val
                },
                searchTypePath: this.attr.searchTypePath,
                autoSuggestId:  this.attr._id
            };

            // Currently only used on air searches
            if (this.attr.searchType) {
                request.params.search_type = this.attr.searchType;
            }

            this.trigger(this.attr.EV_AUTOSUGGEST_REQUEST, request);
        };



        /**
        *  Update header text according to input field value / placeholder
        *  @return String
        */
        this.getHeaderText = function () {

            var text;
            var inputValue = this.select('inputSelector').val();

            if (inputValue === "") {
                // InputField doesn't have any value, so we will transfer placeholder only
                text = this.select('inputSelector').attr('placeholder');
            } else {
                // InputField has previously set Location, we will transfer it into LocationSuggest Modal
                text = inputValue ;
            }
            return text;
        };

        /**
        *  Builds Modal-Header with Text and Close button on it
        *   @return String
        */
        this.getModalHeaderHtml = function () {
            var header = this.getNameFromSelector(this.attr.modalHeaderSelector);
            return '<div class="' + header + '">' +
                '<div class="' + header + '-title">' + this.getHeaderText() + '</div>' +
                '<div class="icon-x ' + this.getNameFromSelector(this.attr.modalCloseSelector) + '"></div>' +
                '</div>';
        };

        /**
        * Builds the AutoSuggestion Container to accommodate List of Arrival and Departure Airports
        *  @return String
        */
        this.getSuggestionResultsHtml = function () {
            return '<div class="' + this.getNameFromSelector(this.attr.suggestionResultsSelector) +' ' + this.getNameFromSelector(this.attr.suggestionResultsSelectorWithId) +'">'
                +       '<ul></ul>'
                +  '</div>';
        };

        /**
        * Builds the LocationSuggest Input-Field and Input-Container
        *  @return String
        */
        this.getLocationSuggestInputHtml = function () {

            var html = '<div class="'+ this.getNameFromSelector(this.attr.inputContainerSelector)+'">';
            html    +=      '<input type="text" />';
            html    += '</div>';

            return html;
        };

        /**
        * Returns a html string version of the LocationSuggest
        * @return String
        */
        this.toHtml = function () {
            return  '<div class="'+this.getNameFromSelector(this.attr.modalSelector) +'">'
                + this.getLocationSuggestInputHtml()
                + this.getSuggestionResultsHtml()
                + '</div>';
        };

        /**
        * Creates a new Location-Suggest element to be used with this instance
        */
        this.createLocationSuggest = function () {

            var $autoSuggest = $(this.toHtml());

            $('body').append($autoSuggest);

            var $modalHeader = $(this.getModalHeaderHtml()).hide();
            $modalHeader.appendTo($('body')).fadeIn();
            this.on($modalHeader.find(this.attr.modalCloseSelector), 'click', this.hideLocationSuggest);

            //Need to focus inputField in modal to get virtual-keyboard visible on mobile devices
            $autoSuggest.find('input').focus();

            // Binding of event is done after components are created,
            // Since components did not exist in prior.
            this.on($autoSuggest.find('input'), 'keyup', this._onKeyUp);

            //  NOTE :
            //  -------
            //  If we don't set Height to window height for LocationSuggest - Div
            //  It will work fine with Chrome and Safari but not with Firefox
            //  So, we want to set height for LocationSuggest to Window height.
            //  Else Previous screen will be visible along with LocationSuggest Modal,
            //  That's not desirable behaviour of Mobile-Site / Application.
            this.setHeightForLocationSuggest($autoSuggest, $modalHeader);

        };

        /**
        *  Sets LocationSuggest height to screen's window height
        *
        *  @param {jQuery} $autoSuggest
        *  @param {jQuery} $modalHeader
        *
        */
        this.setHeightForLocationSuggest = function($autoSuggest, $modalHeader) {
            //Get window height
            var windowHeight = $(window).height();
            // modalHeader height should be subtracted from the Window Height to get final LocationSuggest Height
            var locationSuggestDivHeight = windowHeight - $modalHeader.height();
            // "min-height" property works fine with firefox
            $autoSuggest.css("min-height",locationSuggestDivHeight);
            // "height" property works fine with rest of all Browsers
            $autoSuggest.css("height",locationSuggestDivHeight);

        };


        /**
        * Creates a LocationSuggest and shows a modal containing it
        * @param {Event} ev
        * @param {Obect} data:
        *      @param {HtmlElement} data.el
        *
        * @trigger EV_AUTOSUGGEST_SHOW
        */
        this.showLocationSuggest = function(ev, data) {

            // Remove error message, if error is displayed previously
            if(this.$node.hasClass(this.attr.errorClass)){
                this.$node.removeClass(this.attr.errorClass);
            }

            if (this.$node.hasClass(this.attr.duplicateErrorClass)) {
                this.$node.removeClass(this.attr.duplicateErrorClass);
            }

            if (this.attr.pairedFieldsSelector && $(this.attr.pairedFieldsSelector).hasClass(this.attr.duplicateErrorClass)) {
                $(this.attr.pairedFieldsSelector).removeClass(this.attr.duplicateErrorClass);
            }

            this.createLocationSuggest();
            var $input    = this.select('inputSelector');
            var $autoSuggestInputField = $(this.attr.modalSelector).find('input');

            this.showModal();

            this.trigger(this.attr.EV_AUTOSUGGEST_SHOW, {
                $node: this.$node
            });

        };

        /**
        * Hides the Location Suggest
        * @triggers EV_AUTOSUGGEST_HIDE
        * @param {Event|Null} ev - mouse click event if the user got here by clicking the close button
        */
        this.hideLocationSuggest = function (ev) {
            this.hideModal();

            this.trigger(this.attr.EV_AUTOSUGGEST_HIDE, {
                $node: this.$node
            });
        };

        this.parseDataAttributes = function () {
            // looking for data-search-type="airports_domestic|airports_international|airports_all"
            var searchType = this.$node.data(this.attr._searchTypeAttr);
            if (searchType) {
                this.attr.searchType = searchType;
            }

            // looking for data-include-metro-airports
            var includeMetroAirports = this.$node.data(this.attr._includeMetroAirportsAttr);
            if (includeMetroAirports) {
                this.attr.includeMetroAirports = includeMetroAirports;
            }

            var searchTypePath  = this.$node.data(this.attr._searchTypePathAttr);
            if (!searchTypePath) {
                throw new Error("search-type-path is required, did you forget to add the data attribute");
            }

            this.attr.searchTypePath = searchTypePath;
        };

        this.after('initialize', function() {
            this.attr._id = this._uniqueid();
            this.attr.suggestionResultsSelectorWithId = this.attr.suggestionResultsSelector + "__" + this.attr._id;
            this.parseDataAttributes();

            this.$node.on('focus', this.attr.inputSelector, this.showLocationSuggest.bind(this));

            this.on(document.body, this.attr.EV_AUTOSUGGEST_RESPONSE, this.onSuggestionResponse);
        });

    }

});
