/**
 * A simple regex replacement for templating endpoint params.
 * Format expected:   '/hotels/{taId}TA/reviews'
 */
define(function (require){
  "use strict";

  return WithEndPointParser;

  function WithEndPointParser() {

    /**
    * @param {String} endPoint
    * @param {Object} params
    */
    this.parseEndpoint = function (endPoint, params) {
      var result = endPoint;
      for (var param in params) {
        result = result.replace("{" + param + "}", params[param]);
      }

      return result;
    };
  }
} );
