/**
 * This mixin provides a base for other validation mixins, by requiring attributes for selecting fields that
 * are validated and requiring specification of an error class to be added/cleared from such fields. It also
 * provides the clearErrors method, which will remove the given error class from all children of $node.
 *
 **/

var mixin = function () {
    this.attributes({
        _errorClass: "error",

        _field1Selector: null,

        /**
         * Selector for the second field to be valiated. Provided as .location2 by default, because hotel validation
         * should not be required to specify a field 2 selector, as hotel forms must validate only one field.
         * @type {String}
         */
        _field2Selector: ".location2"
    });

    this.clearErrors = function () {
        this.select("_errorClass").removeClass(this.attr._errorClass);
    };


};

module.exports = mixin;