/**
 * Air autosuggest that with parent id and origin id data values
 */
define(['../base/autosuggest', '../mixin/with-desktop-autosuggest', '../mixin/with-default-autosuggest'], function (baseAutosuggest, withDesktopAutosuggest, withDefaultAutosuggest) {

  return baseAutosuggest.mixin(withDesktopAutosuggest, withDefaultAutosuggest, function () {

    this.attributes({
        /**
         * @override with-modal or ignored.
         */
        disablePrevScroll: true
    });

    /**
    * @Override
    * @param {Array<Object>} items
    */
    this.buildItemListHtml = function (items) {
        var html = '';
        items.forEach(function(item) {

            // What gets sent to the server
            var dataValue = {
                id:                    item.id,
                parentId:              item.parentId,
                airportCode:           item.airportCode,
                parentDisplayName:     item.parentDisplayName,
                displayName:           item.displayName,
                departureFareEligible: item.departureFareEligible
            };

            //
            // If ' (single quote) is present in dataValue, it will break JSON object in between.
            // Eg: data-value='{"id":85854797,"parentId":29528,"airportCode":"MVY","parentDisplayName":"Marthas Vineyard, Massachusetts"}'
            //      v/s
            //     data-value='{"id":85854797,"parentId":29528,"airportCode":"MVY","parentDisplayName":"Martha's Vineyard, Massachusetts"}'
            //     This will result in JSON serialization : {"id":85854797,"parentId":29528,"airportCode":"MVY","parentDisplayName":"Martha"
            //     which is not a valid JSON.

            var json = JSON.stringify(dataValue).replace(/'/g,"&apos;");
            var displayName = item.altName.indexOf("-") >= 0 ? item.altName : item.displayName;
            if (displayName.indexOf("(") == -1) {
                displayName = displayName + " (" + item.airportCode + ")";
            }

            html += '<li class="do_no_popunder" data-value=\'' + json + '\'>' + displayName + '</li>';
        });

        return html;
    };
  });
});
