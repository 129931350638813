var stm = require("stm");
var $j = require("jquery");
var Backbone = require("backbone");

var Strings = stm.strings;

// ---------------------------------------------------------------------------------------------- //
/**
 * Model that can trigger an event and can check is a jquery element is in the current view port
 */
var ViewPortModel = Backbone.Model.extend({

    /**
     * Checks to see if a jquery element is currently visible in the window
     * @param {jQuery} el
     * @returns {*|boolean}
     */
    isElementInViewport: function (el) {
        el = el[0];
        var rect = el.getBoundingClientRect();

        return (
            $j(el).is(":visible") &&
    rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= $j(window).height() &&
            // element width is represented in decimal format, window.width appears to be
            // integer; normalizing to integers here for apples-tp-apples comparison
            Math.floor(rect.right) <=  Math.floor($j(window).width())
        );
    }

}, {
    /* ------------------
     Static - Constants
     ------------------ */

    /* ---------------
     Static - Events
     --------------- */

    /**
     * @const {string} Event that is triggered when the elements viewport has changed
     * @type {jquery} the object that view port has changed
     */
    VIEWPORT_CHANGED: Strings.uniqueId()

    /* ----------------
     Static - Methods
     ---------------- */

});

// ---------------------------------------------------------------------------------------------- //

module.exports = ViewPortModel;