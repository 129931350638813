var baseAirSearchForm = require("src/desktop/modules/flight-search-form/lib/base/air-search-form");
var withCheapFlights = require("src/desktop/modules/flight-open-link/lib/mixins/with-cheap-flights-urls");   
var withTabbedBrowsing = require("src/desktop/modules/flight-open-link/lib/mixins/with-tabbed-browsing-url"); 
var withDepRouteSubs = require("src/desktop/modules/flight-search-form/lib/mixins/with-dep-route-sub");
var withSubSetup = require("src/desktop/modules/flight-search-form/lib/mixins/with-sub-widget-setup");
var withAirValidation = require("src/desktop/modules/flight-search-form/lib/mixins/with-air-validation");
var withSubs = require("src/desktop/modules/flight-subs/lib/mixins/with-subs");
var withCookies = require("flight-common-mixins/lib/with-cookie-support");
var withDataCollection = require("src/desktop/modules/data-services/mixins/with-data-collection");
var win = require("win");

var component = baseAirSearchForm.mixin(withTabbedBrowsing, withCheapFlights, withDepRouteSubs, withAirValidation, withSubSetup, withSubs, withCookies, withDataCollection, function () {
    this.attributes({
        _id: null,
        _subWidgetSelector: ".do_sub_widget",
        _cookieName: "u",
        submitSelector: ".do_submit",
        location1Selector: ".location1",
        location2Selector: ".location2",
        errorClass: "error",
        duplicateErrorClass: "error_duplicate",
        emailCheckbox: ".sub-widget-email-checkbox #emailCheckbox",
        _subInputContainer: ".sub-widget__post_checkbox",
        location2Required: true,

        deviceType: "desktop",

        // Special characters provided in the attribute below will be removed from the string (parentDisplayName)
        // Since, our backend code can grab airport code from url which contains aplhabates separated by "-"
        // If we have any special character in url cheap-flights/location1-locCity-LCD-to-location2-locCity-LCD,
        // We will face 404 - Page not found for particular route.
        //  e.g : Bombay-(Mumbai)-BOM,
        specialCharToRemove:",()"

    });



    /**
     * Updates the and shows the sub widget.
     *
     * @param {Event} ev
     * @param {Object} searchData - the full data set currently contained in the component
     *     @param {String} searchType the type of search
     *     @param {String} location1Name the location name
     *     @param {Object} location1AirportCode
     *      @param {String} code the airport code
     *     @param {String} location2Name the location name
     *     @param {Object} location2AirportCode 
     *      @param {String} code the airport code
     */
    this.updateAirSubWidget = function (ev, searchData) {  
        if (searchData.searchType !== "air") {
            return;
        }

        var isRoute = searchData.location1AirportCode && searchData.location1Name &&
            searchData.location2AirportCode && searchData.location2Name;
        var isDeparture = !isRoute && searchData.location1AirportCode && searchData.location1Name;

        if (isRoute && this.attr.existingRoutes[searchData.location1AirportCode + searchData.location2AirportCode]) {
            this.select("_subWidgetSelector").hide();
        } else if (isDeparture && this.attr.existingDepartures[searchData.location1AirportCode]) {
            this.select("_subWidgetSelector").hide();
        } else {
            this.select("_subWidgetSelector").show();
        }
    };

    /**
     * This checks if the cookie that contains the user's tracking code is present. It does not check if the tracking code
     * contained in the cookie is valid.
     * @return {Boolean} true if the cookie specified by this.attr._cookieName is present and not empty
     */
    this.hasTrackingCode = function () {
        var cookie = this.getCookie(this.attr._cookieName);
        return cookie !== null && cookie.replace(/"/g,"") !== "";
    };

    this.clearErrors = function () {
        this.select("location1Selector").removeClass(this.attr.errorClass);
        this.select("location2Selector").removeClass(this.attr.errorClass);
    };

    this.openFromLink = function (formData) {
        var params = {
            name: formData.location1.parentDisplayName.replace(/,/g, ""),
            code: formData.location1.airportCode
        };
        
        win.location.href = this.buildFromUrl(params);
        return true;
    };

    this.openTabBrowsing = function (formData) {
        var urlParams = {
            mode: "air",
            flightType: formData.flightType,
            originId: formData.location1.id,
            destinationId: formData.location2.id,
            departureDate: formData.date1,
            returnDate: formData.date2,
            numTravelers: formData.travelers
        };

        // push tabbed-browsing search event to GTM data layer  
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({  
            "event": "tabbedBrowsingSearch",
            "tabbedBrowsingSearchData": urlParams
        });

        var url = this.buildTabbedBrowsingSearchUrl(urlParams); 
        win.open(url, "_blank");

        this.sendPbrTracking(formData);
    };

    this.sendPbrTracking = function(data) {
        try {
            this.trackPageActivity({
                type: "click",
                product_type: "air",
                origin: data.location1.airportCode,
                destination: data.location2.airportCode,
                date_1: data.date1,
                date_2: data.date2,
                num_adults: data.travelers,
                name: "find_fares"
            });
        } catch (e) {
            // so this never breaks the page
        }
    };

    this.submit = function (event) {
        event.preventDefault();
        this.clearErrors();
        var formData = this.getFormData();

        if (!this.validateData(formData)) {
            return;
        }

        this.doSub(formData);

        // open a new tab having tab-browsing / mobile lineup
        this.openTabBrowsing(formData);
        // redirect to fares-from page
        this.openFromLink(formData);
    };

    this.handleSubCheckboxChange = function (ev) {
        var $checkbox = this.select("_subCheckbox");
        if ($checkbox.is(":checked")) {
            this.select("_subInputContainer").show();
        } else {
            this.select("_subInputContainer").hide();
        }
    };

    this.after("initialize", function () {
        this.attr.regExpG = new RegExp("[" + this.attr.specialCharToRemove + "]", "g");
        this.on(this.select("submitSelector"), "click", this.submit);
        this.on(this.select("emailCheckbox"), "change", this.handleSubCheckboxChange);
        this.on("body", this.attr.EV_EXTERNAL_SEARCH_DATA_CHANGED, this.updateAirSubWidget);
        this.setup();
    });


});

module.exports = component;