var uuid = require("uuid");
var stm = require("stm");
var $j = require("jquery");


var mixin = function () {
    this.attributes({
        pixelUrl: stm.pageData.hwd.dataServicesServerContext.dataCollectionPixelUrl
    });

    this.getBaseCollectData = function() {
        return {
            request_id: uuid.v4(),
            page_state_id: stm.collectionSvcTrackingData.pageStateId,
            timezone_offset: new Date().getTimezoneOffset(),
            publisher_user_id: stm.collectionSvcTrackingData.publisherUserId,
            publisher_browser_id: stm.collectionSvcTrackingData.publisherBrowserId,
            publisher_session_id: stm.collectionSvcTrackingData.publisherSessionId
        };
    };

    this.generatePageStateId = function() {
        stm.collectionSvcTrackingData.pageStateId = uuid.v4();
    };

    /**
     * Tracks a user's action on a page, e.g. subscribing to an email or clicking a hotel photo. This does not represent a new
     * page state.
     * @see http://collection-service.prd.k8s.use1.smartertravel.net/docs/index.html#PageActivity_DataCollection
     *
     * @method trackPageActivity
     * @param {Object} data Data to pass to the page activity endpoint
     * @returns {Promise}
     */
    this.trackPageActivity = function(data) {
        var finalData = Object.assign({},
            this.getBaseCollectData(),
            data);

        var url = this.attr.pixelUrl + "/page-activity.gif?" + $j.param(finalData);
        var $activityPixel = $j("<img style='display:none' src='" + url + "' />");
        $j("body").append($activityPixel);
    };
};

module.exports = mixin;