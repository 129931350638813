var defineComponent = require("flight/lib/component");
require("social/fb.js"); // require FB SDK if not already required
require("src/desktop/modules/facebook-login/fb-init"); // async initialize Facebook


module.exports = defineComponent(function () {
    this.attributes({
        /**
         * @event data:external:login => triggered when user successfully logs into Facebook
         */
        EV_EXTERNAL_LOGIN: "data:external:login",

        _buttonSelector: ".facebook-login-button",

        // Data to fetch from Facebook API
        fields: ["email"],

        // Permissions to request from Facebook login
        scope: ["email"]
    });

    this.onUserData = function (data) {
        for (var i = 0; i < this.attr.fields.length; i++) {
            var field = this.attr.fields[i];
            if (data[field] === undefined) {
                this.onError({
                    type: "permission",
                    message: "Could not receive " + field + " from Facebook"
                });
                return;
            }
        }
        this.trigger(this.attr.EV_EXTERNAL_LOGIN, data);
    };

    this.onError = function (data) {
        // Error handling method, override with error handler
    };

    this.buildApiUrl = function () {
        var url = "/me?fields=";
        var arr = new Array(this.attr.fields.length);
        for (var i = 0; i < this.attr.fields.length; i++) {
            arr[i] = encodeURIComponent(this.attr.fields[i]);
        }
        url += arr.join(",");
        return url;
    };

    this.onLoginClick = function () {
        var self = this;
        var FB = window.FB;
        FB.login(function () {
            FB.getLoginStatus(function (status) {
                if (status.status === "connected") {
                    FB.api(self.buildApiUrl(), self.onUserData.bind(self));
                } else if (status.status !== "unknown") {
                    self.onError({
                        type: "login",
                        message: "Error logging into Facebook"
                    });
                }
            });
        }, {
            scope: this.attr.scope.join(",")
        });
    };

    this.after("initialize", function () {
        this.on(this.select("_buttonSelector"), "click", this.onLoginClick);
    });
});