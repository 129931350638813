
var flightCompose = require("flight/lib/compose");
var withEndpointParser = require("flight-common-mixins/lib/with-endpoint-parser");

var mixin = function () {
    flightCompose.mixin(this, [withEndpointParser]);
    this.attributes({
        baseUrl: "/",
        fromEndpoint: "cheap-flights/from-{name}/{code}/",
        toEndpoint: "cheap-flights/to-{name}/{code}/",
        routeEndpoint: "cheap-flights/{originName}-{originCode}-to-{destinationName}-{destinationCode}/",
        specialCharToRemove:",()'"

    });

    /**
     * This function builds a from city cheap flights url.
     * @param {Object} data
     *  @param {String} name the city and state of the origin
     *  @param {String} code id of the origin city
     * @return {String}
     */
    this.buildFromUrl = function (data) {
        data.name = data.name.replace(this.attr.regExpG, "");
        data.name = data.name.replace(/\./g, "");
        data.name = data.name.replace(/\//g, "-");
        data.name = data.name.replace(/\s+/g, "-");
        return this.attr.baseUrl + this.parseEndpoint(this.attr.fromEndpoint, data);
    };

    /**
     * This function builds a from city cheap flights url.
     * @param {Object} data
     *  @param {String} name the city and state of the origin
     *  @param {String} code id of the origin city
     * @return {String}
     */
    this.buildToUrl = function (data) {
        data.name = data.name.replace(/\./g, "");
        data.name = data.name.replace(/,/g, "");
        data.name = data.name.replace(/'/g, "");
        data.name = data.name.replace(/\s+/g, "-");
        return this.attr.baseUrl + this.parseEndpoint(this.attr.toEndpoint, data);
    };

    /**
     * This function builds a route cheap flights url.
     * @param {Object} data
     *  @param {String} originName the city and state of the origin
     *  @param {String} originCode st id of the origin city
     *  @param {String} destinationName the city and state of the origin
     *  @param {String} destinationCode st id of the origin city
     *
     * @return {String}
     */
    this.buildRouteUrl = function (data) {
        data.originName = data.originName.replace(/\./g, "");
        data.destinationName = data.destinationName.replace(/\./g, "");
        data.originName = data.originName.replace(/,/g, "");
        data.originName = data.originName.replace(/'/g, "");
        data.destinationName = data.destinationName.replace(/,/g, "");
        data.destinationName = data.destinationName.replace(/'/g, "");
        data.originName = data.originName.replace(/\s+/g, "-");
        data.destinationName = data.destinationName.replace(/\s+/g, "-");
        data.originName = data.originName.replace(/\)/g, "");
        data.destinationName = data.destinationName.replace(/\)/g, "");
        data.originName = data.originName.replace(/\(/g, "");
        data.destinationName = data.destinationName.replace(/\(/g, "");
        return this.attr.baseUrl + this.parseEndpoint(this.attr.routeEndpoint, data);
    };

    this.after("initialize", function () {
        this.attr.regExpG = new RegExp("[" + this.attr.specialCharToRemove + "]", "g");
    });

};

module.exports = mixin;