
var mixin = function () {
    this.attributes({
        _subWidgetSelector: ".do_sub_widget",
        _subCheckbox: ".sub-widget__checkbox",
        _subOption: ".sub_widget__option",
        _subInput: ".sub-widget__email",

        _route: ".route",
        _departure: ".departure",
        _smarter:   ".smarter",

        useOldSubWidget: false
    });

    this.doSub = function (formData) {
        var email = this.select("_subInput"),
            checkbox = this.select("_subCheckbox");
        var subData;

        if (!email.is(":visible") ||
            !email.val() ||
            (checkbox.length > 0 && !checkbox.prop("checked")) ||
            (formData.location2.airportCode && this.attr.existingRoutes[formData.location1.airportCode + formData.location2.airportCode]) ||
            (!formData.location2.airportCode && this.attr.existingDepartures[formData.location1.airportCode])) {
            // Allow the user to come back and sub later
            return;
        }

        subData = {
            email: email.val(),
            id: this.attr._id
        };

        subData.requestedSubscriptions = [
            {
                subscriptionType: this.attr.subTypeDeparture,
                destinationCode: null,
                originCode: formData.location1.airportCode
            }, {
                subscriptionType: this.attr.subTypeHwdUpdates
            }, {
                subscriptionType: this.attr.subTypeAwdUpdates
            }, {
                subscriptionType: this.attr.subTypeAirfareInsider
            }, {
                subscriptionType: this.attr.subTypeSpecialOffer
            }];

        // Additional subs that are dependent upon location2 data (may be optional)
        if (formData.location2.airportCode) {
            subData.requestedSubscriptions.push(
                {
                    subscriptionType: this.attr.subTypeRoute,
                    destinationCode: formData.location2.airportCode,
                    originCode: formData.location1.airportCode
                }, {
                    subscriptionType: this.attr.subTypeHwdCity,
                    destinationCode: formData.location2.airportCode,
                    originCode: null
                }
            );
        }

        this.subMultiple(subData);

        this.select("_subWidgetSelector").hide();
    };

    /**
     *
     * @param {Object} subData
     * @return {Object} subData -- updated subData
     */
    this.applyOtherSubs = function (subData) {
        // No-op function which will be over-ridden by another mixin (ex: with-st-sub)
        // INFO : if you want to apply ST-subs, add mixin called with-st-sub in your component
        return false;
    };

    this.after("initialize", function () {
    });
};

module.exports = mixin;