/**
* This mixin will add search date information to a component. It also
* provides the method 'getSearchDateKeys' to provide the attr keys for any
* fields that are added to the component.
*/
define(function (require) {

'use strict';

/**
  * Module exports
  */

return withDates;

/**
  * Module function
  */

function withDates() {
  this.defaultAttrs({
    /**
      * The date and time of the first search date
      * @type {Object} searchDate1
      *  @type {String} date YYYY-MM-DD
      *  @type {String} time ex. 'anytime'
      */
    searchDate1: {
      date: '',
      time: ''
    },

    /**
      * The date and time of the second search date
      * @type {Object} searchDate2
      *  @type {String} date YYYY-MM-DD
      *  @type {String} time ex. 'anytime'
      */
    searchDate2: {
      date: '',
      time: ''
    },

    /**
     * flag for default dates used in search
     * @type {Boolean} defaultDates
     */
    defaultDates: true

  });

  /**
    * Returns the array of the all of the search keys that the awd specific search model
    * uses and should transmit.
    * @return {Array}
    */
  this.getSearchDateKeys = function() {
    return [
      'searchDate2',
      'searchDate1',
      'defaultDates'
    ];
  };
}
});
