var $j = require("jquery");
var _ = require("underscore");
var stm = require("stm");
var Backbone = require("backbone");

var Strings = stm.strings;

// ---------------------------------------------------------------------------------------------- //

var DartAdModel = Backbone.Model.extend({

    /* --------------
     Backbone Magic
     -------------- */

    /**
     * The attributes are:
     *  - rootContext: {String} The context that the endpoint is called from.
     *  - endpoint: {String} The dart endpoint
     *      shown.
     *
     * @return {Object}
     * @override
     */
    defaults: function() {
        return {
            rootContext: "/",
            endpoint: "dart/site/"
        };
    }

    /* -------------
     Event Handler
     ------------- */
    /**
     * Handler that gets called when the ajax calls successfully returns. Triggers ON_TICKER_LOADED with
     * the data or ON_TICKER_FAIL
     *
     * @param data {string} actual response body
     * @param textStatus {string}
     * @param jqXhr {object} xhr object
     *  @param status {number} status of the response from the server
     */
    , onSuccess: function(data, textStatus, jqXhr) {
        if (jqXhr.status !== 200 || data.length === 0) {
            this.trigger(DartAdModel.AD_NOT_LOADED);
            return;
        }
        this.set(data);
        this.trigger(DartAdModel.AD_LOADED, {dartUrl: data});
    }

    /**
     * Triggers ON_TICKER_FAIL
     */
    , onFail: function () {
        this.trigger(DartAdModel.AD_NOT_LOADED);
    }

    /* -----------------
     Protected Methods
     ----------------- */

    /**
     * Fires off the provided city request.
     * @param {Object} params
     *  @param {string} ad_size the dimensions of the ad ('160x800')
     *  @param {string} page_type an identifier for the page that the ad is showing on
     *  @param {string} tile_num the order of the ad being displayed on the page.
     *  @param {string} position the position of the ad being displayed on the page.
     *  @param {number} ord an identifier that is the same for all ads on a page.
     *
     * @return {this}
     */
    , fetchDartAd: function(params) {
        var url = this.get("rootContext") + this.get("endpoint") + "?" + $j.param(params);
        $j.get(url, _.bind(this.onSuccess, this)).fail(_.bind(this.onFail, this));
        return this;
    }

}, {
    /* ------------------
     Static - Constants
     ------------------ */

    /* ---------------
     Static - Events
     --------------- */

    /**
     * @const {string} Event that is triggered when a dart ad url is returned.
     * When the event is triggered it is expected to be acompanied by:
     * @type {Object}
     *  @type {String} dartUrl
     */
    AD_LOADED: Strings.uniqueId()


    /**
     * @const {string} Event that is fired when the service cannot return a dart ad url
     */
    , AD_NOT_LOADED: Strings.uniqueId()

    /* ----------------
     Static - Methods
     ---------------- */

});

// ---------------------------------------------------------------------------------------------- //

module.exports = DartAdModel;