var flightCompose = require("flight/lib/compose");
var baseJsSub = require("src/desktop/modules/flight-search-form/lib/mixins/with-base-js-sub");

var mixin = function () {
    flightCompose.mixin(this, [baseJsSub]);

    /**
     * This function adds a fixed set of JS subscriptions to existing object / array of requestedSubscriptions
     * @param {Object[]} requestedSubscriptions -- update requestedSubscriptions & add other ST related subs
     */
    this.appendJsSubDataForModal = function (requestedSubscriptions) {
        requestedSubscriptions.push({subscriptionType: this.attr.subTypeJsGeneral});
        requestedSubscriptions.push({subscriptionType: this.attr.subTypeJsInspiration});
        requestedSubscriptions.push({subscriptionType: this.attr.subTypeJsDeals});
    };

    this.applyOtherSubsForJsUserModal = function (subData) {
        subData.siteOverride = this.attr.jsSite;
        this.appendJsSubDataForModal(subData.requestedSubscriptions);
        this.subMultiple(subData);
    };

    /**
     * The user-modal-st-sub-form expects a generically named func like this to do the subscription for either
     * JS or ST based on which mixin it pulled in (as of AWD-3152)
     */
    this.applyOtherSubsForExternalSiteUserModal = function (subData) {
        this.applyOtherSubsForJsUserModal(subData);
    };
};

module.exports = mixin;