/**
 * A few helpful utilities for dealing with css selectors.
 */
define(function (require) {
  "use strict";

  return WithSelectorUtils;

  function WithSelectorUtils() {

    /**
    * Given a selector ".my-class" returns "my-class"
    * @param {String} id
    * @return {String}
    */
    this.getNameFromSelector = function (id) {
      // cache it
      if (!this.getNameFromSelector[id]) {
        this.getNameFromSelector[id] = id.substr(1);
      }
      return this.getNameFromSelector[id];
    };
  }
});
