var Backbone = require("backbone");
var _ = require("lodash");
var $j = require("jquery");
var stm = require("stm");
var Strings = stm.strings;

var DartModel = require("src/desktop/modules/dart/models/dart");

var DartAdView = Backbone.View.extend({
    initialize: function (options) {
        this.options = _.extend({}, this.defaults(), options);
        this.model.on(DartModel.AD_LOADED, _.bind(this.onAdLoaded, this));

        if (!this.options.pageIdentifier) {
            throw "Page identifier is required";
        }

        if (!this.options.order) {
            throw "Order is required";
        }

        if (!this.options.position) {
            throw "Position is required";
        }

        this.adSize = this.$el.data(this.options.widthSizeSelector);

        if (!this.adSize) {
            throw "No ad size present";
        }

        this.fallbackAdSize = this.$el.data(this.options.fallbackWidthSizeSelector);

    }


    /**
     *
     * @returns {object}
     *  @returns {String} sizeSelector the selector for the data attribute that contains the size of the add to be requested
     *  @return {String} pageIdentifier the page the ad is appearing on
     *  @return {Number} order the order of the add on the page.
     */
    , defaults: function () {
        return {
            widthSizeSelector: "ad-size",
            fallbackWidthSizeSelector: "fallback-ad-size",
            testTargetAttribute: "test-target",
            pageIdentifier: null,
            order: null,
            position: null,
            contextRoot: "/",
            ord: null,
            dartAdZone: null,
        };
    }

    /**
     * The dimentions of the dart ad to be requested "widthXheight"
     * @type {String}
     */
    , adSize: null

    /**
     * Fallback ad size dimensions; fallback to be shown if primary ad size is not present
     * @type {String}
     */
    , fallbackAdSize: null

    , readyToLoad: false

    /**
     * Kicks off model ad load. The html is added to the page in the model call back.
     */
    , render: function () {
        var adSizes = [this.adSize];
        if(this.fallbackAdSize) {
            adSizes.push(this.fallbackAdSize);
        }

        var searchDataParams = {};
        var searchData = this.options.searchData;
        if (searchData) {
            searchDataParams = {
                origin_air_code: searchData.location1AirportCode,
                dest_air_code: searchData.location2AirportCode,
                dest_geo_id: searchData.location2CityId,
                search_type: searchData.searchType
            };
        }

        var travelerDataParams = {};
        var travelerData = this.options.travelerData;
        if (travelerData) {
            travelerDataParams = {
                tds_product_type: travelerData.productType,
                tds_origin_continent: travelerData.originContinentName,
                tds_origin_country: travelerData.originCountryName,
                tds_origin_region: travelerData.originRegionName,
                tds_origin_city: travelerData.originCityName,
                tds_origin_air_code: travelerData.originAirportCode,
                tds_dest_continent: travelerData.destinationContinentName,
                tds_dest_country: travelerData.destinationCountryName,
                tds_dest_region: travelerData.destinationRegionName,
                tds_dest_city: travelerData.destinationCityName,
                tds_dest_air_code: travelerData.destinationAirportCode,
                tds_dest_id: travelerData.destinationId,
                tds_dest_ta_zone: travelerData.destinationTaZone
            };
        }

        var travelerLabelParam = null;
        if (this.options.travelerLabels && this.options.travelerLabels.length) {
            travelerLabelParam = this.options.travelerLabels.join(",");
        }

        var params = {
            is_first_ad: this.options.order === 1,
            ad_size: adSizes.toString(),
            page_type: this.options.pageIdentifier,
            tile_num: this.options.order,
            position: this.options.position,
            ord: this.options.ord,
            dart_ad_zone: this.options.dartAdZone,
            traveler_labels: travelerLabelParam,
            test_target: this.$el.data(this.options.testTargetAttribute)
        };

        params = _.extend({}, params, searchDataParams, travelerDataParams);
        params = _.pick(params, function(val) { return val !== null && val !== undefined; });

        this.model.fetchDartAd(params);
    }

    /**
     * Creates the iframe once there is a dart ad url to use.
     * @param {object} data
     */
    , onAdLoaded: function (data) {
        var self = this;
        $j.get(this.options.contextRoot + "dart/wrapper/?dart_url=" + data.dartUrl)
            .done(function(data) {
                self.$el.html(data);
                self.trigger(DartAdView.VALID_AD, self);
            })
            .fail(function() {
                self.trigger(DartAdView.INVALID_AD, self);
            });
    }
}, {
    /**
     * @const the event triggered when the ad loads correctly
     * when this is triggered the instance of the view is expected to be passed along.
     */
    VALID_AD: Strings.uniqueId()

    /**
     * @const the event triggered when the ad loads incorrectly
     * when this is triggered the instance of the view is expected to be passed along.
     */
    , INVALID_AD: Strings.uniqueId()

    /**
     * @const the event triggered when the ad loads incorrectly
     * when this is triggered the instance of the view is expected to be passed along.
     */
    , FALLBACK_AD: Strings.uniqueId()

    /**
     * @const the string to represent known source for talking-flash related Ads.
     * When we find this src in ad's inner frame, we automatically detect ad as a valid ad.
     */
    , GOOGLE_SYNDICATION_SRC_FRAGMENT : "googlesyndication.com"
});

module.exports = DartAdView;