var flightCompose = require("flight/lib/compose");
var baseStSub = require("src/desktop/modules/flight-search-form/lib/mixins/with-base-st-sub");

var mixin = function () {
    flightCompose.mixin(this, [baseStSub]);

    this.attributes({
        _subCheckbox: ".sub-widget__checkbox",
        _subOption: ".sub_widget__option",
        _smarter:   ".smarter"
    });

    /**
     *
     * @param {Object} requestedSubscriptions -- update requestedSubscriptions
     */
    this.appendSTSubData = function (requestedSubscriptions) {
        requestedSubscriptions.push({subscriptionType: this.attr.subTypeStUpdates});
        requestedSubscriptions.push({subscriptionType: this.attr.subTypeStTripReminder});
        requestedSubscriptions.push({subscriptionType: this.attr.subTypeStDealAlert});
    };

    /**
     *
     * @param {Object} subData
     * @return {Boolean} true is additional sub(s) were applied, false otherwise
     */
    this.applyOtherSubs = function (subData) {
        var subST = this.select("_subOption").filter(this.attr._smarter).find(this.attr._subCheckbox).is(":checked");
        // Replace subData.requestedSubscriptions with ST-SubTypeSubs
        if (subST) {
            subData.siteOverride = this.attr.stSite;
            this.appendSTSubData(subData.requestedSubscriptions);
            this.subMultiple(subData);
            return true;
        }

        return false;
    };

    this.after("initialize", function () {
    });
};

module.exports = mixin;