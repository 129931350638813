/**
* If the input hasn't been set server side, we'll try to pull data from localStorage
* and set that as the default locaion suggestion.
*
* Components that make use of this mixin must have satisfy two requirements:
* @attr {String} inputSelector a css selector.
* @method 'updateInput(id, display)'
*
* This mixin will take the name attribute from the dom element sellected by
* 'inputSelector' and then use that name as a key to pull the correct value out of
* 'LOCAL_STORAGE_KEYS'. This mixin will check that localstorage is supported and
* will not crash if it is not.
*
*
*/
define(function() {

    return WithDefaultAutoSuggest;

    function WithDefaultAutoSuggest() {

        this.defaultAttrs({
            LOCAL_STORAGE_KEYS:  {
                originId:       'originId',
                destinationId:  'destinationId'
            }
        });

        /**
        * We need special handling to detect local storage due a  bug in safari
        * where it will throw an exception in "porn mode"
        */
        this.isLocalStorageSupported = function () {
            try {
                if (!window.localStorage) return false;
                window.localStorage.setItem('test', 1);
                window.localStorage.removeItem('test', 1);
                return true;
            } catch (error) {
                return false;
            }
        };

        /**
        * @returns {Object}
        */
        this.getFromLocalStorage = function () {
            var $input    = this.select('inputSelector'),
                inputName = $input[0].name,
                data      = {};

            if (!this.isLocalStorageSupported()) return data;

            try {
                data = JSON.parse(window.localStorage.getItem(this.attr.LOCAL_STORAGE_KEYS[inputName]));
            } catch (e) {
                // bummer
            }

            return (data && data.id && data.display) ? data : {};
        };

        this.preSelectDefaultAutoSuggest = function () {
            // don't override values that were set server side
            if (this.select('inputSelector').val()) return;

            var data = this.getFromLocalStorage();
            if (data.id && data.display) {
                this.updateInput(data.id, data.display);
            }

        };

        this.after('initialize', function() {
            this.preSelectDefaultAutoSuggest();
        });

    }

});
