var subLanderSearchForm = require("src/desktop/modules/flight-search-form/lib/ui/sub-lander-search-form");

var component = subLanderSearchForm.mixin(function () {

    this.attributes({
        submitButtonSelector: ".submit",
        disclaimerSelector: ".search_disclaimer",
        successButtonSelector: ".success"
    });

    this.handleBulkSubJsonResponse = function (data) {
        if (data && data.code === this.attr.INVALID_EMAIL_CODE) {
            this.select("emailContainerSelector").addClass(this.attr.errorClass);
        } else {
            this.select("submitButtonSelector").css("display", "none");
            this.select("disclaimerSelector").css("display", "none");
            this.select("successButtonSelector").css("display", "block");
        }
    };

});

module.exports = component;