var $j = require("jquery");
var defineComponent = require("flight/lib/component");
var withClosable = require("src/desktop/modules/flight-base-components/lib/closeable");
var withPredictUserMovement = require("src/desktop/modules/flight-base-components/lib/predict-user-exit");
var withCookies = require("flight-common-mixins/lib/with-cookie-support");


var component = defineComponent(withClosable, withPredictUserMovement, withCookies, function () {

    this.attributes({
        modalSelector : ".do_subscribe_users",

        subscriptionModalContentSelector : ".subscription_modal_bg_container",
        /**
         * Selector for the ST or JS subscription modal, as of AWD-3152
         */
        subscriptionModalExtSiteSelector : ".subscription_modal_ext",
        successViewSelector : ".subscription_success",

        mobileStageOneSelector: ".user-sub-modal-layer.mobile",
        mobileStageTwoSelector: ".sub-modal-part-two",

        mobileEmailContainer: ".mobile_email_field",

        awdEmailSelector: ".subscription_modal_bg_container .input-container input[type='email']",
        extSiteEmailSelector : ".subscription_modal_ext .input-container input[type='email']",

        extSuccessMessageSelector : ".success_sub_message_ext",
        extSuccessMessageHiddenClass : "success_sub_message_ext_hidden",

        hiddenComponentClass : "hidden_component",

        /**
         * Class name indicating that the sub overlay is _not_ being shown as a follow-up to a successful AWD sub.
         * In other words, it is only prompting for subscriptions to an external site (e.g. JS or ST).
         */
        extOnlyClass: "sub_overlay_ext_only",

        awdSubOverlayCookieName : "subOverlayShown",
        /**
         * While the A/B test in AWD-3152 is running, the presence of this cookie will prevent both the ST and JS sub
         * modals from being displayed. After the test concludes, TBD if we cookie separately or not.
         */
        stSubOverlayCookieName : "stSubOverlayShown",
        stUserSubscribedCookie: "stUserSubscribed",
        jsUserSubscribedCookie: "jsUserSubscribed",
        userCookieName : "u",

        /**
         * @event ui:user:subscribed:success => event to show success message
         */
        EV_USER_SUBSCRIBED_SUCCESS: "ui:user:subscribed:success",

        /**
         * @event ui:start:user:tracking => event to start tracking user movement
         */
        EV_START_USER_TRACKING: "ui:start:user:tracking",

        /**
         * @event ui:stop:user:tracking => event to start tracking user movement
         */
        EV_STOP_USER_TRACKING: "ui:stop:user:tracking",

        /**
         * @event ui:subscription:modal:exit:offer => event to show subscription modal as an exit-offer when user tries to leave
         */
        EV_SHOW_EXIT_OFFER_SUB_MODAL: "ui:subscription:modal:exit:offer",

        /**
         * @event ui:show:other:popup:layer => event to notify the listeners those are displaying popup/layer
         *                                     with less priority than Sub-Acq-EntryGate.
         */
        EV_SHOW_OTHER_POPUP_LAYER: "ui:show:other:popup:layer",

        /**
         * Timer to automatically hide success-view after presenting on success
         */
        HIDE_SUCCESS_VIEW_TIMER: 3000,

        /**
         * AWD User-Subscription-Modal should be shown only once in 1-day span.
         */
        AWD_SUB_OVERLAY_COOKIE_TTL_DAYS: 1,

        /**
         * ST-specific User-Subscription-Modal (for known AWD users) should be shown only once in 30-day span.
         */
        ST_SUB_OVERLAY_COOKIE_TTL_DAYS: 30
    });

    /**
     * Close the subscription modals by simulating a click in the appropriate element
     */
    this.closeSubModals = function() {
        this.select("_closeActionSelector").trigger("click");
    };

    /**
     * Function to determine whether we want to show second sub-layer for ST or success-view
     *      - check for nextView (data.nextView) ['determine' v/s 'success']
     *          - success would simply lead to final-success-view / success-confirmation-view
     *          - if we have nextView = 'determine' and we have ST-sub-modal present, we would show ST-Sub-Modal
     *            else we would simply show final-success-view / success-confirmation-view
     *
     * @param {Object} ev - event
     * @param {Object} data
     *      @param {String} nextView 'determine' or 'success'
     *      @param {String} userEmail '' or 'valid-email'
     */
    this.showNextView = function (ev, data) {
        var nextView = data.nextView;
        var showExternalSiteSuccessMessage = data.showExternalSiteSuccessMessage;
        if (nextView === "determine" && this.shouldShowExternalSiteSpecificModal()) {
            // Show external site sub modal
            this.select("subscriptionModalContentSelector").addClass(this.attr.hiddenComponentClass);
            this.select("subscriptionModalExtSiteSelector").removeClass(this.attr.hiddenComponentClass);

            // Cookie the ST sub modal view
            this.setCookie(this.attr.stSubOverlayCookieName, "true", this.attr.ST_SUB_OVERLAY_COOKIE_TTL_DAYS);

            // Pass email-address to external site modal
            if (data.userEmail) {
                this.select("extSiteEmailSelector").val(data.userEmail);
            }
        } else if (nextView === "success") {
            // Show Final Successview
            this.showSuccessView(showExternalSiteSuccessMessage);
        } else {
            this.closeSubModals();
        }
    };

    /**
     * Function shows Success-view for a few seconds (until this.attr.HIDE_SUCCESS_VIEW_TIMER)
     *  - Flips form to successview on success event
     *  - Sets timer to automatically hide the successview
     *  @param {boolean} showExternalSiteSuccessMessage - whether or not to show external site success message.
     */
    this.showSuccessView = function (showExternalSiteSuccessMessage) {
        this.select("subscriptionModalContentSelector").addClass(this.attr.hiddenComponentClass);
        this.select("subscriptionModalExtSiteSelector").addClass(this.attr.hiddenComponentClass);
        this.select("successViewSelector").removeClass(this.attr.hiddenComponentClass);

        if(!showExternalSiteSuccessMessage) {
            this.select("extSuccessMessageSelector").addClass(this.attr.extSuccessMessageHiddenClass);
        }

        //Hide modal-success-view after a specified number of seconds
        var self = this;
        setTimeout(function(){
            self.closeSubModals();
        }, this.attr.HIDE_SUCCESS_VIEW_TIMER);

    };

    this.showAwdModal = function () {
        this.$node.removeClass("hidden");
        this.setCookie(this.attr.awdSubOverlayCookieName, "true", this.attr.AWD_SUB_OVERLAY_COOKIE_TTL_DAYS);
    };

    /**
     * Show the ST-specific modal - this is meant to be shown for existing AWD users in an attempt to drive ST subs.
     *
     * Note: this is <em>different</em> than the step2 ST sub signup which follows the normal AWD modal flow above
     */
    this.showExternalSiteSpecificModal = function() {
        this.showNextView(null, {nextView: "determine"});
        // Tag the ST modal & subscription success elements as being ST-only, i.e. not coming after user registers for AWD alerts
        this.select("subscriptionModalExtSiteSelector").addClass(this.attr.extOnlyClass);
        this.select("successViewSelector").addClass(this.attr.extOnlyClass);
        this.select("mobileStageOneSelector").addClass(this.attr.extOnlyClass);
        this.$node.removeClass("hidden");
    };

    /**
     * Function checks following things
     *  - Is this a known (cookied) AWD user
     *  - If not a known user, have we shown subscription_modal in specified time-range
     *
     *  FYI:-
     *   We want to track only non-registered users
     *   We would like to show user_subscription_modal only once within specific time-range
     *
     */
    this.shouldShowAwdModal = function () {
        return !(this.isAwdUser() || this.hasSeenAwdModal());
    };

    /**
     * Show the external-site-only sub modal when the following conditions are met:
     *  - this is a known, cookied user
     *  - the external site modal cookie does not exist (user hasn't seen this sub modal in specified timespan)
     *  - we have not cookied the user as having signed up for the external newsletter
     * @returns {boolean|*}
     */
    this.shouldShowExternalSiteSpecificModal = function () {
        return (this.isAwdUser() && !this.hasSeenStModal()
            && this.select("subscriptionModalExtSiteSelector").length > 0 // confirm that there is an external modal on the page to be shown
            && !this.isSubscribedToSt()
            // TODO: we're sort of unexpectedly checking if the user is subbed to JS for the purposes of the A/B test in
            // AWD-3152. This func is pulling double duty for deciding if we should show *either* the ST or the JS modal,
            // and the styling specifics are handled in the thymeleaf template.
            // We do this because we want a user in either recipe to have the same chance of being shown the modal,
            // so that sub data between the recipes is comparable.
            && !this.isSubscribedToJs());
    };

    /**
     * Check for existing AWD user cookie
     * @returns {boolean}
     */
    this.isAwdUser = function() {
        var awdCookie = this.getCookie(this.attr.userCookieName);
        return (awdCookie !== null && awdCookie.replace(/"/g,"") !== "");

    };

    /**
     * Checks whether the user has already been cookied as having signed up for the ST newsletter
     * @returns {boolean}
     */
    this.isSubscribedToSt = function() {
        var stSubscriptionCookie = this.getCookie(this.attr.stUserSubscribedCookie);
        return (stSubscriptionCookie !== null && stSubscriptionCookie.replace(/"/g,"") !== "");
    };

    /**
     * Checks whether the user has already been cookied as having signed up for the JS newsletter
     * @returns {boolean}
     */
    this.isSubscribedToJs = function() {
        var jsSubscriptionCookie = this.getCookie(this.attr.jsUserSubscribedCookie);
        return (jsSubscriptionCookie !== null && jsSubscriptionCookie.replace(/"/g,"") !== "");
    };

    /**
     * Check for existing cookie related to AWD userSubscriptionModal views
     * @returns {boolean}
     */
    this.hasSeenAwdModal = function() {
        var awdSubModalOverlayCookie = this.getCookie(this.attr.awdSubOverlayCookieName);
        return (awdSubModalOverlayCookie !== null && awdSubModalOverlayCookie.replace(/"/g,"") !== "");
    };

    /**
     * Check for existing cookie related to ST userSubscriptionModal views
     * @returns {boolean}
     */
    this.hasSeenStModal = function() {
        var stSubModalOverlayCookie = this.getCookie(this.attr.stSubOverlayCookieName);
        return (stSubModalOverlayCookie !== null && stSubModalOverlayCookie.replace(/"/g,"") !== "");
    };

    /**
     * For mobile only, when "stage one" (showing minimal content) is clicked we want to
     * expand "stage two" content to allow for full user signup, and also remove the listener class
     */
    this.showMobileStageTwo = function() {
        clearTimeout(this.hideSubGateTimer);
        this.select("mobileStageTwoSelector").removeClass(this.attr.hiddenComponentClass);
        this.select("mobileStageOneSelector").addClass("expanded");

        // enabled the initial email field,
        this.select("extSiteEmailSelector").prop("disabled",false);
        this.select("awdEmailSelector").prop("disabled",false);
        this.select("awdEmailSelector").focus();
        this.off(this.attr.mobileStageOneSelector, "click");

        // Cookie the ST sub modal view
        this.setCookie(this.attr.stSubOverlayCookieName, "true", this.attr.ST_SUB_OVERLAY_COOKIE_TTL_DAYS);
    };

    /**
     * Implement MODE-specific configuration that is needed when we initialize UI component
     *  ENTRY_GATE v/s EXIT_OFFER
     */
    this.initModal = function() {
        if (this.shouldShowAwdModal()) {
            this.showAwdModal();
        } else if (this.shouldShowExternalSiteSpecificModal()) {
            // If AWD sub modal will not be shown, still consider external-site-only sub modal
            this.showExternalSiteSpecificModal();
        } else {
            $j("body").trigger(this.attr.EV_SHOW_OTHER_POPUP_LAYER);
        }
    };

    this.after("initialize", function () {
        //init modal based on mode which we want to show,
        this.initModal();
        this.on(this.attr.EV_USER_SUBSCRIBED_SUCCESS, this.showNextView);
        this.on(this.attr.mobileStageOneSelector, "click", this.showMobileStageTwo);

        // Set up a timer to fade out & remove the mobile sub gate. This timer will be canceled if the user interacts
        // with the gate before it starts fading, which is handled in this.showMobileStageTwo
        var self = this;
        this.hideSubGateTimer = setTimeout(function() {
            var subGate = self.select("mobileStageOneSelector");
            var removeSubGate = function() {
                subGate.remove();
            };
            subGate.fadeTo(500, 0, removeSubGate); // fade out of view & then remove
        },  10000);
    });

});

module.exports = component;