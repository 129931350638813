/**
 * Data component for tracking timings to the metrics service.
 *
 * Listens to:
 * ============
 * data:metrics:track:performance
 *  - {String} pageName: what page are we tracking on
 *
 * data:metrics:track:timings
 *  - {String} pageName: what page are we tracking on
 *  - {object} timings: Key value pair object of string keys matching to number values for tracking several
 *                      timings at the same time.
 *
 * data:metrics:set:mark
 *  - {String} pageName: what page are we tracking on
 *  - {String} timingKey: what attribute of the site are we logging
 *
 * data:metrics:track:mark
 *  - {String} pageName: what page are we tracking on
 *  - {String} timingKey: what attribute of the site are we logging
 *
 * @class Data.Metrics
 */

var defineComponent = require("flight/lib/component");
var metricsApi = window.Metrics;
var component = defineComponent(metrics);

function metrics() {
    this.defaultAttrs({
        /**
         * url to the metrics logging service
         *
         * @var {String}
         */
        host: "",

        /**
         * what version of the metrics api are we talking to
         *
         * @var {number}
         */
        version: 1,

        /**
         * @event data:metrics:track:performance
         *  @param pageName {String} what page is the tracking for
         */
        EV_METRICS_TRACK_PERFORMANCE: "data:metrics:track:performance",

        /**
         * @event data:metrics:track:timings
         *  @param pageName {String} what page is the tracking for
         *  @param timings {Object} map of key value pairs with the tracking data
         */
        EV_METRICS_TRACK_TIMINGS: "data:metrics:track:timings",

        /**
         * @event data:metrics:set:mark
         *  @param pageName {String} what page is the tracking for
         *  @param timingKey {String} what attribute of the site are we logging
         */
        EV_METRICS_SET_MARK: "data:metrics:set:mark",

        /**
         * @event data:metrics:track:mark
         *  @param pageName {String} what page is the tracking for
         *  @param timingKey {String} what attribute of the site are we logging
         */
        EV_METRICS_TRACK_MARK: "data:metrics:track:mark"
    });

    /**
     *
     * @param event
     * @param request
     *  @param pageName {String} what page is the tracking for
     */
    this.onTrackPerformance = function (event, request) {
        metricsApi.options.pageName = request.pageName;
        metricsApi.trackPerformanceTimings();
    };

    /**
     * Event handler for tracking timings to the metrics service
     *
     * @param event - the event that triggered this
     * @param request
     *  @param pageName {String} what page is the tracking for
     *  @param timings {Object} map of key value pairs with the tracking data
     */
    this.onTrackTimings = function (event, request) {
        metricsApi.options.pageName = request.pageName;

        // convert the map to an array of timing objects
        var timings = [];
        for (var key in request.timings) {
            timings.push(new metricsApi.Timing(key, request.timings[key]));
        }

        metricsApi.trackTimings(timings);
    };

    /**
     * Event handler for setting marks in the metrics service
     *
     * @param event - the event that triggered this
     * @param request {object} request object
     *  @param pageName {String} name of the page that we are on
     *  @param timingKey {String} name of the key we would like to track this under
     */
    this.onSetMark = function (event, request) {
        metricsApi.options.pageName = request.pageName;
        metricsApi.setMark(request.timingKey);
    };

    /**
     * Event handler for tracking previously set marks in the metrics service.
     *
     * @param event - the event that triggered this
     * @param request {object} request object
     *  @param pageName {String} name of the page that we are on
     *  @param timingKey {String} name of the key we would like to track this under
     */
    this.onTrackMark = function (event, request) {
        metricsApi.options.pageName = request.pageName;
        metricsApi.trackMark(request.timingKey);
    };

    /**
     * Set the Metrics Api Options based on the attributes
     */
    this.setupMetricsApi = function () {
        metricsApi.options.host = this.attr.host;
        metricsApi.options.version = this.attr.version;
    };

    this.after("initialize", function () {
        this.setupMetricsApi();
        this.on(this.attr.EV_METRICS_TRACK_PERFORMANCE, this.onTrackPerformance);
        this.on(this.attr.EV_METRICS_TRACK_TIMINGS, this.onTrackTimings);
        this.on(this.attr.EV_METRICS_SET_MARK, this.onSetMark);
        this.on(this.attr.EV_METRICS_TRACK_MARK, this.onTrackMark);
    });
}

module.exports = component;