/**
 * This mixin provides the events and methods needed for communicating with the sub data component
 *
 * REQUIRES
 * ========
 * this.attr._id
 *
 * In order to properly make use of the sub response just override the handle methods. They are already wired to automatically
 * trigger.
 */
var subTypes = require("src/desktop/modules/flight-subs/lib/data/subTypeConstants");
var MD5 = require("crypto-js/md5");
var mixin = function () {
    this.attributes({

        /**
         * @event "data:subs:bulk:request" this event requests a one or more fare subscriptions with the resulting new subs returned in the requested content type (json/html)
         *  @param {Object} data
         *      @param {String} contentType
         *      @param {String} email
         *      @param {String} firstName
         *      @param {Object} requestedSubscriptions
         *          @param {String} subscriptionType
         *          @param {String} originCode
         *          @param {String} destinationCode
         *          @param {String} frequency
         */
        EV_BULK_SUB_REQUEST: "data:subs:bulk:request",

        /**
         * @event "data:subs:update:request" perform update on __all__ subs assigned to a user
         *  @param {Object} data
         *      @param {String} frequency
         *      @param {String} hold-until date (YYYY-mm-dd)
         */
        EV_UPDATE_SUBS_REQUEST: "data:subs:update:request",

        /**
         * @event "data:sub:delete" perform delete action on the sub id
         * @param {String} subscriptionId
         */
        EV_DELETE_SUB_REQUEST: "data:sub:delete:request",

        /**
         * @event "data:subs:delete" perform bulk unsub action
         *  @param {Object}
         *      @param {String} userTrackingCode
         *      @param {String} unsubReason
         *      @param {String} unsubFreetext
         */
        EV_DELETE_SUBS_REQUEST: "data:subs:delete:request",

        /**
         * @event "data:user:update:request" perform user update
         *  @param {Object}
         *      @param {String} email
         *      @param {String} firstName
         *      @param {String} airportCode
         */
        EV_UPDATE_USER_REQUEST: "data:user:update:request",

        /**
         * @event "data:subs:bulk:response:json" this event sends a bulk subscription json response
         *  @param {Object} data
         *      @param {String} id
         *      @param {Boolean} success
         *      @param {String} msg
         */
        EV_BULK_SUB_JSON_RESPONSE: "data:subs:bulk:response:json",

        /**
         * @event "data:subs:bulk:response" this event sends a bulk subscription html response
         *  @param {String} data sub html content
         */
        EV_BULK_SUB_HTML_RESPONSE: "data:subs:bulk:response:html",

        /**
         * @event "data:subs:update:response" send global subs update json response
         */
        EV_UPDATE_SUBS_RESPONSE: "data:subs:update:response",

        /**
         * @event "data:sub:delete:response" sends sub deletion json response
         */
        EV_DELETE_SUB_RESPONSE: "data:sub:delete:response",

        /**
         * @event "data:subs:delete:response" sends bulk unsub json response
         */
        EV_DELETE_SUBS_RESPONSE: "data:subs:delete:response",

        /**
         * @event "data:user:update:response" sends user update json response
         */
        EV_UPDATE_USER_RESPONSE: "data:user:update:response",

        /**
         * @event "data:user:updated" user data has possibly been updated
         */
        EV_USER_DATA_UPDATED: "data:user:updated",

        /**
         * The error code that the user is already subscribed to
         */
        SUBSCRIPTION_EXISTS_CODE: 603,

        /**
         * The error code that the email provided was invalid
         */
        INVALID_EMAIL_CODE: 160,

        /**
         * The error code that the email provided is already in use
         */
        USER_EMAIL_ALREADY_EXISTS_CODE: 602,

        subTypeRoute: subTypes.subTypeRoute,

        subTypeDeparture: subTypes.subTypeDeparture,

        subTypeArrival: subTypes.subTypeArrival,

        subTypeHwdUpdates: subTypes.subTypeHwdUpdates,

        subTypeAwdUpdates: subTypes.subTypeAwdUpdates,

        subTypeAirfareInsider: subTypes.subTypeAirfareInsider,

        subTypeTripWatch: subTypes.subTypeTripWatch,

        subTypeSpecialOffer: subTypes.subTypeSpecialOffer,

        subTypeHwdCity: subTypes.subTypeHwdCity,

        subTypeJsGeneral: subTypes.subTypeJsGeneral,

        subTypeJsInspiration: subTypes.subTypeJsInspiration,

        subTypeJsDeals: subTypes.subTypeJsDeals,

        subTypeStUpdates: subTypes.subTypeStUpdates,

        subTypeStTripReminder: subTypes.subTypeStTripReminder,

        subTypeStDealAlert: subTypes.subTypeStDealAlert,

        subTypeStSpotlight: subTypes.subTypeStSpotlight,

        gtmAwdSubEvent: "awdSubscriptionEvent",
        gtmAwdNewSubEvent: "awdSubscriptionNewSubEvent",
        gtmAwdNewUserEvent: "awdSubscriptionNewUserEvent",

        awdSite: "awd",
        awdUkSite: "awduk"
    });

    /**
     *
     * @param data
     */
    this.subMultiple = function (data) {
        this.trigger(this.attr.EV_BULK_SUB_REQUEST, data);
    };

    this.updateSubs = function(data) {
        this.trigger(this.attr.EV_UPDATE_SUBS_REQUEST, data);
    };

    this.deleteSub = function(data) {
        this.trigger(this.attr.EV_DELETE_SUB_REQUEST, data);
    };

    this.deleteSubs = function(data) {
        this.trigger(this.attr.EV_DELETE_SUBS_REQUEST, data);
    };

    this.updateUser = function(data) {
        this.trigger(this.attr.EV_UPDATE_USER_REQUEST, data);
    };

    /**
     *
     * @param {Object} data service response
     *  @param {String} id
     *  @param {boolean} success
     *  @param {String} msg
     *
     * @return {Object}
     *  @return {boolean} success
     *  @return {String} msg
     */
    this.handleBulkSubJsonResponse = function (data) {
        return data;
    };

    /**
     *
     * @param {String} html response content
     *
     * * @return {String} html response content
     */
    this.handleBulkSubHtmlResponse = function (data) {
        return data;
    };

    this.handleUpdateSubsResponse = function(data) {
        return data;
    };

    this.handleDeleteSubResponse = function(data) {
        return data;
    };

    this.handleDeleteSubsResponse = function(data) {
        return data;
    };

    this.handleUpdateUserResponse = function(data) {
        return data;
    };

    this.onMultipleSubJsonResponse = function (ev, data) {
        if (this.attr._id === data.id) {
            // Trigger user data update
            this.trigger(this.attr.EV_USER_DATA_UPDATED, data.user);

            // Update GTM data later with user sub info
            if (data.success && data.user) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    "event": this.attr.gtmAwdSubEvent
                });

                if (data.user.newSubscriptions && data.user.newSubscriptions.length) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        "event": this.attr.gtmAwdNewSubEvent,
                        "newSubCount": data.user.newSubscriptions.length
                    });

                    if (data.user.userInfo.newUser) {
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            "event": this.attr.gtmAwdNewUserEvent,
                            "userTrackingCode": data.user.userInfo.trackingCode,
                            "emailHash": MD5(data.user.userInfo.emailAddress).toString()
                        });
                    }
                }
            }

            // Delegate to specific handling of the response
            this.handleBulkSubJsonResponse(data);
        }
    };

    this.onMultipleSubHtmlResponse = function (ev, data) {
        if (this.attr._id === data.id) {
            this.handleBulkSubHtmlResponse(data);
        }
    };

    this.onUpdateSubsResponse = function (ev, data) {
        if (this.attr._id === data.id) {
            this.handleUpdateSubsResponse(data);
        }
    };

    this.onDeleteSubResponse = function (ev, data) {
        if (this.attr._id === data.id) {
            this.handleDeleteSubResponse(data);
        }
    };

    this.onDeleteSubsResponse = function (ev, data) {
        if (this.attr._id === data.id) {
            this.handleDeleteSubsResponse(data);
        }
    };

    this.onUpdateUserResponse = function (ev, data) {
        if (this.attr._id === data.id) {
            this.handleUpdateUserResponse(data);
        }
    };


    this.after("initialize", function () {
        this.on("body", this.attr.EV_BULK_SUB_JSON_RESPONSE, this.onMultipleSubJsonResponse);
        this.on("body", this.attr.EV_BULK_SUB_HTML_RESPONSE, this.onMultipleSubHtmlResponse);
        this.on("body", this.attr.EV_UPDATE_SUBS_RESPONSE, this.onUpdateSubsResponse);
        this.on("body", this.attr.EV_DELETE_SUB_RESPONSE, this.onDeleteSubResponse);
        this.on("body", this.attr.EV_DELETE_SUBS_RESPONSE, this.onDeleteSubsResponse);
        this.on("body", this.attr.EV_UPDATE_USER_RESPONSE, this.onUpdateUserResponse);
    });
};

module.exports = mixin;