var defineComponent = require("flight/lib/component");
var CookieUtils = require("src/desktop/utils/cookies");

var component = defineComponent(dartAdContainer);

function dartAdContainer() {
    this.attributes({
        _closeActionSelector: ".adhesion_unit_container .do_close",

        _closeContainerSelector: ".adhesion_unit_container",

        /**
         * Name of cookie indicating that this ad has been shown recently
         */
        adhesionUnitShownCookie: "adhesionUnitShown",

        /**
         * Size of the dart ad to be shown, e.g. "320x50"
         */
        adSize: "320x50",

        /**
         * Suppression window, in days, to not show ad after a user has seen it
         */
        suppressionWindow: 2
    });

    this.createAd = function() {
        this.$node.find("#header").append(
            "<div class='adhesion_unit_container visible-xs'>" +
                "<div class='adhesion_unit do_dart_ad visible-xs' data-ad-size='" + this.attr.adSize + "' data-ad-position='abovefold'></div>" +
                "<div class='do_close'>X</div>" +
            "</div>"
        );
    };

    this.doClose = function() {
        this.select("_closeContainerSelector").addClass("hidden");
        CookieUtils.setCookie(this.attr.adhesionUnitShownCookie, "true", this.attr.suppressionWindow);
    };

    this.after("initialize", function() {
        if (CookieUtils.getCookie(this.attr.adhesionUnitShownCookie)) {
            return;
        }

        this.createAd();

        this.on(this.select("_closeActionSelector"), "click", this.doClose);
    });
}

module.exports = component;
