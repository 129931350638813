var Cookies = require("src/desktop/utils/cookies");

var mixin = function () {
    this.attributes({
        jsSite: "js",
        jsUserSubscribedCookie: "jsUserSubscribed",
        EV_USER_SUBSCRIBED_SUCCESS: "ui:user:subscribed:success",
        JS_USER_SUBSCRIBED_COOKIE_TTL_DAYS: 365
    });

    this.dropJsUserSubscribedCookie = function() {
        Cookies.setCookie(this.attr.jsUserSubscribedCookie, "true", this.attr.JS_USER_SUBSCRIBED_COOKIE_TTL_DAYS);
    };

    this.after("initialize", function () {
        var self = this;
        this.on(this.attr.EV_USER_SUBSCRIBED_SUCCESS, function(ev, data) {
            if (data.siteForSubRequest === this.attr.jsSite) {
                self.dropJsUserSubscribedCookie();
            }
        });
    });
};

module.exports = mixin;