var withValidation = require("./with-validation");

var mixin = function () {
    withValidation.call(this);
    this.attributes({
        _errorClass: "error",

        _duplicateErrorClass: "error_duplicate",

        _field1Selector: ".location1",

        _field2Selector: ".location2",

        location2Required: true
    });

    this.validateData = function(formData) {
        var isDataValid = true;
        if (!formData.location1 || !formData.location1.airportCode || !formData.location1.parentDisplayName) {
            this.select("_field1Selector").addClass(this.attr._errorClass);
            isDataValid = false;
        }
        if (this.attr.location2Required && (!formData.location2 || !formData.location2.airportCode || !formData.location2.parentDisplayName)) {
            this.select("_field2Selector").addClass(this.attr._errorClass);
            isDataValid = false;
        }
        var locationCode1 = formData.location1.airportCode !== undefined ? formData.location1.airportCode.code || formData.location1.airportCode : undefined;
        var locationCode2 = formData.location2.airportCode !== undefined ? formData.location2.airportCode.code || formData.location2.airportCode : undefined;
        if (locationCode1 !== undefined && locationCode1 !== undefined && locationCode1 === locationCode2) {
            this.select("_field1Selector").addClass(this.attr._duplicateErrorClass);
            this.select("_field2Selector").addClass(this.attr._duplicateErrorClass);
            isDataValid = false;
        }
        return isDataValid;
    };
};

module.exports = mixin;