var $j = require("jquery");
var stm = require("stm");
var AutoSuggestData = require("flight-autosuggest/lib/data/autosuggest");
var AutoSuggestUI = require("flight-autosuggest/lib/ui/desktop-air-autosuggest-with-parent-id");
var ViewPortModel = require("src/desktop/modules/visibility/model/viewPort");
var DartAdController = require("src/desktop/modules/dart/controllers/main");
var WindowChangeController = require("src/desktop/modules/visibility/controller/main");
var UserSubModalEntryGateUI = require("src/desktop/modules/user-subscription-modal/lib/ui/user-sub-modal-entry-gate");
var SearchForm = require("src/desktop/modules/flight-search-form/lib/ui/single-input-sub-modal");

/**
 * ===========================================================================
 */

$j(function (){
    AutoSuggestData.attachTo("body", {endPoint: stm.pageData.hwd.endPointContext});
    AutoSuggestUI.attachTo(".do_auto_suggest", {
        modalRemovalTimeout: 0,
        pairedFieldsSelector:".airfare-locations"
    });

    // Flight JS for subscription_modal functionality, etc
    UserSubModalEntryGateUI.attachTo(".do_subscribe_users");

    SearchForm.attachTo(".subscription-widget", {_id: "sub-widget"});

    // Close logout message after 5 seconds
    setTimeout(function(){ $j(".logout_confirmation").addClass("hidden");}, 5000);

    this.viewPortModel = new ViewPortModel();
    new WindowChangeController($j(window), this.viewPortModel).start();
    this.dartAdController = new DartAdController($j("body"), "homepage", this.viewPortModel, {searchData: stm.externalSearchData});

    var self = this;

    window.pageRenderedCallbacks = window.pageRenderedCallbacks || [];
    window.pageRenderedCallbacks.push(function() {
        self.dartAdController.start();
    });
});

