var $j = require("jquery");
var _ = require("underscore");
var defineComponent = require("flight/lib/component");
var withCookie = require("flight-common-mixins/lib/with-cookie-support");

var component = defineComponent(Nav).mixin(withCookie);


function Nav() {
    this.attributes({
        /**
         * @event "data:user:updated" user data has possibly been updated
         */
        EV_USER_DATA_UPDATED: "data:user:updated",

        /**
         * @event ui:searchForm:submitted => A search form has been submitted without validation errors
         */
        EV_SEARCH_FORM_SUBMITTED: "ui:searchForm:submitted:valid",

        alertsLoginDropdownSelector: ".alerts-login",

        alertsNavDropdownSelector: ".alerts-nav",

        alertsInputSelector: ".email_input",

        alertButtonSigninText: "Sign In",

        dropdownSelector: ".navbar-horizontal .dropdown",

        submenuSelector: ".dropdown-submenu a",

        userCookieName: "u",

        searchWidgetTriggerSelector: ".navbar-search",

        searchWidgetContainer: ".navbar-flight-search-widget",

        searchWidgetDestinationInputSelector: ".navbar-flight-search-widget input[name='destinationId']",

        searchWidgetAnimationDurationMs: 150,

        locationSuggestModalSelector: ".modal__location-suggest"
    });

    /**
     * When a hover event is triggered on a nav dropdown, clear any existing toggled
     * dropdowns and blur their focus - this keeps only one dropdown showing at any
     * given time
     * @param event
     */
    this.onDropdownHover = function(event) {
        // First check cookie data if this is the "My Alerts" login tab - if yes,
        // check if the user cookie has been set and transition to the logged-in
        // tab experience if so
        if ($j(event.target).parents(this.attr.alertsLoginDropdownSelector).length) {
            var userCookie = this.getCookie(this.attr.userCookieName);
            if ((userCookie !== null) && userCookie.match(/[a-z0-9]/i) !== null) {
                this.handleKnownUser();
            }
        }


        this.event = event;
        _.each($j(event.currentTarget.parentElement).find(">.dropdown"), function (dropdown) {
            if ($j(this.event.currentTarget).is(dropdown)) {
                return;
            }

            $j(dropdown).removeClass("open");
            $j(dropdown).find(">a").blur();
        }, this);
    };

    this.onAlertsDropdownClick = function(event) {
        $j(event.target).parents(this.attr.dropdownSelector).addClass("open");
    };

    this.onNewUserData = function(event, userData) {
        if (userData && userData.userInfo) {
            this.handleKnownUser();
        }
    };

    this.handleKnownUser = function() {
        this.select("alertsLoginDropdownSelector").addClass("hidden");
        this.select("alertsNavDropdownSelector").removeClass("hidden");
    };

    this.onSubMenuClick = function(event) {
        var submenuNav = $j(event.target);
        var parentMenu = submenuNav.closest("li.dropdown-submenu");
        var submenu = parentMenu.find(">ul.dropdown-menu");

        if (submenu.is(":visible")) {
            submenu.css("display", "");
        } else {
            submenu.css("display", "block");
        }

        event.stopPropagation();
    };

    this.openSearchWidget = function() {
        var searchWidgetContainer = this.select("searchWidgetContainer");
        var destinationInput = this.select("searchWidgetDestinationInputSelector");

        if (searchWidgetContainer.length && searchWidgetContainer.is(":hidden")) {
            searchWidgetContainer.slideDown(this.attr.searchWidgetAnimationDurationMs, function() {
                destinationInput.focus();
            });
            this.select("searchWidgetTriggerSelector").addClass("invisible");
            this.on(document.body, "click", this.onSearchWidgetOutsideClick);
        }
    };

    this.onSearchWidgetOutsideClick = function(event) {
        // elements that should not cause the search widget to close when clicked
        var searchWidgetCloseExclusions = [
            this.attr.searchWidgetContainer,
            this.attr.searchWidgetTriggerSelector,
            this.attr.locationSuggestModalSelector
        ].join(",");

        if (!$j(event.target).is("body") && !$j(event.target).closest(searchWidgetCloseExclusions).length) {
            this.closeSearchWidget();
        }
    };

    this.closeSearchWidget = function() {
        var searchWidgetContainer = this.select("searchWidgetContainer");

        if (searchWidgetContainer.length) {
            searchWidgetContainer.slideUp(this.attr.searchWidgetAnimationDurationMs);
            this.select("searchWidgetTriggerSelector").removeClass("invisible");
            this.off(document.body, "click", this.onSearchWidgetOutsideClick);
        }
    };

    this.after("initialize", function () {
        // Initial view of cookies
        this.attr.cookieData = document.cookie;

        this.on(this.attr.dropdownSelector, "mouseover", this.onDropdownHover);
        this.on(this.attr.alertsInputSelector, "click", this.onAlertsDropdownClick);
        this.on("body", this.attr.EV_USER_DATA_UPDATED, this.onNewUserData);
        this.on(this.attr.submenuSelector, "click", this.onSubMenuClick);
        this.on(this.attr.searchWidgetTriggerSelector, "click", this.openSearchWidget);
        this.on("body", this.attr.EV_SEARCH_FORM_SUBMITTED, this.closeSearchWidget);
    });
}

module.exports = component;