var CookieUtils = require("src/desktop/utils/cookies");
var defineComponent = require("flight/lib/component");

var component = defineComponent(function () {

    this.after("initialize", function () {
        window._pcq = window._pcq || [];
        window._pcq.push(["APIReady", setPushcrewCookie]);

        function setPushcrewCookie() {
            if (window.pushcrew.subscriberId) {
                CookieUtils.setCookie("pc_id", window.pushcrew.subscriberId, 365);
            }
        }
    });
});

module.exports = component;