var baseAirSearchForm = require("src/desktop/modules/flight-search-form/lib/base/air-basic-search-form");
var withStSubModal = require("src/desktop/modules/flight-search-form/lib/mixins/with-st-sub-modal");
var withJsSubModal = require("src/desktop/modules/flight-search-form/lib/mixins/with-js-sub-modal");
var withSubs = require("src/desktop/modules/flight-subs/lib/mixins/with-subs");

var component = baseAirSearchForm.mixin(withSubs, withStSubModal, withJsSubModal, function () {
    this.attributes({
        _id: null,
        submitSelector: ".do_submit",
        formSelector: ".subscription-modal-form",
        emailContainerSelector: ".email",
        emailInput: "input.email",
        location1Selector: ".location1",
        externalSubInlineCheckboxSelector: ".ext_sub_inline_checkbox",
        errorClass: "error",

        userEmailId: "",

        /**
         * @event ui:user:subscribed:success => event to show success message
         */
        EV_USER_SUBSCRIBED_SUCCESS: "ui:user:subscribed:success",

        /**
         * @event data:external:login => triggered when user logs in via an external site
         */
        EV_EXTERNAL_LOGIN: "data:external:login"
    });


    /**
     * AWD & ST subscription responses are handled in the following function.
     * <u>Notes:-</u>
     * <ul>
     *     <li>Here we are sequentially requesting for ST-Sub upon receiving sub-response from AWD subs request.</li>
     *     <li>We are also handling subscription exists - case differently here, than we do in other places. </li>
     *     <li>We are doing nothing upon receiving ST subs response. </li>
     * </ul>
     *
     * @param {Object} data - responseData coming from - handleBulkSubJsonResponse
     *
     */
    this.handleSubResponses = function (data) {
        if ((data.siteForSubRequest !== this.attr.awdSite) && (data.siteForSubRequest !== this.attr.awdUkSite)) {
            // this could potentially be a JS/ST sub from the inline checkbox
            return;
        }

        var externalSubCheckbox = this.select("externalSubInlineCheckboxSelector");
        var isExternalSubChecked = externalSubCheckbox.prop("checked") || false;
        var eventData = {
            nextView: externalSubCheckbox.length ? "success" : "determine",
            showExternalSiteSuccessMessage: isExternalSubChecked,
            userEmail: this.select("emailInput").val(),
            siteForSubRequest: data.siteForSubRequest
        };

        this.trigger(this.attr.EV_USER_SUBSCRIBED_SUCCESS, eventData);
        if (isExternalSubChecked) {
            this.doExternalSiteSub();
        }
    };

    /**
     * We only can do something if the sub fails with a email related error. So if the error code does not represent a
     * bad email we will fail silently.
     *
     * @override with-subs
     * @param {Object} data
     *  @param {Boolean} success
     *  @param {String} msg
     *  @param {Number} code
     */
    this.handleBulkSubJsonResponse = function (data) {
        if (data && data.code === this.attr.INVALID_EMAIL_CODE) {
            //TODO: Display Error
            this.select("emailContainerSelector").addClass(this.attr.errorClass);
        } else {
            this.handleSubResponses(data);
        }
    };

    /**
     * Subscribes the user to their home/fav airport if the user is already subbed this will fail silently
     * In user_modal_subscription, we are making subscriptions for following subs,
     *  1) Departure Fare Alert (DFA - with Origin Airport) , if Origin-Airport is available
     *  2) HotelWatchdog hotel deal alert
     *  3) Airfarewatchdog insider
     *  4) Airfarewatchdog updates
     *  5) Airfarewatchdog tripwatch
     *  6) Airfarewatchdog special offers
     *
     * @param {Object} formData see getFormData return
     */
    this.doSub = function (formData) {
        var subData = {
            id: this.attr._id,
            requestedSubscriptions: [
                {
                    subscriptionType: this.attr.subTypeDeparture,
                    destinationCode: null,
                    originCode: formData.location1.airportCode
                }, {
                    subscriptionType: this.attr.subTypeHwdUpdates
                }, {
                    subscriptionType: this.attr.subTypeAwdUpdates
                }, {
                    subscriptionType: this.attr.subTypeAirfareInsider
                }, {
                    subscriptionType: this.attr.subTypeTripWatch
                }, {
                    subscriptionType: this.attr.subTypeSpecialOffer
                }
            ]
        };

        var email = this.select("emailInput").val();
        if (email) {
            this.attr.userEmailId = email;
            subData.email = email;
        }

        this.subMultiple(subData);
    };

    /**
     * Subscribe the user to another site. Which site will be determined by the class names on the inline checkbox.
     */
    this.doExternalSiteSub = function() {
        var subData = {
            id: this.attr._id,
            requestedSubscriptions: []
        };

        if (this.attr.userEmailId) {
            subData.email = this.attr.userEmailId;
        }

        var subSite = this.select("externalSubInlineCheckboxSelector").attr("data-sub-site");
        if (subSite === "st") {
            this.applyOtherSubsForStUserModal(subData);
        } else if (subSite === "js") {
            this.applyOtherSubsForJsUserModal(subData);
        }
    };

    this.clearErrors = function () {
        this.select("location1Selector").removeClass(this.attr.errorClass);
        this.select("emailContainerSelector").removeClass(this.attr.errorClass);
    };

    /**
     * Show error for form fields
     * @param element - jQuery Object
     */
    this.showError = function (element) {
        element.addClass(this.attr.errorClass);
    };

    /**
     * Validate before submiting data
     * @param formData
     * @return {Boolean}
     */
    this.isValidData = function (formData) {
        var invalid = this.getInvalidElements(formData);

        for (var i = 0; i < invalid.length; i++) {
            this.showError(invalid[i]);
        }

        return invalid.length === 0;
    };

    /**
     * Validates the form data and returns an array of invalid elements
     * @param formData the data to validate
     * @returns {Array} of elements that have errors
     */
    this.getInvalidElements = function (formData) {
        var email = this.select("emailInput").val();

        var invalid = [];

        if(!email.trim()) {
            invalid.push(this.select("emailContainerSelector"));
        }
        if (!formData.location1 || !formData.location1.airportCode || !formData.location1.parentDisplayName) {
            invalid.push(this.select("location1Selector"));
        }

        return invalid;
    };

    this.submit = function (event) {
        event.preventDefault();
        this.clearErrors();
        var formData = this.getFormData();

        if (!this.isValidData(formData)) {
            return;
        }

        this.doSub(formData);
    };

    /**
     * The event handler when external search data is detected by the search forms.
     * causes the form to silently update all of its fields.
     *
     * This form is independent of search-mode. (used in air as well hotel search-type)
     *
     * FYI :-
     * we want to override function from air-basic-search-form because we want to get rid of following condition:
     *  {code}
     *       //Bail out if search data isn't an air search
     *       if (searchData.searchType !== "air") {
	 *          return;
	 *       }
     *  {code}
     */
    this.externalSearchDataChanged = function (event, searchData) {
        var newValue;
        if (!searchData) {
            return;
        }

        var location1Input = this.select("_location1Selector");

        if (searchData.location1AirportId && location1Input.length) {
            newValue = {
                id: searchData.location1AirportId,
                airportCode: searchData.location1AirportCode.hasOwnProperty("code") ? searchData.location1AirportCode.code : searchData.location1AirportCode,
                parentId: searchData.location1CityId,
                parentDisplayName: searchData.location1CityDisplayName,
                departureFareEligible: searchData.location1DepartureFareEligible
            };

            location1Input.data("value", newValue);
            location1Input.val(searchData.location1Name);
        }

    };

    this.onExternalLogin = function (event, data) {
        var email = data.email;
        if (!email) {
            return;
        }

        this.select("emailInput").val(email);

        if (this.getInvalidElements(this.getFormData()).length === 0) {
            this.select("formSelector").submit();
        }
    };


    this.after("initialize", function () {
        this.on(this.select("formSelector"), "submit", this.submit);
        this.on(this.attr.EV_EXTERNAL_LOGIN, this.onExternalLogin);
    });

});

module.exports = component;
