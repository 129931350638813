var stm = require("stm");
var define = stm.define;

var SiteIdUtils = define({
    STATIC: {
        defaultSiteId: 200,
        siteIdToDisplayDateFormatMap: {
            200: "mm/dd/yy",
            206: "dd/mm/yy"
        },
        siteIdToShortDisplayDateFormatMap: {
            200: "mm/dd",
            206: "dd/mm"
        },
        siteIdToSingleDisplayDateFormatMap: {
            200: "MM/DD",
            206: "DD/MM"
        },

        siteIdToSite: {
            200: "awd",
            206: "awduk",
            328: "js",
            3: "st"
        },

        getDisplayDateFormat: function(siteId) {
            return this.siteIdToDisplayDateFormatMap[siteId];
        },
        getShortDisplayDateFormat: function(siteId) {
            return this.siteIdToShortDisplayDateFormatMap[siteId];
        },

        getSingleDisplayDateFormat: function(siteId) {
            return this.siteIdToSingleDisplayDateFormatMap[siteId];
        },

        getSite: function(siteId) {
            return this.siteIdToSite[siteId];
        }
    }
});

module.exports = SiteIdUtils;