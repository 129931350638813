var baseAirSearchForm = require("src/desktop/modules/flight-search-form/lib/ui/air-exact-date-sub-search-form");
var withCheapFlights = require("src/desktop/modules/flight-open-link/lib/mixins/with-cheap-flights-urls");
var withStSubHomepage = require("src/desktop/modules/flight-search-form/lib/mixins/with-st-sub-homepage");
var withDepRouteSubs = require("src/desktop/modules/flight-search-form/lib/mixins/with-dep-route-sub");
var withSubSetup = require("src/desktop/modules/flight-search-form/lib/mixins/with-sub-widget-setup");
var withAirValidation = require("src/desktop/modules/flight-search-form/lib/mixins/with-air-validation");
var withSubs = require("src/desktop/modules/flight-subs/lib/mixins/with-subs");
var checkboxUpdate = require("src/desktop/modules/flight-search-form/lib/mixins/sub-widget-checkbox");
var withCookies = require("flight-common-mixins/lib/with-cookie-support");
var win = require("win");

var component = baseAirSearchForm.mixin(checkboxUpdate, withCheapFlights,withDepRouteSubs, withStSubHomepage, withSubSetup, withAirValidation, withSubs, withCookies, function () {
    this.attributes({
        _id: null,
        _subWidgetSelector: ".do_sub_widget",
        _cookieName: "u",
        submitSelector: ".do_submit",
        location1Selector: ".location1",
        location2Selector: ".location2",
        errorClass: "error",
        duplicateErrorClass: "error_duplicate",
        location2Required: false,

        // Special characters provided in the attribute below will be removed from the string (parentDisplayName)
        // Since, our backend code can grab airport code from url which contains aplhabates separated by "-"
        // If we have any special character in url cheap-flights/location1-locCity-LCD-to-location2-locCity-LCD,
        // We will face 404 - Page not found for particular route.
        //  e.g : Bombay-(Mumbai)-BOM,
        specialCharToRemove:",()",
        deviceType: "desktop",
        emailCheckbox: ".sub-widget-email-checkbox #emailCheckbox"
    });

    this.openFromLink = function (formData) {

        var params = {
            name: formData.location1.parentDisplayName.replace(/,/g, ""),
            code: formData.location1.airportCode
        };

        var url = this.buildFromUrl(params);
        win.open(url, "_blank");
        return true;
    };

    this.openRouteLink = function (formData) {
        if (!formData.location2 || !formData.location2.airportCode || !formData.location2.parentDisplayName) {
            return false;
        }

        var params = {
            originName: formData.location1.parentDisplayName.replace(this.attr.regExpG, ""),
            originCode: formData.location1.airportCode,
            destinationName: formData.location2.parentDisplayName.replace(this.attr.regExpG, ""),
            destinationCode: formData.location2.airportCode
        };

        var url = this.buildRouteUrl(params);
        win.open(url, "_blank");
        return true;
    };

    this.submit = function (event) {
        event.preventDefault();
        this.clearErrors();
        var formData = this.getFormData();
        if (!this.validateData(formData)) {
            return;
        }

        if (this.select("emailCheckbox").attr("checked")) {
            this.doSub(formData);
        }

        this.trigger(this.attr.EV_SEARCH_FORM_SUBMITTED);

        if (this.openRouteLink(formData)) {
            return;
        }
        this.openFromLink(formData);
    };
});

module.exports = component;