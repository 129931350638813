var baseAirSearchForm = require("src/desktop/modules/flight-search-form/lib/base/air-basic-search-form");
var withCheapFlights = require("src/desktop/modules/flight-open-link/lib/mixins/with-cheap-flights-urls");
var withAirValidation = require("src/desktop/modules/flight-search-form/lib/mixins/with-air-validation");
var withSubs = require("src/desktop/modules/flight-subs/lib/mixins/with-subs");
var withCookies = require("flight-common-mixins/lib/with-cookie-support");
var win = require("win");

var component = baseAirSearchForm.mixin(withCheapFlights, withAirValidation, withSubs, withCookies, function () {
    this.attributes({
        _id: null,
        submitSelector: ".do_submit",
        formSelector: ".sub-lander-air-form",
        emailContainerSelector: ".email",
        emailInput: "input.email",
        location1Selector: ".location1",
        errorClass: "error",
        _duplicateErrorClass: "error_duplicate",
        location2Required: false
    });

    /**
     * Subscribes the user to their home airport if the user is already subbed this will fail silently
     * @param {Object} formData see getFormData return
     */
    this.doSub = function (formData) {
        var subData = {
            id: this.attr._id,
            requestedSubscriptions: [
                {
                    subscriptionType: this.attr.subTypeDeparture,
                    destinationCode: null,
                    originCode: formData.location1.airportCode
                }, {
                    subscriptionType: this.attr.subTypeHwdUpdates
                }
            ]
        };

        var email = this.select("emailInput").val();
        if (email) {
            subData.email = email;
        }

        this.subMultiple(subData);
    };

    this.openFromLink = function (formData) {

        var params = {
            name: formData.location1.parentDisplayName.replace(/,/g, ""),
            code: formData.location1.airportCode
        };

        var url = this.buildFromUrl(params) + "?sub_lander=1";
        win.open(url, "_blank");
        win.location.reload();
        return true;
    };

    this.clearErrors = function () {
        this.select("location1Selector").removeClass(this.attr.errorClass);
        this.select("emailContainerSelector").removeClass(this.attr.errorClass);
    };

    this.handleBulkSubJsonResponse = function (data) {
        var formData = this.getFormData();

        if (data && data.code === this.attr.INVALID_EMAIL_CODE) {
            this.select("emailContainerSelector").addClass(this.attr.errorClass);
        } else {
            this.openFromLink(formData);
        }
    };

    this.submit = function (event) {
        event.preventDefault();
        this.clearErrors();
        var formData = this.getFormData();

        if (!this.validateData(formData)) {
            return;
        }

        this.doSub(formData);
    };

    this.after("initialize", function () {
        this.on(this.select("formSelector"), "submit", this.submit);
    });


});

module.exports = component;