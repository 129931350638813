/**
* This mixin will add arrival and departure location information to a component. It also
* provides the method 'getLocationKeys' to provide the attr keys for any
* fields that are added to the component.
*/
define(function (require) {

'use strict';

/**
  * Module exports
  */

return withDates;

/**
  * Module function
  */

function withDates() {
  this.defaultAttrs({
    /**
    * The TA id of The city
     * @type {Number}
    */
    location1CityId: 0,

    /**
     * The ta id for the airport
     * @type {Number}
     */
    location1AirportId: 0,

    /**
    * The airport code for location 1
    * @type {Object}
    *   @type {String} code 3 letter IOTA code
    */
    location1AirportCode: {},

    /**
     * The name of the location
     * @type {String}
     */
    location1Name: '',

    /**
     * The full display name of the geo's city
     * @type {String}
     */
    location1CityDisplayName: '',

    /**
     * Whether the location is eligible for creating fare alerts if it is the departure location
     * @type {Boolean}
     */
    location1DepartureFareEligible: false,

    /**
     * The TA id of The city
     * @type {Number}
     */
    location2CityId: 0,

    /**
     * The ta id for the airport
     * @type {Number}
     */
    location2AirportId: 0,

    /**
     * The airport code for location 1
     * @type {Object}
     *   @type {String} code 3 letter IOTA code
     */
    location2AirportCode: {},

    /**
     * The name of the location
     * @type {String}
     */
    location2Name: '',

    /**
     * The full display name of the geo's city
     * @type {String}
     */
    location2CityDisplayName: '',

    /**
     * Whether the location is eligible for creating fare alerts if it is the departure location
     * @type {Boolean}
     */
    location2DepartureFareEligible: false
  });

  /**
    * Returns the array of the all of the search keys that the awd specific search model
    * uses and should transmit.
    * @return {Array}
    */
  this.getLocationKeys = function() {
    return [
      'location1CityId',
      'location1AirportId',
      'location1AirportCode',
      'location1Name',
      'location1CityDisplayName',
      'location1DepartureFareEligible',
      'location2CityId',
      'location2AirportId',
      'location2AirportCode',
      'location2Name',
      'location2CityDisplayName',
      'location2DepartureFareEligible'
    ];
  };
}
});
