/**
 *
 * This component is a shim that saves and loads certain data to the cs cookie. It is by no means a complete mapping but
 * does have all the functionality required for loading and storing data on the new platform.
 *
 * @todo: remove this code and replace it with a better persistent store when we are able to (when awd is fully ported). As
 * such this is not being built as a sample of elegant code but rather ugly and functional.
 *
 *
 * DOM Element Requirements:
 * =========================
 *  This element should be placed on the body tag or as high up on the dom as possible so it will catch
 *  all of its events.
 *
 * Listens To:
 * ===========
 * @event data:searchData:changed => the search data has been updated
 *  @param {Object} searchData - the full data set currently contained in the component
 *
 *
 *
 * This module will listen for requests for data and listens for updates to the search data.
 * This component can translate data from the new world to the cs cookie format.
 *
 */
var LOCATION_TYPE_GEO = "geo";
var LOCATION_TYPE_AIR = "airport";

/**
 * Module dependencies
 */

var defineComponent = require("flight/lib/component");
var withCookieSupport = require("flight-common-mixins/lib/with-cookie-support");
var moment = require("moment");

/**
 * Module exports
 */

var component = defineComponent(cookieShim).mixin(withCookieSupport);

/**
 * Module function
 */

function cookieShim() {
    this.attributes({

        /**
         * Events
         */

        /**
         * @event data:searchData:changed => the search data in this component has been updated
         *  @param {Object} searchData - the full data set currently contained in the component
         */
        EV_DATA_SEARCH_DATA_CHANGED: "data:searchData:changed",

        /**
         * The name of the cookie that the data is persisted to
         */
        _cookieName: "cs",

        /**
         * The number of days that the cookie should persist
         */
        _cookieExpireDays: 7

    });

    /**
     * This function takes search data in the new world format and converts it into the old
     * cs cookie format.
     * @param {Object} search date. The awd java search model
     * @return {Object} the cs cookie json model
     */
    this.convertToCookieData = function (searchData) {
        var cookieData = {};
        cookieData.location1 = {};
        cookieData.location2 = {};

        if (searchData.searchType && searchData.searchType === "air") {
            cookieData.location1.type = LOCATION_TYPE_AIR;
            cookieData.location2.type = LOCATION_TYPE_AIR;
        } else {
            cookieData.location1.type = LOCATION_TYPE_GEO;
            cookieData.location2.type = LOCATION_TYPE_GEO;
        }


        if (searchData.serviceClass) {
            cookieData["class"] = searchData.serviceClass;
        }

        if (searchData.nonStop) {
            cookieData.nonstop = searchData.nonStop ? 1 : 0;
        }

        if (searchData.flightSearchType) {
            cookieData.oneway = searchData.flightSearchType === "oneway" ? 1 : 0;
        }

        if (searchData.travelers) {
            cookieData.travelers = searchData.travelers;
        }

        if (searchData.flexDays) {
            cookieData.flx = searchData.flexDays;
        }

        if (searchData.searchDate1) {
            var searchDate1 = searchData.searchDate1;

            if (searchDate1.date) {
                cookieData.date1 = this.toTimestamp(searchDate1.date);
            }

            cookieData.time1 = searchDate1.time;
        }

        if (searchData.searchDate2) {
            var searchDate2 = searchData.searchDate2;

            if (searchDate2.date) {
                cookieData.date2 = this.toTimestamp(searchDate2.date);
            }

            cookieData.time2 = searchDate2.time;
        }

        cookieData.defaultDates = searchData.defaultDates;

        if (searchData.location1CityId) {
            cookieData.location1.cid = searchData.location1CityId;
        }

        if (searchData.location1AirportId) {
            cookieData.location1.aid = searchData.location1AirportId;
        }

        if (cookieData.location1.type === LOCATION_TYPE_AIR) {
            cookieData.location1.id = cookieData.location1.aid;
        } else {
            cookieData.location1.id = cookieData.location1.cid;
        }

        if (searchData.location2CityId) {
            cookieData.location2.cid = searchData.location2CityId;
        }

        if (searchData.location2AirportId) {
            cookieData.location2.aid = searchData.location2AirportId;
        }

        if (cookieData.location2.type === LOCATION_TYPE_AIR) {
            cookieData.location2.id = cookieData.location2.aid;
        } else {
            cookieData.location2.id = cookieData.location2.cid;
        }

        return cookieData;

    };

    /**
     * Converts flightjs search data to a format usable by our React app.
     *
     * NOTE: For now this is only set up to handle AirportSuggestions.
     */
    this.convertToReactAppData = function(searchData) {
        var reactSearchData = {},
            location1 = {},
            location2 = {};

        reactSearchData.defaultDates = searchData.defaultDates;
        if (searchData.searchDate1 && searchData.searchDate1.date) {
            reactSearchData.date1 = moment(searchData.searchDate1.date);
        }

        if (searchData.searchDate2 && searchData.searchDate2.date) {
            reactSearchData.date2 = moment(searchData.searchDate2.date);
        }

        if (searchData.location1CityId) {
            location1.parentId = searchData.location1CityId;
        }

        if (searchData.location1AirportId) {
            location1.id = searchData.location1AirportId;
        }

        if (searchData.location1AirportCode) {
            location1.airportCode = searchData.location1AirportCode;
        }

        if (searchData.location1Name) {
            location1.displayName = searchData.location1Name;
        }

        if (searchData.location1CityDisplayName) {
            location1.parentDisplayName = searchData.location1CityDisplayName;
        }

        if (searchData.location1DepartureFareEligible) {
            location1.departureFareEligible = searchData.location1DepartureFareEligible;
        }

        if (searchData.location2CityId) {
            location2.parentId = searchData.location2CityId;
        }

        if (searchData.location2AirportId) {
            location2.id = searchData.location2AirportId;
        }

        if (searchData.location2AirportCode) {
            location2.airportCode = searchData.location2AirportCode;
        }

        if (searchData.location2Name) {
            location2.displayName = searchData.location2Name;
        }

        if (searchData.location2CityDisplayName) {
            location2.parentDisplayName = searchData.location2CityDisplayName;
        }

        if (searchData.location2DepartureFareEligible) {
            location2.departureFareEligible = searchData.location2DepartureFareEligible;
        }

        reactSearchData.location1 = {
            text: location1.displayName,
            suggestion: location1
        };

        reactSearchData.location2 = {
            text: location2.displayName,
            suggestion: location2
        };

        if (searchData.flightSearchType) {
            reactSearchData.flightSearchType = searchData.flightSearchType;
        }

        if (searchData.travelers) {
            reactSearchData.travelers = searchData.travelers;
        }

        if (searchData.searchType) {
            reactSearchData.searchType = searchData.searchType;
        }

        if (searchData.nonStop) {
            reactSearchData.nonStop = searchData.nonStop;
        }

        if (searchData.provider) {
            reactSearchData.provider = searchData.provider;
        }

        if (searchData.serviceClass) {
            reactSearchData.serviceClass = searchData.serviceClass;
        }

        return reactSearchData;
    };

    /**
     * Converts a ISO 8601 date string to a unix timestamp (seconds)
     * @param {String} date in the format of YYYY-MM-DD (ISO 8601)
     */
    this.toTimestamp = function (dateString) {
        var dateParts = dateString.split("-");
        var date = new Date(dateParts[0], (dateParts[1] - 1), dateParts[2]);
        return date.getTime() / 1000;
    };

    /**
     * @param searchData the new world search data
     */
    this.updateCookie = function (event, searchData) {
        var cookieData = this.convertToCookieData(searchData);
        var encoded = encodeURIComponent(JSON.stringify(cookieData)).replace(/\(/g, "%28").replace(/\)/g, "%29");
        this.setCookie(this.attr._cookieName, encoded, this.attr._cookieExpireDays);
        if (window.awd_updateSearchData) {
            // yes, technically this doesn't relate to the cs cookie. But we want the same event to trigger it, and
            // this is only going to be necessary to bridge the gap between react & flight until we get rid of flight
            window.awd_updateSearchData(this.convertToReactAppData(searchData));
        }
    };

    this.after("initialize", function () {
        this.on("body", this.attr.EV_DATA_SEARCH_DATA_CHANGED, this.updateCookie);
    });
}

module.exports = component;
