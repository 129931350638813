var $j = require("jquery");
var SearchDataComponent = require("flight-search-data/lib/data/air-search-data");
var stm = require("stm");
var ViewPortModel = require("src/desktop/modules/visibility/model/viewPort");
var DartAdController = require("src/desktop/modules/dart/controllers/main");
var WindowChangeController = require("src/desktop/modules/visibility/controller/main");

/**
 * ===========================================================================
 * Include any common page js that should be run *after* all
 *  page-specific logic has been loaded;
 * For instance, SearchDataComponent will trigger a data refresh
 *  with externalSearchData so all necessary listeners need a
 *  chance to attach first
 */

$j(function (){
    SearchDataComponent.attachTo("body");

    var isMobile = stm.pageData.hwd.device.mobile;

    if (isMobile) {
        this.viewPortModel = this.viewPortModel || new ViewPortModel();

        if (!this.windowChangeController) {
            this.windowChangeController = new WindowChangeController($j(window), this.viewPortModel);
            this.windowChangeController.start();
        }

        if (!this.dartAdController) {
            this.dartAdController = new DartAdController($j("body"), "generic_page", this.viewPortModel, {searchData: stm.externalSearchData});
            this.dartAdController.start();
        }
    }
});